import { Component, ViewChild, OnInit, Input, OnChanges, Output, EventEmitter, ElementRef, Renderer2 } from '@angular/core';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import { AngularFireStorage } from '@angular/fire/storage';
import FirebaseCloudStorage from './firestorage.plugin';
import { UiService } from '../services/ui.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BackendService } from '../services/backend.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ColorPlateComponent } from '../project-detail/color-plate/color-plate.component';

declare var uppy;
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit, OnChanges {
  private uppy;
  isTraining: boolean = false;
  @ViewChild('trainingDialog') trainingDialog: any;
  imageSimilarity = environment.imageSimilarity;
  @Input() projectId: string;
  @Input() assetId: string;
  @Input() folderPath: string;
  @Input() idToken: string;

  @Output() errorEvent = new EventEmitter();
  errorImages = [];
  addedImages = [];
  addImagesCount = 0;
  generateThumbnailsCount = 0;
  isSetDisabledOnUploadBtn = true;
  isBtnDisabled = true;
  subs: Subscription;
  trainingDialogRef;
  onDestroy$ = new Subject();
  set isUploadImages(value: boolean) {
    this.uiService.isUploadImages = value;
    this.uiService.currentUploadImagesFolder = this.uiService.activeFolderPath;
  }

  get isUploadImages(): boolean {
    return this.uiService.isUploadImages;
  }

  get currentUploadImagesFolder(): string {
    return this.uiService.currentUploadImagesFolder;
  }

  constructor(
    private storage: AngularFireStorage,
    private uiService: UiService,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private backendService: BackendService,
    private dialog: MatDialog
  ) {

    this.subs = this.uiService.imagesCompleted$.subscribe(() => {
      uppy.close();
      this.init();
      uppy.getPlugin('Dashboard').openModal()
    })
  }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
    if (!this.isUploadImages) {
      if (this.uppy) {
        this.uppy.close();
      }
      this.init();
    } else {
      if (!this.uppy) {
        this.uppy = uppy;
      }
    }
  }

  removeText() {
    let txtEl = document.querySelector('.upload-text');
    if (txtEl) {
      txtEl.remove()
    }
  }

  ngAfterViewChecked() {

    // setting button disabling and text for it
    // if (this.isSetDisabledOnUploadBtn && this.elementRef.nativeElement.querySelector('.uppy-StatusBar-actionBtn--upload')) {
    //   this.isSetDisabledOnUploadBtn = false;

    // const uploadButton = this.elementRef.nativeElement.querySelector('.uppy-StatusBar-actionBtn--upload');
    // this.renderer.setProperty(
    //   uploadButton,
    //   'disabled',
    //   this.isBtnDisabled
    // );
    // this.setUploadBtnText(uploadButton);
    // }
  }

  setUploadBtnText(uploadButton: ElementRef) {
    const textDiv = this.renderer.createElement('div');
    this.renderer.addClass(textDiv, 'upload-text');
    textDiv.innerHTML = `Please wait while we process the <b>${this.generateThumbnailsCount}</b> of <b>${this.addImagesCount}</b> images for upload. and do not click anywhere outside the upload box`;

    const parentNode = this.renderer.parentNode(uploadButton);
    this.removeUploadBtnText(parentNode);

    if (this.addImagesCount !== this.generateThumbnailsCount) {
      this.renderer.appendChild(
        parentNode,
        textDiv
      )
    }
  }

  removeUploadBtnText(parentNode: ElementRef) {
    if (this.elementRef.nativeElement.querySelector('.upload-text')) {
      this.renderer.removeChild(
        parentNode,
        this.elementRef.nativeElement.querySelector('.upload-text')
      )
    }
  }

  init(): void {
    this.uppy = Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: 4294967296, //4 GB
        /* allowedFileTypes: ['image/*', '.3fr', '.ari', '.arw', '.cr2', '.cr3',
           '.dcs', '.dcr', '.dng', '.drf', '.erf', '.fff',
           '.iiq', '.k25', '.kdc', '.mef', '.mos', '.mrw','.mdc',
           '.nef', '.nrw', '.orf', '.pef', '.ptx', '.pxn', '.raf',
           '.raw', '.rwl', '.rw2', '.sr2', '.srw', '.x3f']
           */
        allowedFileTypes: ['image/png', 'image/jpg', 'image/jpeg', 'image/tiff', 'video/*']
      },
      onBeforeUpload: (files) => {
        // this.uiService.uppyUploadFilesState = files;
        return files;
      }
    })

      .use(Dashboard, {
        inline: false,
        trigger: '.UppyModalOpenerBtn',
        target: '.DashboardContainer',
        replaceTargetContent: true,
        note:
          this.uiService.asset?.assetType === 'solar'
            ?
            !this.uiService.isGeoReference ? `Geo reference is missing in DXF. 
          For automatic linking of labels create manual geo points in asset panel.` :
              `
        Automatic linking will take few hours to compelte.
          `
            :
            ` If you want to automatic linking of labels with their respective media then [QR file name should be prefix with "QRcode_" example: QRcode_DSC12345.jpg]  
        `,
        maxHeight: 450,
        proudlyDisplayPoweredByUppy: false,
        closeModalOnClickOutside: true,
        fileManagerSelectionType: 'both',
        waitForThumbnailsBeforeUpload: true,
        disableThumbnailGenerator: true,
        metaFields: [
          { id: 'name', name: 'Name', placeholder: 'file name' },
          { id: 'license', name: 'License', placeholder: 'specify license' },
          {
            id: 'caption',
            name: 'Caption',
            placeholder: 'describe what the image is about'
          }
        ]
      })
    this.uppy.use(FirebaseCloudStorage, {
      storageRef: this.storage.ref('images/projects'),
      storageCustomMetaData: {
        folderPath: this.folderPath,
        projectId: this.projectId,
        assetId: this.assetId,
        idToken: this.idToken,
        imageLinking: this.backendService.DOMAIN_CONTROL?.features?.threeDImageLinking || false
      },
      videoStorageRef: this.storage.ref('videos/projects'),
      uiService: this.uiService
    }).on('upload-error', (file) => {
      const imgObj = this.convertFileTImg(file);
      this.errorImages.push(imgObj);
     // this.uiService.filteredImages = this.uiService.filteredImages.filter(image => image.thumbFileUrl !== imgObj.thumbFileUrl);
    });
    this.uppy.on('files-added', async (files) => { });
    this.uppy.on('file-added', async (file) => {
      if (file.type == 'application/octet-stream') {
        file.type = 'image/' + file.name.split('.').pop();
      }

      this.addImagesCount++;
      const imgObj = this.convertFileTImg(file);
      this.addedImages.push(imgObj);
      /*  setTimeout(() => {
          const trainingDiv: any = document.getElementById('training');
          if (!trainingDiv) {
            this.addTrainingSeriesCheckbox();
          } else {
            trainingDiv.value = this.projectId;
          }
        }, 700)*/
    });
    this.uppy.on('upload', (file) => {
      this.uiService.currentUploadingFolder = this.uiService.activeFolder.data;
      this.isUploadImages = true;
      this.uiService.currentUploadFolderPath = this.folderPath;
      this.uiService.uploadImagesCount = this.addedImages.length + this.uiService.filteredImages.length;
      this.uiService.imageProcess.imageUpload = Object.keys(this.uppy.getState().files).length
      if (this.uppy)
        this.uppy.getPlugin('Dashboard').closeModal();
    });

    this.uppy.on('upload-started', (file) => {
      const image = this.addedImages.find(image => file.id.includes(image.id));
      if (this.currentUploadImagesFolder === this.uiService.activeFolderPath) {
        this.uiService.filteredImages.splice(0, 0, image);
        this.addedImages = this.addedImages.filter(item => item.id !== image.id);
        const imgObj = this.convertFileTImg(file);
        this.uiService.uploadImages.push(imgObj);
        this.uiService.uppyUploadFilesState = this.uppy.getState();
      }
    })

    this.uppy.on('upload-success', (file, response) => {
      this.uiService.imageProcess.imageSuccess = this.uiService.imageProcess.imageSuccess + 1;
      const image = this.uiService.uploadImages.find(image => file.id.includes(image.id));
      if (image) {
        this.uiService.uploadImages = this.uiService.uploadImages.filter(item => item.id !== image.id);
       // this.uiService.filteredImages = this.uiService.filteredImages.filter(item => item.id !== image.id);
      }
    });
    this.uppy.on('upload-finished', () => {
      this.isUploadImages = false;
      this.uiService.uppyUploadFilesState = null;
    })

    this.uppy.on('error', () => {
      this.errorEvent.emit(this.errorImages);
      this.isUploadImages = false;
    });


    this.uppy.on('file-removed', (file) => {

      this.uiService.imageProcess.imageUpload = Object.keys(this.uppy.getState().files).length;
    });

    this.uppy.on('cancel-all', () => {
      this.uiService.imageProcess = {
        imageUpload: 0,
        imageSuccess: 0
      }
      this.isUploadImages = false;
      this.uiService.uploadImagesCount = 0;
      this.uiService.uploadImages = [];
      this.uiService.uppyCancelAllEvent$.next();
    });
    this.uppy.on('complete', (result) => {
      /*  const trainingEl: any = document.getElementById('training');
        if (trainingEl) {
          if (trainingEl.checked) {
            this.generateTrainingIndexes(trainingEl.value)
          } else {
            this.backendService.trainedProject(trainingEl.value, false);
          }
        }*/
      this.generateTrainingIndexes(this.projectId);
    })

    this.uppy.on('dashboard:modal-open', () => {
      if (this.isUploadImages) {
        this.showMessage();
      }
      const dashboardEl = document.querySelector('.uppy-Dashboard-AddFiles');
      let isPaletteButton = false;
      const el: any = document.querySelector('.uppy-Dashboard-AddFiles');
      for (var i = 0; el && el.childNodes[i]; i++) {
        if (el.childNodes[i].id == 'palette_btn') {
          // button already in the dom
          isPaletteButton = true;
          break;
        }
      }
      if (!isPaletteButton && this.uiService.project.projectType == "thermal" &&
        !this.uiService.project.colorPalette) {
        const customForm = document.querySelector('#palette_btn').cloneNode(true);
        // Append the custom form to the Dashboard
        dashboardEl.appendChild(customForm);
        const _this = this;
        customForm.addEventListener('click', function () {
          // Your click event handler code here
          _this.openColorPalette();
        });
      }
    })

    this.uppy.on('dashboard:modal-close', () => {
      this.removeCustumButton();
    })
  }

  addTrainingSeriesCheckbox() {
    var checkbox: any = document.createElement('input');
    checkbox.type = "checkbox";
    checkbox.style = "margin-left:10px";
    checkbox.name = "name";
    checkbox.value = this.projectId;
    checkbox.id = "training";
    var label: any = document.createElement('label');
    label.htmlFor = "training";
    label.style = "color:#000;margin-left:5px;margin-top:5px;"
    label.appendChild(document.createTextNode('Select this checkbox for training series of your project when you end your uploads of this project.'));
    const uploadBtn = document.querySelector('.uppy-StatusBar-actionBtn')
    if (uploadBtn) {
      uploadBtn.parentNode.appendChild(checkbox);
      uploadBtn.parentNode.appendChild(label);
    }

  }
  showMessage() {
    uppy.info(`File is uploading on  "${this.uiService.activeFolder.data.breadcrumb.name}"`)
  }

  convertFileTImg(file) {
    const url = window.URL || window.webkitURL;
    const imgSrc = this.sanitizer.bypassSecurityTrustUrl(url.createObjectURL(file.data));
    const imgObj = {
      id: file.id,
      thumbFileUrl: imgSrc,
      fileName: file.name,
    };
    return imgObj;
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  generateTrainingIndexes(id) {
    
    if(this.uiService.isGeoReference && this.uiService.asset.assetType === 'solar'){
      this.backendService.linkDXFImages(id).pipe(
        takeUntil(this.onDestroy$)).subscribe();  
    }
    this.backendService.imageSimilarityTrain(id).pipe(
      takeUntil(this.onDestroy$)).subscribe(images => {
        //    this.backendService.trainedProject(id, true);
      })
  }

  removeCustumButton() {
    const dashboardEl = document.querySelector('.uppy-Dashboard-AddFiles');
    const el: any = document.querySelector('.uppy-Dashboard-AddFiles');
    for (var i = 0; el && el.childNodes[i]; i++) {
      if (el.childNodes[i].id == 'palette_btn') {
        dashboardEl.removeChild(dashboardEl.childNodes[i])
        break;
      }
    }
  }

  openColorPalette() {
    const dialogRef = this.dialog.open(ColorPlateComponent, {
      width: '500px',
    })
    dialogRef.afterClosed().subscribe(r => {
      if (r && r.colorPalette) {
        this.removeCustumButton();
        this.backendService.projectColorPalette(this.uiService.selectedProjectId,
          r.colorPalette).then(() => {
            this.removeCustumButton();
          })
      }
    });
  }


}
<div class="filter-component">
    <div class="popup-header d-flex">
        <p class="w-100"> Filter</p>
        <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
    </div>
    <mat-tab-group class="mat-two-tabs" (selectedTabChange)="selectTab($event)">
        <mat-tab label="Features">
            <ng-container [ngTemplateOutlet]="featuresOuter"></ng-container>
        </mat-tab>
        <mat-tab label="Report Groups">
            <ng-container [ngTemplateOutlet]="groupsOuter"></ng-container>
        </mat-tab>
    </mat-tab-group>

    <ng-template #featuresOuter>
        <mat-card style="height: calc(100vh - 230px);" class="overflow-y-scroll p-0">
            <div class="flex flex-column">
                <div class="tag-option py-2 px-3 border-bottom" [class.active]="'relations' === selectedTag"
                    (click)="selectTag('relations')">
                    Context Tags</div>
                <div *ngFor="let tag of tags" (click)="tagClick(tag,$event)">
                    <p class="tag-option d-flex py-2 px-3 m-0 border-bottom" matTooltip="{{tag.tag}}"
                        [class.active]="tag.tag === selectedTag">

                        {{ tag.tag }} {{getFilterTagCount(tag.tag)}} <mat-icon class="sensitivity-icon"
                            *ngIf="tag.sensitivity">{{!tag.isOpen ? 'expand_more' :
                            'expand_less'}}
                        </mat-icon>
                    </p>
                    <div class="text-center" *ngIf="tag.isOpen && tag.tag === selectedTag">
                        <span *ngFor="let level of tag.levels" [style.background]="level.color" [id]="level.level"
                            [style.opacity]="sensitiveTag === level.level ? 1 : 0.5"
                            [style.border]="sensitiveTag === level.level ? '1px solid #007bff' : 'none'"
                            class="circle levels" [matTooltip]="level.title"></span>
                        <!-- <span [style.opacity]="sensitiveTag === '1' ? 1 : 0.2"
                                [style.border]="sensitiveTag === '1' ? '1px solid #007bff' : 'none'"
                                class="circle medium" matTooltip="medium"></span>
                            <span [style.opacity]="sensitiveTag === '2' ? 1 : 0.2"
                                [style.border]="sensitiveTag === '2' ? '1px solid #007bff' : 'none'" class="circle high"
                                matTooltip="high"></span> -->
                    </div>
                </div>
            </div>
        </mat-card>

    </ng-template>

    <ng-template #groupsOuter>
        <div style="height: calc(100vh - 230px);" class="overflow-y-scroll p-0">
            <button mat-button class="dark add-folder" name="button"
                (click)="toggleGroup(); $event.stopImmediatePropagation()" [disabled]="getReadOnlyForCurrentUser()">
                <mat-icon fontSet="material-icons-outlined" class="mr-1">
                    create_new_folder
                </mat-icon>
                <span>Add New Group</span>
                <mat-icon class="group-help"
                    matTooltip="Create groups here and add images to the groups for easier reporting">help</mat-icon>

            </button>

            <div *ngIf="addGroup">
                <mat-form-field class="w-100 mb-3 px-2" appearance="fill">
                    <input matInput cdkFocusInitial [(ngModel)]="groupName" placeholder="Group name" />
                </mat-form-field>
                <div class="input-error" *ngIf="!isNameValid(groupName)">Group already exists</div>
                <div class="d-flex">

                    <button class="ml-2" mat-raised-button color="primary"
                        [disabled]="!groupName || !isNameValid(groupName) || isLoading"
                        (click)="handleAddGroup(); $event.stopImmediatePropagation()">
                        <mat-spinner [diameter]="20" *ngIf="isLoading"></mat-spinner>
                        <span class="upper-text">Create</span>

                    </button>
                    <button mat-button (click)="toggleGroup()">
                        <span class="upper-text">Cancel</span>
                    </button>
                </div>
            </div>
            <hr />
            <div *ngFor="let group of groups">
                <p (click)="selectGroup(group,$event)"
                    class="d-flex py-2 px-3 m-0 border-bottom report-list">
                    <span style="width:100vh">
                        {{group.groupName}}
                    </span>
                    <mat-icon id="edit" matTooltip="Edit Group">edit</mat-icon>
                    <mat-icon id="delete" matTooltip="Delete Group">delete_outline</mat-icon>
                </p>

                <div *ngIf="group.isEdit">
                    <mat-form-field class="w-100 mb-3 px-2" appearance="fill">
                        <input matInput cdkFocusInitial [(ngModel)]="groupName" placeholder="Group name" />
                    </mat-form-field>
                    <div class="input-error" *ngIf="!isEditNameValid(group, groupName)">Group already exists</div>
                    <div class="d-flex">

                        <button class="ml-2" mat-raised-button color="primary"
                            [disabled]="!groupName || !isEditNameValid(group,groupName) || isLoading"
                            (click)="handleEditGroup(group); $event.stopImmediatePropagation()">
                            <mat-spinner [diameter]="20" *ngIf="isLoading"></mat-spinner>
                            <span class="upper-text">Edit</span>
                        </button>
                        <button mat-button (click)="group.isEdit = false;">
                            <span class="upper-text">Cancel</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
<!--  -->
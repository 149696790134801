import { Project } from './../projects/item/project.type';
import { BehaviorSubject, of, Observable, Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { DrawingMode } from '../tagging/tagging.helpers';
import { KonvaConfig } from '../models/tagging.interface';
import { IImage } from '../models/image.interface';
import { TreeModel } from '@circlon/angular-tree-component';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  // subjects:
  public selectedProjectId: string;
  public getProjectsEvent$ = new Subject();
  public changeProjectEvent$ = new Subject();
  public uppyCancelAllEvent$ = new Subject();
  public generateReportEvent$ = new Subject<string>();
  public updateReportEvent$ = new Subject<string>();
  public updateContextsEvent$ = new Subject();
  public folderSelectedEvent$ = new Subject();
  public imagesContainerScrollEvent$ = new Subject();
  public imagesAnnotations$ = new Subject();
  public imagesCompleted$ = new Subject();
  public openTraingingModel$ = new Subject();
  public tagSelectedEvent$ = new Subject();
  public projectFieldsChangeEvent$ = new Subject();
  public activeFolderChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public activeFolder: any;
  public activeFolderPath: string;
  public project: Project;
  public asset;
  public isGeoReference = false;;
  public projects$: Observable<Project[]> = of([]);
  public folders$: Observable<any[]> = of([]);
  public groups$: Observable<any[]> = of([]);
  public unProcessedImages$: Observable<any[]> = of([]);
  public folderImages: any[] = [];
  public taggingMode: DrawingMode = 'rectangle';
  public isMask: boolean = false;
  public addContextEnabled$ = new BehaviorSubject<boolean>(false);
  public addContextEvent$ = new Subject<KonvaConfig>();
  public thermalEnabled$ = new BehaviorSubject<boolean>(false);
  public colorImageEnabled$ = new BehaviorSubject<boolean>(false);
  public addContextArea = null;
  public tree: any;
  public members: any[] = [];
  public roles: any[] = [];
  public tags: any[] = [];
  public collapsePanel$ = new Subject();
  public images: any[] = [];
  public videos: any[] = [];
  public filteredImages: any[] = [];
  public isFolderContentLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public checkedImages: any[] = [];
  public movedImages: IImage[] = [];
  public unlabelledImages: IImage[] = [];
  public movedInfo: { projectId: string, folderPath: string } = null;
  public movedFolder: TreeModel;
  public nodes: any[] = [];
  public selectedTag = 'All';
  public sensitiveTag;
  public selectedTab = 'folders';
  public selectedGroup;
  // for uppy uploading images
  public uploadImagesCount = 0;
  public isUploadImages: boolean = false;
  public currentUploadImagesFolder: string = null;
  public uploadImages = [];
  public uppyUploadFilesState = null;
  public currentUploadFolderPath: string;
  public imageProcess = {
    imageUpload: 0,
    imageSuccess: 0
  }
  public folderParams;
  public currentUploadingFolder;
  // for groups on ImageCheckboxGridComponent
  public selectedImagesIds$ = new BehaviorSubject<string[]>([]);
  public reportingProcess = [];

  // For compare side by side
  public activeProjectLeftChange = new Subject();
  public activeProjectRightChange = new Subject();
  public selectedLeftProject: any;
  public selectedRightProject: any;


  // For asset Side panel
  public selectedSideTab: string = "";
  public assetSelectedTabIndex: number = 0;
  public selectedProjectNode = {
    parent: null,
    child: null
  };
  public selectedAssetNode = {
    parent: null,
    child: null
  };
  public selectedCampaignNode = {
    company: null,
    asset: null
  }
  public projectNodes: any[] = [];
  public assetsNodes: any[] = [];
  public mapSelectedEvent$ = new Subject();
  public isAssetPanelExpand: boolean = false;
  public updateAssetNodeEvent$ = new Subject();
  public campaignAssetView: boolean = true;
  public groupByCompanyView: any;
  public selectedCampaignCompany = {
    company: null,
    campaign: null,
    asset: null
  }
  // ai engine
  public engine: any[] = [];
  public projectAITags: any[] = [];
  public skipImages: any[] = [];

  // asset sidebar 
  public allProjects = [];
  public allAssets = [];
  public allCompanies = [];
  public campaigns: any[] = []
  public changeAssetOrProjectData$ = new Subject();

  public assetProjects = [];
  public projectInfo = {
    images: 0,
    videos: 0,
    annotations: 0,
    annotatedImages: 0,
    unAnnotatedImages: 0,
    loading: false,
    projectId: ''
  }
  public projectImageContexts = [];
  public summary = [];
  public folderChange = new Subject();
  public folderChangeWithOldImageRoute: boolean = false;
  public defaultView: string;

  //3D model uplaod states
  uploadModelPercentage = 0;
  modelUploadTaks;
  projectModel: any = []
  model: any;

  public excluded3DIamges = [];
  public isColorImage :boolean=true;


  //cache dxf data
  public dxfId: any = null
  public rawDXF: any = null
  public panelDXF: any = null

  //for dashboard events
  public projectImages=[];
  public annotationSubscription: Subscription = new Subscription();
  public imageSubscription: Subscription = new Subscription();
  public colorPaletteChange$ = new Subject();
  public anomalyDetectionChange$ = new Subject();

  // forcsea
  public logs=[];
  public excelSheetContent = [];
  public isLogProcessing$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

}

<div class="popup-header d-flex">
  <p class="w-100">
    Add Features
  </p>
  <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>
<div>
  <div mat-dialog-content>
    <div class="form-container">
      <form>
        <div class="justify-content-between align-middle">
          <div class="form-field">
            <input id="input-tag" 
              *ngIf="appInferenceEngineMode  === 1 && backendService.DOMAIN_CONTROL?.features?.aiEngine else normalTextBox"
              type="text" (input)="filterAiTags($event.target.value)" [matAutocomplete]="aiTags" maxLength="64" required
              [class.is-invalid]="form.get('tag').invalid && form.get('tag').touched" [disabled]="tagsOverflow() || data.edit"
              class="form-control input-field input-tag" placeholder="Add new feature" name="tag" [(ngModel)]="tag" />
            <ng-template #normalTextBox>
              <input  id="input-tag" type="text" maxLength="64" required
                [class.is-invalid]="form.get('tag').invalid && form.get('tag').touched" [disabled]="tagsOverflow()  || data.edit"
                class="form-control input-field input-tag" placeholder="Add new feature" name="tag" [(ngModel)]="tag" />
            </ng-template>

            <mat-autocomplete #aiTags="matAutocomplete">
              <mat-option style="height:auto" (click)="selectTag(aiTag)" *ngFor="let aiTag of filteredTags"
                [value]="aiTag">
                {{aiTag}} <span>(AI)</span>
              </mat-option>
            </mat-autocomplete>
          </div>

          <div class="mt-2 form-field">
            <textarea  [disabled]="data.edit" type="text" required [(ngModel)]="description" class="form-control input-field input-tag"
              placeholder="Description" name="description"></textarea>
          </div>

          <div class="mt-3">
            <mat-checkbox [disabled]="data.edit" (change)="sensitivityChange($event.checked)"  name="sensitivity"
              [(ngModel)]="sensitivity">Add
              severity</mat-checkbox>

          </div>

          <div *ngIf="sensitivity" class="mt-2">
            <label>Severity Level</label>
            <div [style.background]="data.edit? '#e1e1e1' : '#ffffff'" class="container-box">
             
              <span (click)="addTable(level.level)"  *ngFor="let level of levels;let i =index">
              <mat-checkbox   [disabled]="true" [color]="'primary'" class="img-checkbox" [checked]="level.checked">
                </mat-checkbox> 
              <span [class.active]="sensitiveLevel >= i + 1">
                {{level.level}}
              </span> 
              </span>
            </div>

            <table class="mt-2 w-100" *ngIf="selectedLevels.length">
              <thead style="text-align: center;">
                <th>
                  Level
                </th>
                <th>
                  Title
                </th>
                <th>
                  Action
                </th>
              </thead>
              <tbody>
                <tr *ngFor="let level of selectedLevels;let index=index; trackBy:trackByIndex;">
                  <td>
                    <span>
                      <p class="severity-marker" [style.background]="level.color"></p>
                    </span>
                  </td>
                  <td>
                    <input   [disabled]="data.edit" name="title_{{index}}"  [(ngModel)]="selectedLevels[index].title" type="text"
                      class="form-control input-field input-tag" />
                  </td>
                  <td>
                    <input name="acton_{{index}}"  [(ngModel)]="selectedLevels[index].action" type="text"
                      class="form-control input-field input-tag" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button *ngIf="!data.edit" mat-raised-button type="submit" color="primary" class="mt-3"
            [disabled]="(form.get('tag').invalid && form.get('tag').touched) || tagsOverflow() || !isNameValid()
            || !isSeverityLevelsValid()"
            (click)="submit()">
            <span class="text-uppercase">Add</span>
          </button>

          <button *ngIf="data.edit" mat-raised-button type="submit" color="primary" class="mt-3"
          [disabled]="(form.get('tag').invalid && form.get('tag').touched)
          || !isSeverityLevelsValid()"
          (click)="editTag()">
          <span class="text-uppercase">Save</span>
          <mat-icon class="help-menu"  matTooltip="Only actions can be editable,Create a new finding for updating  feature name and levels">help</mat-icon>
        </button>
        </div>

      </form>
      <div class="input-error" *ngIf="!isNameValid() && !data.edit">Tag already exists</div>
    </div>
    
    <span class="legend-tags" *ngIf="tags.length">Existing Features</span>
    <div class="d-flex flex-wrap mb-5" style="max-width: 540px">
      
      <div class="tag-item text-center d-flex align-middle justify-content-center mr-2 mb-2" *ngFor="let tag of tags">
        {{tag.tag}}
        <span style="color:#46b3e6;font-weight: normal;" *ngIf="tag.aiTag"> (AI)</span>
        <mat-icon *ngIf="tag.sensitivity" matTooltip="Copy severity levels" (click)="copyFeature(tag)">file_copy</mat-icon>
      </div>
    </div>
    <div style="max-width: 540px">
      <p *ngIf="tags.length === backendService.CONSTANTS.TAGS_MAX_COUNT" style="color: #3f51b5;">
        Hey, maximum of {{backendService.CONSTANTS.TAGS_MAX_COUNT}} tags allowed for current plan.
        Become our prestige user to enjoy more features
      </p>
    </div>
  </div>
  <mat-icon class="close-dialog-button" [ngStyle]="{'color':'#EB5757'}" (click)="dialogRef.close()">close</mat-icon>
</div>
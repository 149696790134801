import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BackendService } from '../services/backend.service';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-add-edit-scope',
  templateUrl: './add-edit-scope.component.html',
  styleUrls: ['./add-edit-scope.component.scss']
})
export class AddEditScopeComponent implements OnInit, OnDestroy {
  @ViewChild('uploadProgressBar') uploadProgressBar: any;
  @ViewChild('documentDialog') documentDialog: any;
  documentDialogRef: any;
  tabView = 'scope';
  public scope: string;
  projectDocuments = new Subscription();
  documents = [];
  certificates = [];
  public createBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    raised: true,
    spinnerSize: 24,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    mode: 'indeterminate',
    customClass: 'text-uppercase'
  };
  progressBar = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public backend: BackendService,
    private storage: AngularFireStorage,
    public dialogRef: MatDialogRef<AddEditScopeComponent>,
    private dialog: MatDialog
  ) {
    this.scope = this.data.project.projectScope
  }

  ngOnInit(): void {
    this.projectDocuments = this.backend.getProjectDocuments(this.data.project.id).subscribe(response => {
      this.certificates = response.filter(o => o.isCertificate)
      this.documents = response.filter(o => !o.isCertificate);

    });
  }

  close(): void {
    this.dialogRef.close(false);
  }

  scopeUpdate(name): void {
    if (name) {
      this.createBtnOptions.active = true;
      this.backend.scopeUpdate(this.data.project.id, this.scope).then(() => {
        this.createBtnOptions.active = false;
        //  this.dialogRef.close({success: true, name});
      });
    }
  }

  delete(id) {
    this.backend.deleteProjectDocuments(this.data.project.id, id).then(() => { });
  }

  ngOnDestroy() {
    this.projectDocuments.unsubscribe();
  }

  public isImageUploading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  async fileChoose(event) {
    if (!event.target.files.length) { return };
    const file = event.target.files[0];
    this.isImageUploading$.next(true);
    const tabView = this.tabView;
    const ref = this.storage.ref('project-documents').child(uuidv4());
    if (file) {
      var uploadTask = ref.put(file);
      uploadTask.task.on('state_changed', async (snapshot) => {
        this.progressBar = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
        (error) => {
          this.progressBar = 0;
          this.isImageUploading$.next(false);
        },
        () => {
          ref
            .getDownloadURL()
            .subscribe((url) => {
              this.progressBar = 0;
              this.isImageUploading$.next(false);

              this.documentDialogRef = this.dialog.open(this.documentDialog, {
                panelClass: 'no-border-radius-dialog',
                disableClose:true,
                data: {
                  projectId: this.data.project.id,
                  url: url,
                  fileName: file.name,
                  isCertificate: tabView === 'certificate' ? true : false
                },
              });
    
            })
        }
      );
    }
  }

  encode(url: string) {
    return encodeURIComponent(url);
  }


  handleAdd() {
    document.getElementById('documents').click();
  }

  saveDocument(data) {
    this.backend.addProjectDocuments(this.data.project.id, {
      url: data.url,
      fileName: data.fileName,
      isCertificate: data.isCertificate,
      documentName:data.documentName
    }).then(() => {
      this.documentDialogRef.close();
     });
  }

  openDoc(document) {
    if (document.fileName.indexOf('docx') != -1) {
      window.open('https://docs.google.com/viewer?url=' + encodeURI(document.url) + '&embedded=true', "target:_blank")

    } else {
      window.open(document.url, "target:_blank")
    }
  }
}
<div class="popup-header w-100 d-flex">
    <p class="w-100">
        Severity Levels
    </p>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>

<div class="levels">
    <div mat-dialog-content>
            <ul class="p-level">
                <li [matTooltip]="level.action" (click)="addLevel(level.level)" *ngFor="let level of data.levels">
                    <span class="bubbles" [style.background]="level.color"></span>
                    {{level.title}}  
                </li>
            </ul>
    </div>
</div>
<div style="height: 40px;" class="popup-header w-100 d-flex">
  <p class="w-100">
    {{data.fileName}}
  </p>

  <!-- <button
  (click)="matchLogs();"  matTooltip="Extract frame from video" *ngIf="uiService.project.inspectionFile &&  uiService.project.inspectionType ==='forssea-robotics'"
  [disabled]="isProcessing$.value" class="p-10 d-flex">
  <mat-icon>perm_media</mat-icon>
  <span *ngIf="isProcessing$ | async">
      <mat-spinner class="spinner" [diameter]="20"></mat-spinner>
  </span>
</button> -->

  <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>


<div #modal class="video-modal panel-fix bg">
  <video src="{{data.fileUrl}}#t=0.1" #video [autoplay]="false" [preload]="true" [download]="false" spinner="spin"
    poster="{{data.posterImage}}">
  </video>
  <div id="video-controls">
    <div class="d-flex video-component">
      <mat-spinner matTooltip="video is loading" [diameter]="15" *ngIf="!isLoadedVideo" ></mat-spinner>
      <mat-icon *ngIf="!isPlaying && isLoadedVideo" (click)="play()">play_arrow</mat-icon>
      <mat-icon *ngIf="isPlaying && isLoadedVideo" (click)="pause()">pause</mat-icon>
      <mat-spinner-button 
      [disabled]="isProcessing$.value"
      [options]="createBtnOptions" (btnClick)="capture()">
      </mat-spinner-button>
      <span class="w-100">
        <mat-slider style="width:100%;top: 10px;" (change)="sliderChange($event)" min="0" [max]="getMax()"
          [value]="getSliderValue()">
        </mat-slider>
        <div *ngIf="this.video" class="bubble">
          <div [style]="getBubbleStyle(image.frame,i)" *ngFor="let image of videoImages; let i = index">
            <p [style.background]="image.tags?.length > 0 ?'#46b3e6' : '#FFFFFF'" class="circle"></p>
          </div>
        </div>
      </span>
      <span class="time"> {{getTime()}} / {{getDuration()}}</span>
    </div>
  </div>
</div>

<div class="grid-y w-100 h-100 container-box">
  <div class="cell shrink w-100 h-100" #videoContainer>
    <div class="cell shrink justify-content-center w-100 h-100">
      <div class="carousel mt-0 mb-0">
        <div *ngIf="this.imageId" class="carousel__main">
          <div class="carousel__main-container" style="position: relative;overflow: hidden;">

            <div class="slide-button-container" *ngFor="let area of activeImgAreas; let i = index"
              [style]="getAreaSeverityIconStyle(area)">
              <p [matTooltip]="getTitle(area)" [style.background]="getBGColor(area)"
                *ngIf="getTagSensitive(area)" class="bubble-marker"></p>
            </div>

            <ko-stage #videoStage style='pointer-events: none;' [config$]="videoConfigStage$">
              <!-- <ko-layer>
                        <ko-image [config$]="videoConfigImg$"></ko-image>
                      </ko-layer> -->

              <ko-layer>
                <ng-container *ngFor="let area of activeImgAreas; let i = index">
                  <ko-line [config$]="area.config$">
                  </ko-line>
                  <ko-group *ngIf="area.tag">
                    <ko-label [config$]="area.label$">
                      <ko-tag [config$]="area.tag$"></ko-tag>
                      <ko-text [config$]="area.textConfig$"></ko-text>
                    </ko-label>
                  </ko-group>
                </ng-container>
              </ko-layer>
            </ko-stage>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollUpDistance]="1" [infiniteScrollThrottle]="50"
  [scrollWindow]="false" class="scroller v-slider">
  <ng-container>
    <div [class.active]="image.id == imageId" *ngFor="let image of videoImages; let i = index">

      <img (click)="activeImage(i,image)" [src]="image.thumbFileUrl" />
      <mat-icon *ngIf="!image.processing" [ngStyle]="{ color: image.tags?.length > 0 ?'#4456F9' : '#FFFFFF' }"
        (click)="preview(image)" class="image-icon">edit</mat-icon>
      <mat-spinner [diameter]="15" *ngIf="image.processing" class="processing"></mat-spinner>

      <div class="carousel-filename">{{image.fileName }}</div>

    </div>
  </ng-container>
</div>


<ng-template #addFrameDialog let-data>
  <div class="popup-header d-flex">
    <p class="w-100">
      Frame
    </p>
    <mat-icon class="close-btn" (click)="addFrameDialogRef.close()">close</mat-icon>
  </div>
  <div mat-dialog-content>
    <mat-form-field class="w-100 mb-2 mt-4" appearance="fill">
      <input matInput cdkFocusInitial [(ngModel)]="data.title" placeholder="Name of image" autocomplete="false" />
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button mat-button [disabled]="!data.title" (click)="saveVideoFrame(data)" class="ml-2" color="primary"
      mat-raised-button>
      Save
    </button>
    <button mat-button (click)="addFrameDialogRef.close()">Cancel</button>
  </div>

</ng-template>
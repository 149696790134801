import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
  Input,
  NgZone,
} from '@angular/core';
import {
  of,
  Observable,
  Subscription,
  BehaviorSubject,
  combineLatest,
  Subject,
} from 'rxjs';

import { first, mergeMap, map, startWith, catchError, tap, switchMap, takeUntil, retry, take, concatMap, debounceTime, distinctUntilChanged, last, debounce, takeLast, skip, delay } from 'rxjs/operators';
import { UiService } from './../services/ui.service';
import { BackendService } from '../services/backend.service';
import { KonvaComponent } from '../konva/konva.module';
import { Images, KonvaConfig, TaggedArea } from '../models/tagging.interface';
import { ActivatedRoute, Router } from '@angular/router';
import {
  getPolygonRect,
  getScaledPolygon,
  inverseScaleXY,
  scaledLabelOffset
} from './../tagging/tagging.helpers';
import { Project } from '../projects/item/project.type';
import { GeneralService } from '../services/general.service';
import { Options, ChangeContext } from '@angular-slider/ngx-slider';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import * as htmlToImage from 'html-to-image';
import { v4 as uuidv4 } from 'uuid';
import { AngularFireStorage } from '@angular/fire/storage';
import { Clipboard } from '@angular/cdk/clipboard';
import { GroupFilterComponent } from './group-filter/group-filter.component';
import * as THREE from 'three'

import { ImageModalComponent } from '../image-modal/image-modal.component';
import { VideoModalComponent } from '../video-modal/video-modal.component';
import { environment } from 'src/environments/environment';
import { Viewer } from '../Three/viewer';
import { Status } from '../models/app.enum';
import { CesiumViewer } from '../cesium/viewer';
declare var Cesium: any
declare var window: any;
const fontSize = 16;
const color = '#EB5757';
const colorPalette = {
  COLORMAP_JET: '#000',
  COLORMAP_RAINBOW: '#222',
  COLORMAP_HSV: '#222',
  COLORMAP_HOT: '#FFF',
  COLORMAP_INFERNO: '#FFF',
};

@Component({
  selector: 'app-compare-images',
  templateUrl: './compare-images.component.html',
  styleUrls: ['./compare-images.component.scss']
})
export class CompareImagesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('leftStage') leftStage: KonvaComponent;
  @ViewChild('rightStage') rightStage: KonvaComponent;
  @ViewChild('leftContainer') leftContainer: ElementRef;
  @ViewChild('rightContainer') rightContainer: ElementRef;
  @ViewChild('addReportDialog') addReportDialog: any;
  @ViewChild('copyDialog') copyDialog: any;
  @ViewChild('baseModel') baseModelDialog: any;
  @ViewChild('leftModelPanel') leftModelPanel: ElementRef;
  @ViewChild('rightModelPanel') rightModelPanel: ElementRef;
  @Input() isVisible: boolean = false;
  @Input() top: string = '0';
  @Input() left: string = '0';
  isMenu: boolean = false;
  addReportDialogRef;
  copyDialogRef
  public projectLeftTags: string[] = [];
  public sensitiveProjectLeftTags: any[] = [];

  public projectRightTags: string[] = [];
  public sensitiveProjectRightTags: any[] = [];
  public leftTagsTextConfigs: any[] = [];
  public rightTagsTextConfigs: any[] = [];
  public colors = {};
  public leftContainerSize: any;
  public rightContainerSize: any;
  public leftProjectImages: Images;
  public leftProjectImages$: Observable<any>;
  public rightProjectImages: Images;
  public rightProjectImages$: Observable<any>;
  public leftImagesSubscription: Subscription;
  public rightImagesSubscription: Subscription;
  public leftImageAnnotationsSub: Subscription;
  public rightImageAnnotationsSub: Subscription;
  public leftImageName: string;
  public rightImageName: string;
  public isCopying = false;
  searchData = [];
  noMatchFound = false;
  public labelImages = [];
  public selectedImages = 'all';
  public searchMode = 'manual';
  public labelMode = '';
  public groupMode = '';
  public modelType = '2d';
  public get activeLeftImgAreas(): TaggedArea[] {
    return this.leftTaggedAreas.get(this.leftImageId) || [];
  }
  public get activeRightImgAreas(): TaggedArea[] {
    return this.rightTaggedAreas.get(this.rightImageId) || [];
  }

  public get leftActiveImgAreas(): TaggedArea[] {
    return this.leftTaggedAreas.get(this.leftImageId) || [];
  }

  public get rightActiveImgAreas(): TaggedArea[] {
    return this.rightTaggedAreas.get(this.rightImageId) || [];
  }
  // ----------left------------------
  public leftConfigStage$: BehaviorSubject<any> = new BehaviorSubject({
    width: 600,
    height: 350
  });

  public leftConfigImg$: BehaviorSubject<KonvaConfig> = new BehaviorSubject({
    width: 0,
    height: 0,
    image: null,
  });


  // ----------right-----------------

  public rightConfigStage$: BehaviorSubject<any> = new BehaviorSubject({
    width: 600,
    height: 350
  });

  public rightConfigImg$: BehaviorSubject<KonvaConfig> = new BehaviorSubject({
    width: 0,
    height: 0,
    image: null,
  });


  public leftTaggedAreas: Map<string, TaggedArea[]> = new Map();

  public rightTaggedAreas: Map<string, TaggedArea[]> = new Map();
  public isDrawing = false;
  public leftActiveImgIdx: number;
  public rightActiveImgIdx: number;
  public leftTagsListHeight = 0;
  public leftTagsListWidth = 0;
  public rightTagsListHeight = 0;
  public rightTagsListWidth = 0;
  leftImageId: any;
  rightImageId: any;
  assetId: string;
  asset: any;
  public leftImageAnnotations = [];
  public rightImageAnnotations = [];
  public isSetLeftContainerSize: boolean = true;
  public isRightSetContainerSize: boolean = true;
  public projectSubscription = new Subscription();
  public projects: Project[] = [];
  private onDestroy$ = new Subject();
  value: number = 0;
  highValue: number = 0;
  oldValue: number = 0;
  oldHighValue: number = 0;
  pointerType: number;
  projectId: string;
  options: Options = {
    floor: 0,
    ceil: 0,
    showTicks: true,
    showTicksValues: false,
    translate: (value: number): string => {
      return '';
    }
  };
  isLoading: boolean = false;
  thumbImages = []
  leftProjectStatus: string = "";
  rightProjectStatus: string = '';
  leftTrainSubscription: any = new Subject();
  rightTrainSubscription: any = new Subject();
  isSearchRequestReady = false;
  public leftProjectAnnotations;
  public rightProjectAnnotations;
  public leftProjectUnprocessedImages;
  public rightProjectUnprocessedImages = [];
  public leftProjectFilteredImages;
  public rightProjectFilteredImages;
  public selectedLeftProject;
  public selectedRightProject;
  public get Success() {
    return Status.SUCCESS;
  }
  public get Failed() {
    return Status.FAILED;
  }
  public get Process() {
    return Status.PROCESSING;
  }
  public get Queue() {
    return Status.QUEUED;
  }
  public get Cancel() {
    return Status.CANCELLED;
  }

  constructor(
    private elRef: ElementRef,
    public backend: BackendService,
    public uiService: UiService,
    private readonly cdr: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private generalService: GeneralService,
    private renderer: Renderer2,
    private location: Location,
    private changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private storage: AngularFireStorage,
    private zone: NgZone
  ) {
    this.activatedRoute.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.projectId = params.projectId;
      this.assetId = params.assetId;
      this.listenProjects();
      this.getAssets(params.assetId);
      this.getBaselineModels();

    });
  }

  onUserChangeStart(changeContext: ChangeContext) {
    this.pointerType = changeContext.pointerType;
  }


  panelData: any = {
    context: '',
    projects: [],
    value: '',
  };
  onUserChange(changeContext: ChangeContext): void {
    if (changeContext.pointerType == 1) {
      if (this.groupProjects[changeContext.highValue].value.length > 1) {
        //show panel
        if (changeContext.highValue !== this.panelData.value) {
          this.panelData = {
            projects: this.groupProjects[changeContext.highValue].value,
            context: changeContext.pointerType,
            value: changeContext.highValue
          }
        } else {
          this.panelData = {
            projects: [],
            context: changeContext.pointerType,
            value: ''
          }
        }
      }
      else {
        this.panelData = {
          projects: [],
          value: ''
        };
        if (changeContext.highValue !== this.oldHighValue) {
          this.modelType = '2d';
          this.oldHighValue = changeContext.highValue;
          this.isSearchRequestReady = true;
          this.clearCurrentFilter();
          this.filterKeyProject();
          this.uiService.activeProjectRightChange.next(this.groupProjects[changeContext.highValue].value[0]);
        }
      }

    }

    if (changeContext.pointerType == 0) {
      if (this.groupProjects[changeContext.value].value.length > 1) {
        //show panel
        if (changeContext.value !== this.panelData.value) {
          this.panelData = {
            projects: this.groupProjects[changeContext.value].value,
            context: changeContext.pointerType,
            value: changeContext.value
          }
        } else {
          this.panelData = {
            projects: [],
            context: changeContext.pointerType,
            value: ''
          }
        }
      }
      else {
        this.panelData = {
          projects: [],
          value: ''
        };
        if (changeContext.value != this.oldValue) {
          if (!this.isSearchRequestReady) {
            this.isSearchRequestReady = true;
          }
          this.oldValue = changeContext.value;
          // reset  labels and groups filters
          this.clearCurrentFilter();
          this.filterValueProject();
          this.modelType = '2d';
          this.uiService.activeProjectLeftChange.next(this.groupProjects[changeContext.value].value[0]);
        }
      }

    }

  }

  dxfFile;
  getAssets(assetId) {
    this.backend.getAssetById(assetId).subscribe((asset) => {
      this.asset = asset.data();
    });
  }

  toggleMenu(isMenu) {
    this.isMenu = isMenu;
    setTimeout(() => {
      if (this.leftActiveImgIdx !== undefined) {
        this.setLeftContainerSize();
        this.activate(this.leftActiveImgIdx, 'left');
      }
      if (this.rightActiveImgIdx !== undefined) {
        this.setRightContainerSize();
        this.activate(this.rightActiveImgIdx, 'right');
      }
    }, 200);

  }

  groupByInspectionDate(array: Array<any>, field) {
    if (array) {
      const groupedObj = array.reduce((prev, cur) => {
        if (!prev[cur[field]]) {
          prev[cur[field]] = [cur];
        } else {
          prev[cur[field]].push(cur);
        }
        return prev;
      }, {});
      return Object.keys(groupedObj).map(key => ({ key, value: groupedObj[key] }));
    }
    return [];
  }

  groupProjects: any = [];
  listenProjects(): any {
    this.projectSubscription = this.backend.getProjects().subscribe((projects) => {
      this.projectSubscription.unsubscribe();
      this.projects = projects.filter(o => o.assetId == this.assetId).sort((a, b) => new Date(a?.inspectionDate).getTime() - new Date(b?.inspectionDate).getTime());

      this.groupProjects = this.groupByInspectionDate(this.projects, 'inspectionDate')
      let leftProject;
      let rightProject;
      if (this.projectId) {
        const index = this.projects.findIndex(o => o.id == this.projectId);

        if (index != -1) {
          const groupIndex = this.groupProjects.findIndex(o => o.key == this.projects[index].inspectionDate);
          const projectIndex = this.groupProjects[groupIndex].value.findIndex(o => o.id === this.projectId);
          rightProject = this.groupProjects[groupIndex].value[projectIndex];
          if (this.groupProjects[groupIndex].value > 1) {
            leftProject = this.groupProjects[groupIndex].value[projectIndex + 1] ? this.groupProjects[index].value[projectIndex + 1] :
              this.groupProjects[groupIndex].value[projectIndex - 1];
          } else {
            const fileredProjects = this.projects.filter(o => o.id != this.projectId);
            if (index === this.projects.length - 1) {
              leftProject = this.projects[this.projects.length - 2];
            } else {
              leftProject = fileredProjects[fileredProjects.length - 1];
            }

          }
        }

      } else {
        if (this.groupProjects.length > 1) {
          leftProject = this.groupProjects[this.groupProjects.length - 2].value[0];
        } else {
          leftProject = this.groupProjects[this.groupProjects.length - 1].value[1];
        }
        rightProject = this.groupProjects[this.groupProjects.length - 1].value[0];

      }

      const newOptions: Options = Object.assign({}, this.options);
      newOptions.ceil = this.projects.length;
      newOptions.floor = 0;
      newOptions.showTicks = true;
      newOptions.showTicksValues = false;
      newOptions.stepsArray = [];
      this.groupProjects.forEach((element, i) => {
        newOptions.stepsArray.push({
          value: i,
          legend: element.key//element.name.toString().length > 10 ? element.name.substr(0, 9) + ".." : element.name,
        })

        element.value.forEach(project => {
          if (leftProject.id == project.id) {
            this.oldValue = this.value = i;
          }
          if (rightProject.id == project.id) {
            this.oldHighValue = this.highValue = i;
          }
        });


      })
      this.options = newOptions;
      this.renderToolTip();
      if (!projects) return of();

      if (!this.selectedLeftProject) {
        this.selectedLeftProject = leftProject;
        this.uiService.activeProjectLeftChange.next(this.selectedLeftProject);
      }
      if (!this.selectedRightProject) {
        this.selectedRightProject = rightProject;
        this.uiService.activeProjectRightChange.next(this.selectedRightProject);
      }
      this.changeDetectorRef.detectChanges();
    });
  }



  leftProcessing: boolean = false;
  rightProcessing: boolean = false;
  leftProjectAnnotationSubscription: Subscription = new Subscription();
  rightProjectAnnotationSubscription: Subscription = new Subscription();


  ngOnInit() {
    /* Left Project Change Events  */
    this.leftProjectImages$ = this.uiService.activeProjectLeftChange.pipe(
      mergeMap((project: any) => {
        this.leftProcessing = true;
        console.log('LEFT', project.id);
        this.clearLeftProjectImages();
        this.selectedLeftProject = project;
        this.getTags(project.id, 'left');
        if (this.leftProjectAnnotationSubscription) {
          this.leftProjectAnnotationSubscription.unsubscribe();
        }
        this.leftProjectAnnotationSubscription = this.backend.getBatchProjectAnnotations$(project.id)
          .pipe(takeUntil(this.onDestroy$)).subscribe((annotations: any) => {
            this.leftProjectAnnotationSubscription.unsubscribe();
            this.leftProjectAnnotations = annotations;
          });
        return combineLatest(
          this.backend.getAllProjectImages$(project.id),
          this.backend.getProjectUnprocessedImages(project.id),
          // this.backend.getProjectAnnotations(this.selectedLeftProject.id)
        )
      }),
      mergeMap((images: any[]) => {
        //    this.leftProjectAnnotations = images[2];
        this.leftProjectUnprocessedImages = [...new Set(images[1].map(o => o.imageId))]
        const imageDoc = images[0].map((doc: any) => {
          return new Promise((resolve, reject) => {
            resolve({ doc })
          });
        });
        this.leftProjectImages$ = of({
          loading: false
        })
        return Promise.all(imageDoc);
      }),
      map((value: any) => ({
        loading: value.type === 'start',
        value: value.type ? value.value : value,
      })),
      tap((images) => {
        this.cdr.detectChanges();
        this.leftProjectImages$ = of(images);
      }),
      startWith({ loading: true }),
      catchError((error) => of({ loading: false, error }))
    );
    /* Right Project Change Events  */
    this.rightProjectImages$ = this.uiService.activeProjectRightChange.pipe(
      mergeMap((project: any) => {
        this.rightProcessing = true;
        console.log('RIGHT', project.id)
        this.clearRightProjectImages();
        this.selectedRightProject = project;
        this.getTags(project.id, 'right');
        if (this.rightProjectAnnotationSubscription) {
          this.rightProjectAnnotationSubscription.unsubscribe();
        }
        this.rightProjectAnnotationSubscription = this.backend.getBatchProjectAnnotations$(project.id)
          .pipe(takeUntil(this.onDestroy$)).subscribe((annotations: any) => {
            this.rightProjectAnnotationSubscription.unsubscribe();
            this.rightProjectAnnotations = annotations;
          });
        return combineLatest(
          this.backend.getAllProjectImages$(project.id),
          this.backend.getProjectUnprocessedImages(project.id),
          //   this.backend.getProjectAnnotations(this.selectedRightProject.id)
        )
      }),
      mergeMap((images: any[]) => {
        //    this.rightProjectAnnotations = images[2];
        this.rightProjectUnprocessedImages = [...new Set(images[1].map(o => o.imageId))]
        const imageDoc = images[0].map((doc: any) => {
          return new Promise((resolve, reject) => {
            resolve({ doc })
          });
        });

        this.rightProjectImages$ = of({
          loading: false
        })
        return Promise.all(imageDoc);
      }),
      map((value: any) => ({
        loading: value.type === 'start',
        value: value.type ? value.value : value,
      })),
      tap((images) => {
        this.cdr.detectChanges();
        this.rightProjectImages$ = of(images);
      }),
      startWith({ loading: true }),
      catchError((error) => of({ loading: false, error }))
    );

    /* Left Image Subscription Events  */
    if (this.leftImagesSubscription) {
      this.leftImagesSubscription.unsubscribe()
    }
    this.leftImagesSubscription =
      this.leftProjectImages$.pipe().subscribe((images) => {
        this.leftProjectImages = images;
        this.cdr.detectChanges();

        if (images && images.value && images.value.length > 0) {
          /**Training Process Check Status */
          if (this.leftTrainSubscription) {
            this.leftTrainSubscription.unsubscribe();
          }
          this.leftTrainSubscription = this.backend.getImageSimilarity(this.selectedLeftProject.id).pipe(takeUntil(this.onDestroy$)).subscribe(train => {
            this.noMatchFound = false;
            this.leftProjectStatus = train[0]?.status || 'no_status',
              this.filterKeyProject()
            this.leftProcessing = false;
          });

        } else {
          this.leftProcessing = false;
        }
      });

    /* Right Image Subscription Events  */
    if (this.rightImagesSubscription) {
      this.rightImagesSubscription.unsubscribe()
    }
    this.rightImagesSubscription =
      this.rightProjectImages$.pipe().subscribe((images) => {

        this.rightProjectImages = images;
        this.cdr.detectChanges();
        if (images && images.value && images.value.length > 0) {
          /**Training Process Check Status */
          if (this.rightTrainSubscription) {
            this.rightTrainSubscription.unsubscribe();
          }
          this.rightTrainSubscription = this.backend.getImageSimilarity(this.selectedRightProject.id).pipe(takeUntil(this.onDestroy$)).subscribe(train => {
            this.rightProjectStatus = train[0]?.status || 'no_status',
              this.filterValueProject();
            this.rightProcessing = false;

          });
        } else {
          this.rightProcessing = false;

        }
      });
    this.cdr.detectChanges();

  }

  filterLeftImages: any;
  filterKeyProject() {
    if (!this.leftProjectImages || !this.leftProjectImages.value) {
      return;
    }
    if (this.searchMode === "manual") {
      this.noMatchFound = false;
      if (this.selectedImages == 'all') {
        this.filterLeftImages = this.leftProjectImages.value;
      } else {
        const annotatedImages = this.leftProjectAnnotations.filter(o => o &&
          (o.polygons && o.polygons.find(c => c.tag != 'context')
            || (o.hotspots && o.hotspots.length)
            || (o.coldspots && o.coldspots.length)
            || (o.thermalPolygons && o.thermalPolygons.length)
          )).map(o => o.imageId);
        this.filterLeftImages = this.leftProjectImages.value.filter(o => annotatedImages.includes(o.doc.id));
      }
      if (this.leftProjectFilteredImages && this.leftProjectFilteredImages.filter) {
        this.filterLeftImages = this.filterLeftImages.filter(o => this.leftProjectFilteredImages.images.includes(o.doc.id));
      }
      if (this.filterLeftImages.length) {
        this.activate(0, 'left');
      } else {
        this.clearLeftImage();
      }
    } else {
      if (this.selectedImages == 'all') {
        this.filterLeftImages = this.leftProjectImages.value;
      } else {
        const annotatedImages = this.leftProjectAnnotations.filter(o => o &&
          (o.polygons && o.polygons.find(c => c.tag != 'context')
            || (o.hotspots && o.hotspots.length)
            || (o.coldspots && o.coldspots.length)
            || (o.thermalPolygons && o.thermalPolygons.length)
          )).map(o => o.imageId);
        this.filterLeftImages = this.leftProjectImages.value.filter(o => annotatedImages.includes(o.doc.id));
      }
      if (this.leftProjectFilteredImages && this.leftProjectFilteredImages.filter) {
        this.filterLeftImages = this.filterLeftImages.filter(o => this.leftProjectFilteredImages.images.includes(o.doc.id));
      }
    }
  }


  filterRightImages: any;
  filterValueProject() {
    if (!this.rightProjectImages || !this.rightProjectImages.value) {
      return;
    }
    if (this.searchMode === "manual") {
      if (this.selectedImages == 'all') {
        this.filterRightImages = this.rightProjectImages.value;
      } else {
        const annotatedImages = this.rightProjectAnnotations.filter(o => o
          &&
          (o.polygons && o.polygons.find(c => c.tag != 'context')
            || (o.hotspots && o.hotspots.length)
            || (o.coldspots && o.coldspots.length)
            || (o.thermalPolygons && o.thermalPolygons.length)
          )).map(o => o.imageId);
        this.filterRightImages = this.rightProjectImages.value.filter(o => annotatedImages.includes(o.doc.id));
      }
      if (this.rightProjectFilteredImages && this.rightProjectFilteredImages.filter) {
        this.filterRightImages = this.filterRightImages.filter(o => this.rightProjectFilteredImages.images.includes(o.doc.id));
      }

      if (this.filterRightImages.length) {
        this.activate(0, 'right');
      } else {
        this.clearRightImage();
      }
    } else {
      if (this.selectedImages == 'all') {
        this.filterRightImages = this.rightProjectImages.value;
      } else {
        const annotatedImages = this.rightProjectAnnotations.filter(o => o &&
          (o.polygons && o.polygons.find(c => c.tag != 'context')
            || (o.hotspots && o.hotspots.length)
            || (o.coldspots && o.coldspots.length)
            || (o.thermalPolygons && o.thermalPolygons.length)
          )).map(o => o.imageId);
        this.filterRightImages = this.rightProjectImages.value.filter(o => annotatedImages.includes(o.doc.id));
      }
      if (this.rightProjectFilteredImages && this.rightProjectFilteredImages.labelFilter) {
        this.filterRightImages = this.filterRightImages.filter(o => this.rightProjectFilteredImages.images.includes(o.doc.id));
      }
      if (this.filterRightImages.length) {
        this.activate(0, 'right');

      } else {
        this.clearRightImage();
      }
      this.resetSearchDta();
      // Image will check from AI service
      if (this.filterRightImages.length) {
        this.compare(this.filterRightImages.map(o => o.doc.id)[0]);
      }
    }
  }

  resetSearchDta() {
    this.searchData = [];
    this.thumbImages = [];
    this.isSearchRequestReady = false;
  }


  isSetLeftConfigStage = true;
  isSetRightConfigStage = true;
  ngAfterViewInit() {
    if (window) {
      this.renderer.listen(window, 'resize', () => {
        this.isSetLeftConfigStage = true;
        this.isSetRightConfigStage = true;
      });
    }
  }


  ngAfterViewChecked(): void {
    if (this.isSetLeftConfigStage && this.leftContainer) {
      this.isSetLeftConfigStage = false;
      this.setLeftContainerSize();
      if (this.leftActiveImgIdx !== undefined) {
        this.activate(this.leftActiveImgIdx, 'left');
      }
    }

    if (this.isSetRightConfigStage && this.rightContainer) {
      this.isSetRightConfigStage = false;
      this.setRightContainerSize();
      if (this.rightActiveImgIdx !== undefined) {
        this.activate(this.rightActiveImgIdx, 'right');
      }
    }

    if (this.leftContainer && this.isSetLeftContainerSize) {
      this.isSetLeftContainerSize = false;
      const { offsetWidth, offsetHeight } = this.leftContainer.nativeElement;
      this.leftContainerSize = {
        width: offsetWidth,
        height: offsetHeight
      };
    }

    if (this.rightContainer && this.isRightSetContainerSize) {
      this.isRightSetContainerSize = false;
      const { offsetWidth, offsetHeight } = this.rightContainer.nativeElement;
      this.rightContainerSize = {
        width: offsetWidth,
        height: offsetHeight
      };
    }
  }


  ngOnDestroy(): void {
    this.projectSubscription.unsubscribe();
    this.leftImagesSubscription.unsubscribe();
    this.rightImagesSubscription.unsubscribe();
    if (this.leftImageAnnotationsSub)
      this.leftImageAnnotationsSub.unsubscribe();
    if (this.rightImageAnnotationsSub)
      this.rightImageAnnotationsSub.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    if (this.leftTrainSubscription)
      this.leftTrainSubscription.unsubscribe();
    if (this.rightTrainSubscription)
      this.rightTrainSubscription.unsubscribe();
    this.clear3DModel();
    this.leftLabelSubscription.unsubscribe();
    this.rightLabelSubscription.unsubscribe();
    this.baselineModelSubscription.unsubscribe();

  }

  setLeftContainerSize(): void {
    if (this.leftContainer) {
      const { offsetWidth, offsetHeight } = this.leftContainer.nativeElement;
      this.leftContainerSize = {
        width: offsetWidth - (this.isMenu ? 250 : 0),
        height: offsetHeight
      };
      this.leftConfigStage$.next(this.leftContainerSize);
      this.leftConfigImg$.next(this.leftContainerSize);
    }
  }

  setRightContainerSize(): void {
    if (this.rightContainer) {
      const { offsetWidth, offsetHeight } = this.rightContainer.nativeElement;
      this.rightContainerSize = {
        width: offsetWidth - (this.isMenu ? 250 : 0),

        height: offsetHeight
      };
      this.rightConfigStage$.next(this.rightContainerSize);
      this.rightConfigImg$.next(this.rightContainerSize);
    }
  }

  getLeftTagSensitive(area) {
    return this.sensitiveProjectLeftTags.map(tag => tag.tag).includes(area.tag);
  }

  activeLeftSensitiveTags() {
    return this.activeLeftImgAreas.filter(area => area.annotation && this.getLeftTagSensitive(area))
  }

  getRightTagSensitive(area) {
    return this.sensitiveProjectRightTags.map(tag => tag.tag).includes(area.tag);
  }

  activeRightSensitiveTags() {
    return this.activeRightImgAreas.filter(area => area.annotation && this.getRightTagSensitive(area))
  }

  getTags(projectId: string, type: string): void {
    this.backend.getTags(projectId).pipe(takeUntil(this.onDestroy$)).subscribe(({ tags }) => {
      const activeTags = tags.filter(
        (x) => x.status !== 'de-active'
      );

      activeTags.forEach((activeTag, i) => {
        if (!this.colors[activeTag.tag]) {
          this.colors[activeTag.tag] = activeTag.color || this.backend.CONSTANTS.tags_colors[
            i % this.backend.CONSTANTS.tags_colors.length
          ];
        }
      });

      const height = 20;
      const padding = 3;

      if (type == 'left') {
        this.projectLeftTags = activeTags.map((tag) => tag.tag);
        this.sensitiveProjectLeftTags = activeTags.reduce((acc, tag) => tag.sensitivity ? [...acc, tag] : acc, []);
        this.leftTagsListHeight = this.projectLeftTags.length * (height + padding + 3);

        this.leftTagsListWidth = Math.max(...this.projectLeftTags.map(tag => tag.length)) * ((fontSize - 2) / 2) + padding;

        this.leftTagsTextConfigs = activeTags.map((tag, idx) => {
          const space = 3;

          return {
            label$: new BehaviorSubject({
              y: idx * (height + space),
              ...inverseScaleXY(this.leftStage ? this.leftStage.getScale() : 1)
            }),

            text$: new BehaviorSubject({
              fontSize: fontSize - 2,
              fontFamily: 'Montserrat',
              fontStyle: 'bold',
              text: tag.tag,
              fill: 'white',
              padding,
              wrap: 'none',
              lineHeight: 1,
              ellipsis: true,
            }),

            tag$: new BehaviorSubject({
              fill: '#222831',
              ...inverseScaleXY(this.leftStage ? this.leftStage.getScale() : 1)
            }),
          };
        });
      } else {
        this.projectRightTags = activeTags.map((tag) => tag.tag);
        this.sensitiveProjectRightTags = activeTags.reduce((acc, tag) => tag.sensitivity ? [...acc, tag] : acc, []);
        this.rightTagsListHeight = this.projectRightTags.length * (height + padding + 3);

        this.rightTagsListWidth = Math.max(...this.projectRightTags.map(tag => tag.length)) * ((fontSize - 2) / 2) + padding;

        this.rightTagsTextConfigs = activeTags.map((tag, idx) => {
          const space = 3;
          return {
            label$: new BehaviorSubject({
              y: idx * (height + space),
              ...inverseScaleXY(this.rightStage ? this.rightStage.getScale() : 1)
            }),

            text$: new BehaviorSubject({
              fontSize: fontSize - 2,
              fontFamily: 'Montserrat',
              fontStyle: 'bold',
              text: tag.tag,
              fill: 'white',
              padding,
              wrap: 'none',
              lineHeight: 1,
              ellipsis: true,
            }),

            tag$: new BehaviorSubject({
              fill: '#222831',
              ...inverseScaleXY(this.rightStage ? this.rightStage.getScale() : 1)
            }),
          };
        });
      }

    });
  }


  activateImage(idxDelta: number, type: string): void {
    if (type === 'left') {
      const activeImageIdx = this.filterLeftImages.findIndex(image => image.doc.id === this.leftImageId);
      if (this.filterLeftImages[activeImageIdx + +idxDelta]) {
        this.leftImageId = this.filterLeftImages[activeImageIdx + +idxDelta].doc?.id || null;
        this.activate(activeImageIdx + +idxDelta, 'left')
      }
    } else {
      const activeImageIdx = this.filterRightImages.findIndex(image => image.doc.id === this.rightImageId);
      if (this.filterRightImages[this.rightActiveImgIdx + +idxDelta]) {
        this.rightImageId = this.filterRightImages[activeImageIdx + +idxDelta].doc?.id || null;
        this.activate(activeImageIdx + +idxDelta, 'right')
      }
    }
  }


  getLeftImageScale(idx: number, imageEl?: any): { widthScale: number; heightScale: number } {
    if (!this.filterLeftImages || !this.filterLeftImages[idx] || !this.filterLeftImages[idx].image) return;
    const image = this.filterLeftImages[idx].image;
    const { width, height } = image;
    const { width: stageWidth, height: stageHeight } = this.leftConfigStage$.getValue();
    const widthScale = stageWidth / width;
    const heightScale = stageHeight / height;

    return {
      widthScale: Math.min(widthScale, heightScale),
      heightScale: Math.min(widthScale, heightScale),
    };
  }


  getRightImageScale(idx: number, imageEl?: any): { widthScale: number; heightScale: number } {
    if (!this.filterRightImages || !this.filterRightImages[idx] || !this.filterRightImages[idx].image) return;
    const image = this.filterRightImages[idx].image;
    const { width, height } = image;
    const { width: stageWidth, height: stageHeight } = this.rightConfigStage$.getValue();
    const widthScale = stageWidth / width;
    const heightScale = stageHeight / height;

    return {
      widthScale: Math.min(widthScale, heightScale),
      heightScale: Math.min(widthScale, heightScale),
    };
  }


  activate(idx: number, type: string): void {
    if (type == 'left') {
      if (!this.filterLeftImages[idx]) return;
      const { image, doc } = this.filterLeftImages[idx];
      this.leftImageId = doc.id;
      this.setLeftContainerSize();
      if (image) {
        const { height, width } = image;
        const { widthScale, heightScale } = this.getLeftImageScale(idx);
        const newWidth = width * widthScale;
        const newHeight = height * heightScale;
        if (this.leftStage) {
          this.leftStage.reset();
        }
        this.getLeftImageAnnotations(doc);
        this.leftActiveImgIdx = idx;
        const newSize = {
          width: newWidth,
          height: newHeight,
        };

        this.leftConfigImg$.next({
          ...newSize,
          image: image
        });
        this.leftConfigStage$.next(newSize);
      }
      else {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.onload = () => {
          this.filterLeftImages[idx].image = image;
          const { height, width } = image;
          const { widthScale, heightScale } = this.getLeftImageScale(idx);
          const newWidth = width * widthScale;
          const newHeight = height * heightScale;
          if (this.leftStage) {
            this.leftStage.reset();
          }
          this.getLeftImageAnnotations(doc);
          this.leftActiveImgIdx = idx;
          const newSize = {
            width: newWidth,
            height: newHeight,
          };
          this.leftConfigStage$.next(newSize);
          this.leftConfigImg$.next({
            ...newSize,
            image: image
          });
        };
        image.src = doc.mediumFileUrl;
      }
      this.leftImageName = doc.fileName;
    } else {
      if (!this.filterRightImages[idx]) return;
      let { image, doc } = this.filterRightImages[idx];
      this.rightImageId = doc.id;
      this.setRightContainerSize();
      if (image) {
        const { height, width } = image;
        const { widthScale, heightScale } = this.getRightImageScale(idx);
        const newWidth = width * widthScale;
        const newHeight = height * heightScale;
        if (this.rightStage) {
          this.rightStage.reset();
        }
        this.getRightImageAnnotations(doc);
        this.rightActiveImgIdx = idx;

        const newSize = {
          width: newWidth,
          height: newHeight,
        };
        this.rightConfigImg$.next({
          ...newSize,
          image: image
        });
        this.rightConfigStage$.next(newSize);
      }
      else {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        let newSize = {};
        image.onload = () => {
          this.filterRightImages[idx].image = image;
          const { height, width } = image;
          const { widthScale, heightScale } = this.getRightImageScale(idx);
          const newWidth = width * widthScale;
          const newHeight = height * heightScale;
          if (this.rightStage) {
            this.rightStage.reset();
          }
          this.getRightImageAnnotations(doc);
          this.rightActiveImgIdx = idx;
          newSize = {
            width: newWidth,
            height: newHeight,
          };
          this.rightConfigStage$.next(newSize);
          this.rightConfigImg$.next({
            ...newSize,
            image: image
          });
        };
        image.src = doc.mediumFileUrl;
      }
      this.rightImageName = doc.fileName;
    }

    this.cdr.detectChanges();
  }

  activateThumb(idx: number, type: string) {
    this.activate(idx, type)
    if (this.searchMode == 'ai') {
      this.noMatchFound = false;
      const find = this.searchData.find(o => o.imageId == this.rightImageId);
      if (find) {
        this.thumbImages = find?.result;
        if (find?.result.length > 0) {
          this.noMatchFound = false;
          this.activateThumbImage(find.result[0].id)
        } else {
          this.clearLeftImage();
          this.noMatchFound = true;
        }
      } else {
        this.compare(this.rightImageId);
      }
    }
  }

  getUnprocessed(imageId: string) {
    return this.rightProjectUnprocessedImages.includes(imageId)
  }

  activateThumbImage(imageId) {
    const activeImageIdx = this.filterLeftImages.findIndex(image => image.doc.id === imageId);
    this.activate(activeImageIdx, 'left')
  }

  getLeftImageAnnotations(doc) {
    if (this.leftImageAnnotationsSub) {
      this.leftImageAnnotationsSub.unsubscribe();
    }
    this.leftImageAnnotationsSub = this.backend
      .getImageAnnotations$<{
        polygons: {
          id: string,
          polygon: KonvaConfig;
          tag: string,
          hasContext: boolean,
          note?: string,
          noteCreatedBy: { displayName: string, email: string }
          lastModifiedBy?: { displayName: string, uid: string }
        }[];
        thermalPolygons: {
          id: string,
          polygon: KonvaConfig;
          note?: string,
          noteCreatedBy: { displayName: string, email: string }
          lastModifiedBy?: { displayName: string, uid: string }
          minTemperature?: number,
          maxTemperature?: number,
          temperature?: number,
        }[];
        hotspots: {
          id: string,
          polygon: KonvaConfig;
          note?: string,
          noteCreatedBy: { displayName: string, email: string }
          lastModifiedBy?: { displayName: string, uid: string }
          minTemperature?: number,
          maxTemperature?: number,
          temperature?: number,
        }[];
        coldspots: {
          id: string,
          polygon: KonvaConfig;
          note?: string,
          noteCreatedBy: { displayName: string, email: string }
          lastModifiedBy?: { displayName: string, uid: string }
          minTemperature?: number,
          maxTemperature?: number,
          temperature?: number,
        }[];
        sensitive: number;
        narration: string;
        narrationModifiedBy: { displayName: string, uid: string };
        levels: [],
      }>(doc.id)
      .subscribe((annotations) => {
        const annotationData =
          annotations && annotations.polygons
            ? annotations.polygons.filter(o => o.tag != 'context').map((currentArea) => {
              const { tag, hasContext } = currentArea;
              let taggingItem = {
                ...this.updateTaggingItemConfig('left', currentArea.polygon, tag, currentArea.id, hasContext, currentArea['sensitive'], currentArea['note'], currentArea['levels']),
              };

              taggingItem = {
                ...taggingItem,
                annotation: {
                  ...taggingItem.annotation,
                  tag: tag,
                  type: 'polygons',
                  polygon: {
                    ...currentArea.polygon,
                    sensitive: currentArea['sensitive'] ? currentArea['sensitive'].toString() : null
                  },
                  hasContext: currentArea.hasContext,
                  note: currentArea.note,
                  noteCreatedBy: currentArea.noteCreatedBy,
                  lastModifiedBy: currentArea.lastModifiedBy ? currentArea.lastModifiedBy : null,
                  levels: this.sensitiveProjectLeftTags.find(o => o.tag === tag)?.levels || []
                }
              };
              return taggingItem;
            })
            : [];

        let data = [];
        if (annotations && annotations.thermalPolygons) {
          data = annotations.thermalPolygons.map(obj => ({ ...obj, type: 'thermalPolygons' }))
        }
        if (annotations && annotations.coldspots) {
          data = data.concat(annotations.coldspots.map(obj => ({ ...obj, type: 'coldspots' })))
        }
        if (annotations && annotations.hotspots) {
          data = data.concat(annotations.hotspots.map(obj => ({ ...obj, type: 'hotspots' })))
        }
        const thermalData = data.map((currentArea: any) => {
          let temperatureItem = {
            maxTemperature: currentArea.maxTemperature,
            minTemperature: currentArea.minTemperature,
            temperature: currentArea.temperature
          }

          let taggingItem = {
            ...this.updateTaggingItemConfig(
              'left', currentArea.polygon, "thermalPolygon", currentArea.id, false, currentArea.sensitive, currentArea.note
            ),
          };
          taggingItem = {
            ...taggingItem,
            annotation: {
              ...taggingItem.annotation,
              ...temperatureItem,
              tag: "",
              type: currentArea.type,
              polygon: {
                ...currentArea.polygon,
                sensitive: currentArea['sensitive'] ? currentArea['sensitive'].toString() : null
              },
              note: currentArea.note,
              noteCreatedBy: currentArea.noteCreatedBy,
              lastModifiedBy: currentArea.lastModifiedBy ? currentArea.lastModifiedBy : null,
              levels: []
            }
          };

          return taggingItem;
        })
        this.leftImageAnnotations = annotationData.concat(thermalData);
        this.leftTaggedAreas.set(doc.id, annotationData.concat(thermalData));
        this.cdr.detectChanges();
        return;
      });
  }

  getRightImageAnnotations(doc) {
    if (this.rightImageAnnotationsSub) {
      this.rightImageAnnotationsSub.unsubscribe();
    }
    this.rightImageAnnotationsSub = this.backend
      .getImageAnnotations$<{
        polygons: {
          id: string,
          polygon: KonvaConfig;
          tag: string,
          hasContext: boolean,
          note?: string,
          noteCreatedBy: { displayName: string, email: string }
          lastModifiedBy?: { displayName: string, uid: string }
        }[];
        thermalPolygons: {
          id: string,
          polygon: KonvaConfig;
          note?: string,
          noteCreatedBy: { displayName: string, email: string }
          lastModifiedBy?: { displayName: string, uid: string }
          minTemperature?: number,
          maxTemperature?: number,
          temperature?: number,
        }[];
        hotspots: {
          id: string,
          polygon: KonvaConfig;
          note?: string,
          noteCreatedBy: { displayName: string, email: string }
          lastModifiedBy?: { displayName: string, uid: string }
          minTemperature?: number,
          maxTemperature?: number,
          temperature?: number,
        }[];
        coldspots: {
          id: string,
          polygon: KonvaConfig;
          note?: string,
          noteCreatedBy: { displayName: string, email: string }
          lastModifiedBy?: { displayName: string, uid: string }
          minTemperature?: number,
          maxTemperature?: number,
          temperature?: number,
        }[];
        sensitive: number;
        levels: [];
        narration: string;
        narrationModifiedBy: { displayName: string, uid: string }
      }>(doc.id)
      .subscribe((annotations) => {

        const annotationData =
          annotations && annotations.polygons
            ? annotations.polygons.filter(o => o.tag != 'context').map((currentArea) => {
              const { tag, hasContext } = currentArea;
              let taggingItem = {
                ...this.updateTaggingItemConfig('right', currentArea.polygon, tag, currentArea.id, hasContext, currentArea['sensitive'], currentArea['note'], currentArea['levels']),
              };

              taggingItem = {
                ...taggingItem,
                annotation: {
                  ...taggingItem.annotation,
                  tag: tag,
                  type: 'polygons',
                  polygon: {
                    ...currentArea.polygon,
                    sensitive: currentArea['sensitive'] ? currentArea['sensitive'].toString() : null
                  },
                  hasContext: currentArea.hasContext,
                  note: currentArea.note,
                  noteCreatedBy: currentArea.noteCreatedBy,
                  lastModifiedBy: currentArea.lastModifiedBy ? currentArea.lastModifiedBy : null,
                  levels: this.sensitiveProjectLeftTags.find(o => o.tag === tag)?.levels || []
                }
              };
              return taggingItem;
            })
            : [];

        let data = [];
        if (annotations && annotations.thermalPolygons) {
          data = annotations.thermalPolygons.map(obj => ({ ...obj, type: 'thermalPolygons' }))
        }
        if (annotations && annotations.coldspots) {
          data = data.concat(annotations.coldspots.map(obj => ({ ...obj, type: 'coldspots' })))
        }
        if (annotations && annotations.hotspots) {
          data = data.concat(annotations.hotspots.map(obj => ({ ...obj, type: 'hotspots' })))
        }
        const thermalData = data.map((currentArea: any) => {
          let temperatureItem = {
            maxTemperature: currentArea.maxTemperature,
            minTemperature: currentArea.minTemperature,
            temperature: currentArea.temperature
          }

          let taggingItem = {
            ...this.updateTaggingItemConfig(
              'right', currentArea.polygon, "thermalPolygon", currentArea.id, false, currentArea.sensitive, currentArea.note
            ),
          };
          taggingItem = {
            ...taggingItem,
            annotation: {
              ...taggingItem.annotation,
              ...temperatureItem,
              tag: "",
              type: currentArea.type,
              polygon: {
                ...currentArea.polygon,
                sensitive: currentArea['sensitive'] ? currentArea['sensitive'].toString() : null
              },
              note: currentArea.note,
              noteCreatedBy: currentArea.noteCreatedBy,
              lastModifiedBy: currentArea.lastModifiedBy ? currentArea.lastModifiedBy : null,
              levels: []
            }
          };

          return taggingItem;
        })
        this.rightImageAnnotations = annotationData.concat(thermalData);
        this.rightTaggedAreas.set(doc.id, annotationData.concat(thermalData));
        this.cdr.detectChanges();
      });
  }

  getTagsGroupXY(
    area: KonvaConfig
    , type: string): { tagsGroupX: number; tagsGroupY: number, tagTextY: number } {
    if (type == 'left') {
      const {
        width: stageWidth,
        height: stageHeight,
      } = this.leftConfigStage$.getValue();
      const { x, y, width, height, originY } = getPolygonRect(area.points);

      const transform = this.leftStage.getStage().getTransform().copy();
      const { x: x1, y: y1 } = transform.point({ x, y }),
        { x: x2, y: y2 } = transform.point({ x: x + width, y: y + height });

      const tagsGroupRight = x + width + 1.5 / this.leftStage.getScale();
      const tagsGroupLeft = x - (this.leftTagsListWidth + 1.5) / this.leftStage.getScale();
      const tagsGroupTop = y;
      const tagsGroupBottom = y + height - this.leftTagsListHeight / this.leftStage.getScale();

      const tagTextTop = y - scaledLabelOffset(this.leftStage.getScale());
      const tagTextBottom = y + height + 1.5 / this.leftStage.getScale();

      const tagsGroupX =
        stageWidth < x2 + this.leftTagsListWidth
          ? tagsGroupLeft
          : tagsGroupRight;

      const tagsGroupY =
        stageHeight < y1 + this.leftTagsListHeight
          ? tagsGroupBottom
          : tagsGroupTop;

      const tagTextY =
        y1 - scaledLabelOffset(1) > 0
          ? tagTextTop
          : tagTextBottom

      return {
        tagsGroupX: tagsGroupX < 0 ? 5 : tagsGroupX,
        tagsGroupY,
        tagTextY
      };
    } else {

      const {
        width: stageWidth,
        height: stageHeight,
      } = this.rightConfigStage$.getValue();
      const { x, y, width, height, originY } = getPolygonRect(area.points);

      const transform = this.rightStage.getStage().getTransform().copy();
      const { x: x1, y: y1 } = transform.point({ x, y }),
        { x: x2, y: y2 } = transform.point({ x: x + width, y: y + height });

      const tagsGroupRight = x + width + 1.5 / this.rightStage.getScale();
      const tagsGroupLeft = x - (this.rightTagsListWidth + 1.5) / this.rightStage.getScale();
      const tagsGroupTop = y;
      const tagsGroupBottom = y + height - this.rightTagsListHeight / this.rightStage.getScale();

      const tagTextTop = y - scaledLabelOffset(this.rightStage.getScale());
      const tagTextBottom = y + height + 1.5 / this.rightStage.getScale();

      const tagsGroupX =
        stageWidth < x2 + this.rightTagsListWidth
          ? tagsGroupLeft
          : tagsGroupRight;

      const tagsGroupY =
        stageHeight < y1 + this.rightTagsListHeight
          ? tagsGroupBottom
          : tagsGroupTop;

      const tagTextY =
        y1 - scaledLabelOffset(1) > 0
          ? tagTextTop
          : tagTextBottom

      return {
        tagsGroupX: tagsGroupX < 0 ? 5 : tagsGroupX,
        tagsGroupY,
        tagTextY
      };
    }

  }

  getPalette(type: string) {
    return colorPalette[
      type === 'left' ? this.selectedLeftProject?.colorPalette :
        this.selectedRightProject?.colorPalette] || '335AFF';
  }

  updateTaggingItemConfig(type: string, area: KonvaConfig, tag: string, id?: string, hasContext?: boolean, sensitive?, note?: string, levels?,
    minTemperature?: number,
    maxTemperature?: number, temperature?: number): TaggedArea {
    const { widthScale, heightScale } = type == 'left' ? this.getLeftImageScale(this.leftActiveImgIdx) :
      this.getRightImageScale(this.rightActiveImgIdx);
    if (!widthScale && !heightScale) {
      return;
    }
    const polygon = {
      points: id ? getScaledPolygon(area.points, {
        x: widthScale,
        y: heightScale,
      }) : area.points,
    };
    const { originX, originY, x, y, width, height } = getPolygonRect(polygon.points);
    const { tagsGroupX, tagsGroupY, tagTextY } = this.getTagsGroupXY(polygon, type);

    const annotations = id ? { tag, annotation: { id, sensitive, levels, ...area, minTemperature, maxTemperature, temperature } } : {};
    return {
      ...annotations,
      config$: area['closedType'] == "point" ?
        new BehaviorSubject({
          ...polygon,
          radius: 3,
          x: polygon.points[0],
          y: polygon.points[1],
          stroke: !note ? '#FFF' : '#4456F9',
          fill: this.getPalette(type),
          strokeWidth: 2,
        })
        : new BehaviorSubject({
          ...polygon,
          stroke:
            tag == 'thermalPolygon' ?
              this.getPalette(type)
              : tag && tag !== 'context' ? this.colors[tag] || this.colors[tag.toLowerCase()] : (tag === 'context' ? '#335AFF' : color)
          ,
          fill: this.uiService.isMask ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0)',
          strokWidth: 3,
          strokeScaleEnabled: false,
          closed: area['closedType'] == "line" ? false : true,
          dash: tag === 'context' ? [2, 2] : null
        }),
      textConfig$: new BehaviorSubject({
        fontSize: fontSize - 2,
        fontFamily: 'Montserrat',
        fontStyle: 'bold',
        x: originX,
        y: tagTextY,
        fill: '#fff',
        text: tag,
        padding: 5,
        wrap: 'none',
        lineHeight: 1,
        ellipsis: true,
        ...inverseScaleXY(type == 'left' ? this.leftStage.getScale() : this.rightStage.getScale()),
        listening: false,
        visible: (tag !== 'thermalPolygon')
      }),
      tag$: new BehaviorSubject({
        fill: '#222831',
        ...inverseScaleXY(type == 'left' ? this.leftStage.getScale() : this.rightStage.getScale()),
        listening: false,
        visible: (tag !== 'context' && tag !== 'thermalPolygon')
      }),
      label$: new BehaviorSubject({
        y: originY < tagTextY ? 5 : tagTextY,
        x,
        listening: false,
        visible: (tag !== 'context')
      }),
      tagsGroupConfig$: new BehaviorSubject({
        x: tagsGroupX,
        y: tagsGroupY,
        ...inverseScaleXY(type == 'left' ? this.leftStage.getScale() : this.rightStage.getScale()),
        visible: !!!id
      }),
      closedType: area['closedType']

    };

  }


  formatLabel(value: number): string {
    switch (value) {
      case 0:
        return 'Low';
      case 1:
        return 'Medium';
      case 2:
        return 'High';
    }
  }

  getSliderClass(area) {
    if (area?.annotation?.sensitive === '2') {
      return 'high';
    } else if (area?.annotation?.sensitive === '1') {
      return 'medium';
    }
    else {
      return 'low';
    }
  }

  getSliderStyle(area: TaggedArea, type: string): Partial<CSSStyleDeclaration> {
    if (type == 'left' && !this.leftStage) { return null; }
    if (type == 'right' && !this.rightStage) { return null; }

    const { height } = getPolygonRect(area.config$.getValue().points);
    const scale = type == 'left' ? this.leftStage.getScale() : this.rightStage.getScale();
    return {
      minHeight: `${Math.max((height) * scale) + (scale * 3)}px`,
    };
  }

  getAreaTagPanelStyle(area: TaggedArea, type: string): Partial<CSSStyleDeclaration> {
    if (type == 'left' && !this.leftStage) { return null; }
    if (type == 'right' && !this.rightStage) { return null; }

    const { tagsGroupX, tagsGroupY } = this.getTagsGroupXY(area.config$.getValue(), type);
    const { x: left, y: top, height } = getPolygonRect(area.config$.getValue().points);

    if (type == 'left') {
      const scale = this.leftStage.getScale();
      const transform = this.leftStage.getStage().getTransform().copy();
      let { x, y } = transform.point({ x: tagsGroupX, y: tagsGroupY });
      if (tagsGroupX < left) x += this.leftTagsListWidth - 20;

      if (tagsGroupY < top) y += this.leftTagsListHeight - Math.max((height - 5) * scale, 50);
      return {
        top: `${y}px`,
        left: `${x}px`,
      };

    } else {
      const scale = this.rightStage.getScale();
      const transform = this.rightStage.getStage().getTransform().copy();
      let { x, y } = transform.point({ x: tagsGroupX, y: tagsGroupY });
      if (tagsGroupX < left) x += this.rightTagsListWidth - 20;

      if (tagsGroupY < top) y += this.rightTagsListHeight - Math.max((height - 5) * scale, 50);
      return {
        top: `${y}px`,
        left: `${x}px`,
      };
    }
  }


  getAreaSeverityIconStyle(area: TaggedArea, type: string): Partial<CSSStyleDeclaration> {
    if (type == 'left' && !this.leftStage) { return null; }
    if (type == 'right' && !this.rightStage) { return null; }
    const { x, y, height, width } = getPolygonRect(area.config$.getValue().points);
    const positionOffset = type === 'left' ? this.leftStage.getStage().position() :
      this.rightStage.getStage().position();
    const scale = type === 'left' ? this.leftStage.getScale() : this.rightStage.getScale();
    if (area.annotation && area.annotation.closedType && area.annotation.closedType != "rectangle") {
      return {
        top: `${positionOffset.y - 10 + (area.config$.getValue().points[area.config$.getValue().points.length - 1] * scale)}px`,
        left: `${positionOffset.x + (10) + (area.config$.getValue().points[area.config$.getValue().points.length - 2] * scale)}px`,
      };

    }
    else {
      return {
        top: `${positionOffset.y + (y + height - 15 * (1 / scale)) * scale}px`,
        left: `${positionOffset.x + (x + width - 15 * (1 / scale)) * scale}px`,
      };
    }
  }


  getMidpointOfPolygon(polygonPoints: number[]) {
    const xValues = polygonPoints.filter((_, i) => i % 2 === 0);
    const yValues = polygonPoints.filter((_, i) => i % 2 === 1);

    // Calculate the average midpoint
    const xSum = xValues.reduce((sum, point) => sum + point, 0);
    const ySum = yValues.reduce((sum, point) => sum + point, 0);

    return {
      x: xSum / xValues.length,
      y: ySum / yValues.length,
    };

  }
  getAreaTempratureLabelStyle(area: TaggedArea, type: string): Partial<CSSStyleDeclaration> {
    if (type === 'left' && !this.leftStage) { return null; }
    if (type === 'right' && !this.rightStage) { return null; }

    const points = area.config$.getValue().points;
    const { x, y, height, width } = getPolygonRect(points);
    const positionOffset = type === 'left' ? this.leftStage.getStage().position() : this.rightStage.getStage().position();
    const scale = type === 'left' ? this.leftStage.getScale() : this.rightStage.getScale();

    if (area.annotation && area.annotation.closedType) {
      if (area.annotation.closedType != "rectangle" &&
        area.annotation.closedType != "point") {
        return {
          top: `${positionOffset.y + (this.getMidpointOfPolygon(points).y * scale)}px`,
          left: `${positionOffset.x + (this.getMidpointOfPolygon(points).x * scale)}px`,
        };
      } else {

        if (area.annotation.closedType === 'point') {
          return {
            top: `${positionOffset.y - 15 + (this.getMidpointOfPolygon(points).y * scale)}px`,
            left: `${positionOffset.x + (this.getMidpointOfPolygon(points).x * scale)}px`,
          };
        } else {
          return {
            top: `${positionOffset.y + (this.getMidpointOfPolygon(points).y * scale)}px`,
            left: `${positionOffset.x + (x + 5 * (1 / scale)) * scale}px`,
          };
        }
      }
    }

  }


  getBGColor(area) {
    if (area.annotation.sensitive == 0) {
      return '#d4d4d4';
    } else {
      return area.annotation.levels?.find(o => o.level == area.annotation.sensitive)?.color;
    }
  }
  getTitle(area) {
    if (!area.annotation.sensitive) {
      return 'not marked';
    } else {
      const level = area.annotation.levels?.find(o => o.level == area.annotation.sensitive);
      if (level) {
        return `level ${level.level},   ${level?.title}`;
      }
    }
  }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser();
  }

  handleClick(type: string): void {
    if (type === 'left') {
      this.uiService.selectedProjectId = this.selectedLeftProject.id;
      this.router.navigateByUrl(`/dashboard/projects/${this.selectedLeftProject.id}/images/${this.leftImageId}`);
    } else {
      this.uiService.selectedProjectId = this.selectedRightProject.id;
      this.router.navigateByUrl(`/dashboard/projects/${this.selectedRightProject.id}/images/${this.rightImageId}`);
    }
  }

  prevCompare() {
    this.activateImage(-1, 'right');
    if (!this.rightImageId) {
      return;
    }
    if (this.searchMode === 'ai') {
      this.calls.next(true);
      const find = this.searchData.find(o => o.imageId == this.rightImageId);
      if (find) {
        this.thumbImages = find?.result;
        if (find?.result.length > 0) {
          this.noMatchFound = false;
          this.activateThumbImage(find.result[0].id)
        } else {
          this.clearLeftImage();
          this.noMatchFound = true;
        }
      } else {
        this.service
          .getData()
          .pipe(takeUntil(this.calls))
          .subscribe((res) => {
            this.compare(this.rightImageId);

          });

      }
    }
  }
  // Mock of an actual service
  service = {
    getData: () => of({ call: true }).pipe(delay(500)),
  };

  calls = new Subject();
  nextCompare() {
    this.activateImage(1, 'right');
    if (!this.rightImageId) {
      return;
    }
    if (this.searchMode === 'ai') {
      this.calls.next(true);
      const find = this.searchData.find(o => o.imageId == this.rightImageId);
      if (find) {
        this.thumbImages = find?.result;
        if (find?.result.length > 0) {
          this.noMatchFound = false;
          this.activateThumbImage(find.result[0].id)
        } else {
          this.clearLeftImage();
          this.noMatchFound = true;
        }
      } else {
        this.service
          .getData()
          .pipe(takeUntil(this.calls))
          .subscribe((res) => {
            this.compare(this.rightImageId);
          });
      }
    }

  }

  clearLeftImage() {
    this.leftActiveImgIdx = null;
    this.leftImageName = null;
    this.leftImageId = null;
    this.thumbImages = [];
    this.leftConfigImg$.next({
      image: null,
      mediumImage: null,
    });
  }

  clearRightImage() {
    this.rightActiveImgIdx = null;
    this.rightImageName = null;
    this.rightImageId = null;
    this.rightConfigImg$.next({
      image: null,
      mediumImage: null,
    });
  }

  clearLeftProjectImages() {
    this.clearLeftImage();
    this.noMatchFound = false;
    this.filterLeftImages = [];
    this.leftProjectImages = null;
    this.leftProjectAnnotations=null;
    this.leftProjectImages$ = of({
      loading: true
    })

  }

  clearRightProjectImages() {
    this.rightActiveImgIdx = null;
    this.rightImageName = null;
    this.rightImageId = null;
    this.rightConfigImg$.next({
      image: null,
      mediumImage: null,
    });
    this.filterRightImages = [];
    this.rightProjectAnnotations=null;
    this.rightProjectImages = null;
    this.rightProjectImages$ = of({
      loading: false
    })


  }

  clearCurrentFilter() {
    this.groupMode = null;
    this.labelMode = null;
    this.leftProjectFilteredImages = null;
    this.rightProjectFilteredImages = null;
  }

  back() {
    this.location.back();
  }

  async copyClipboard() {
    var dataURL = this.leftStage.getStage().toDataURL();
    var rightdataURL = this.rightStage.getStage().toDataURL();
    this.isCopying = true;
    window.onscroll = function () {
      window.scrollTo(0, 0);
    };
    setTimeout(() => {
      const leftEl: any = document.getElementById('copy_left')
      leftEl.src = dataURL;
      const rightEl: any = document.getElementById('copy_right')
      rightEl.src = rightdataURL;
      setTimeout(async () => {
        const copyEl: any = document.getElementById('copy_content');
        let htmlContent = await htmlToImage.toBlob(copyEl);
        const snapshot = await this.storage.ref("clipbord").child(uuidv4()).put(htmlContent, { contentType: 'image/jpg' })
        const url = await snapshot.ref.getDownloadURL();
        this.callToClipboard(url);
        window.onscroll = function () { };
        this.isCopying = false;
      }, 2000);
    }, 1000);
  }

  async callToClipboard(url: string) {
    this.copyDialogRef = this.dialog.open(this.copyDialog, {
      data: {
        url: url
      }
    });
  }
  copy() {
    this.toastr.success('Copied to clipboard')
    this.copyDialogRef.close();
  }

  isSearchError: boolean = false;
  aiSubs;
  compare(imageId) {
    this.clearLeftImage();
    this.isSearchError = false;
    if (this.filterLeftImages?.length > 0
      && this.filterRightImages?.length > 0
      && this.leftProjectStatus === this.Success
      && this.rightProjectStatus === this.Success
      && !this.getUnprocessed(imageId)) {
      this.isLoading = true;
      const newOptions: Options = Object.assign({}, this.options, { disabled: true });
      this.options = newOptions;
      this.noMatchFound = false;
      let params = {
        "mode": "search",
        "projectId": this.selectedLeftProject.id,
        "images": [imageId]
      };
      if (this.labelMode || this.groupMode) {
        params['labelImages'] = this.leftProjectFilteredImages.images;
      }

      this.aiSubs = this.backend.imageSimilaritySearch(params).pipe(retry(2)).pipe(take(1)).subscribe((search) => {
        if (this.searchMode == 'manual') {
          return;
        }
        this.isLoading = false;
        const newOptions: Options = Object.assign({}, this.options, { disabled: false });
        this.options = newOptions;
        const data = search.data;
        const imageIds = Object.keys(data);
        imageIds.forEach(imageId => {
          const searchResult = [];
          let keyIds = Object.keys(data[imageId]);
          keyIds.forEach(id => {
            const find = this.filterLeftImages.find(o => o.doc.id == id)
            if (find) {
              searchResult.push({
                id: id,
                src: find.doc.thumbFileUrl
              })
            }
          });
          this.searchData.concat(this.searchData.push(
            {
              imageId: imageId,
              result: searchResult
            }
          ))
        });
        const find = this.searchData.find(o => o.imageId == this.rightImageId);
        this.isLoading = false;
        this.thumbImages = find?.result;
        if (find?.result.length > 0) {
          this.noMatchFound = false;
          this.activateThumbImage(find.result[0].id)
        } else {
          this.noMatchFound = true;
        }
      }, error => {
        this.isLoading = false;
        const newOptions: Options = Object.assign({}, this.options, { disabled: false });
        this.options = newOptions;
        this.isSearchError = true;
      });
    }
  }

  cancelAIRequest() {
    if (this.aiSubs) {
      this.aiSubs.unsubscribe();
    }
    this.isLoading = false;
    const newOptions: Options = Object.assign({}, this.options, { disabled: false });
    this.options = newOptions;
    this.isSearchError = false;
  }

  copyReport() {
    this.addReportDialogRef = this.dialog.open(this.addReportDialog, {
      data: {
        title: '',
        leftThumbImage: this.filterLeftImages.find(o => o.doc.id == this.leftImageId).doc.thumbFileUrl,
        rightThumbImage: this.filterRightImages.find(o => o.doc.id == this.rightImageId).doc.thumbFileUrl
      }
    });
  }

  addToReport(title) {
    this.backend.addImageTimeline(
      this.selectedRightProject.id,
      this.leftImageId,
      this.rightImageId,
      title,
      this.selectedLeftProject.inspectionDate,
      this.selectedRightProject.inspectionDate).subscribe(() => {
        this.addReportDialogRef.close();
        this.toastr.success('Image copied to report');
      })
  }

  train(project) {
    project.loading = true;
    this.backend.imageSimilarityTrain(project.id).pipe(
      takeUntil(this.onDestroy$)).subscribe(images => {
        project.loading = false;
        //this.backend.trainedProject(project.id, true);
      })
  }


  open2DModel() {
    const data = {
      assetId: this.assetId,
      projects: this.projects,
      isAssetOwner: false,
      asset: this.asset,
      panelsVisible: true,
      rightImageId: this.rightImageId,
      leftImageId: this.leftImageId,
    };
    if (this.asset.baseModel) {
      this.dialogRef = this.dialog.open(this.baseModelDialog, {
        width: '100%',
        height: '100vh',
        panelClass: 'report-panel',
        data,
      });

    }
  }

  dialogRef: any;
  chooseLabels() {
    const data = {
      assetId: this.assetId,
      projects: this.projects,
      isAssetOwner: false,
      asset: this.asset
    };
    if (this.asset.baseModel) {
      this.dialogRef = this.dialog.open(this.baseModelDialog, {
        width: '100%',
        height: '95vh',
        panelClass: 'report-panel',
        data,
      });
      this.dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).pipe().
        subscribe(data => {
          if (data) {
            this.labelMode = "labels";
            let leftProjectLabelImages = [];
            let countLeftLabels = 0;
            let rightProjectLabelImages = [];
            let countRightLabels = 0;
            if (data.type === 'DXF') {
              this.leftProjectFilteredImages = {
                filter: true,
                images: data.leftProjectImages
              }
              this.filterKeyProject();
              this.rightProjectFilteredImages = {
                filter: true,
                images: data.rightProjectImages
              }
              this.filterValueProject();

            }
            else {
              data.labels.forEach(label => {

                this.backend.getLinkedImages(this.assetId, label.modelId, this.selectedLeftProject.id).subscribe(labelImages => {
                  countLeftLabels++;
                  if (labelImages) {
                    leftProjectLabelImages = leftProjectLabelImages.concat(labelImages[label.id]?.['images'] || [])
                  }
                  if (countLeftLabels === data.labels.length) {
                    this.leftProjectFilteredImages = {
                      filter: true,
                      images: leftProjectLabelImages
                    }
                    this.filterKeyProject();
                  }
                })

                this.backend.getLinkedImages(this.assetId, label.modelId, this.selectedRightProject.id).subscribe(labelImages => {
                  countRightLabels++;
                  if (labelImages) {
                    rightProjectLabelImages = rightProjectLabelImages.concat(labelImages[label.id]?.['images'] || [])
                  }
                  if (countRightLabels === data.labels.length) {
                    this.rightProjectFilteredImages = {
                      filter: true,
                      images: rightProjectLabelImages
                    }
                    this.filterValueProject();
                  }
                })
              });
            }

          }

        })
    } else {
      this.toastr.warning("No Base Models added yet.")
    }
  }

  chooseGroups() {
    const data = {
      keyProject: this.selectedLeftProject,
      valueProject: this.selectedRightProject
    };

    const dialogRef = this.dialog.open(GroupFilterComponent, {
      width: '50%',
      height: '100vh',
      panelClass: 'bg-panel',
      data,
    });
    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).pipe().
      subscribe(data => {
        if (data) {
          this.groupMode = "groups";
          let leftImages = []
          data.keyProjectGroups.forEach(group => {
            leftImages = leftImages.concat(group.images || [])
          });
          this.leftProjectFilteredImages = {
            filter: true,
            images: leftImages
          }
          this.filterKeyProject();
          let rightImages = []
          data.valueProjectGroups.forEach(group => {
            rightImages = rightImages.concat(group.images || [])
          });
          this.rightProjectFilteredImages = {
            filter: true,
            images: rightImages
          }
          this.filterValueProject();
        }

      })
  }

  closeLabel() {
    this.uiService.activeProjectRightChange.next(this.selectedRightProject)
    this.labelImages = []
  }


  onImageModeChange(value) {
    this.selectedImages = value;
    this.filterKeyProject();
    this.filterValueProject();
  }

  onSearchModeChange(value) {
    this.searchMode = value;
    this.cancelAIRequest();
    this.filterKeyProject();
    this.filterValueProject();
  }

  toggleLabels() {
    if (!this.labelMode) {
      this.chooseLabels();
    } else {
      this.labelMode = null;
      this.leftProjectFilteredImages = null;
      this.rightProjectFilteredImages = null;
      this.filterKeyProject();
      this.filterValueProject();
    }

  }

  toggleGroups() {
    if (!this.groupMode) {
      this.chooseGroups();
    } else {
      this.groupMode = null;
      this.leftProjectFilteredImages = null;
      this.rightProjectFilteredImages = null;
      this.filterKeyProject();
      this.filterValueProject();
    }

  }

  choose2DModel() { }

  baselineModelSubscription: any = new Subject();
  baselineModel: any;
  getBaselineModels() {
    this.baselineModelSubscription = this.backend.get3DModels$(this.assetId).subscribe((result: any) => {
      if (result) {
        this.baselineModel = result;
      }
    });
  }

  leftModel;
  rightModel;
  choose3DModel() {
    this.clear3DModel();
    this.getLeftProjectModels();
    this.getRightProjectModels();
    window.addEventListener('resize', this.handleResize, false)
  }

  getLeftProjectModels() {
    if (this.asset?.baselineProjectId == this.selectedLeftProject?.id) {
      this.leftModel = this.baselineModel;
      this.leftModelLoad(this.baselineModel, true);
    } else {
      this.leftModel = this.selectedLeftProject;
      this.leftModelLoad(this.selectedLeftProject, this.selectedLeftProject.isAligned);
    }
  }


  createLeftViewer(model) {
    const viewerEl: any = this.leftModelPanel.nativeElement;
    viewerEl.width = (window.innerWidth / 2) - 20;
    viewerEl.height = window.innerHeight - 300;
    this.leftViewer = new Viewer(viewerEl, { modelOptions: model.modelOptions || null, sideBySide: true, class: "compare-gui" });
    return this.leftViewer;
  }

  createLeftCesiumViewer(model) {
    const viewerEl: any = this.leftModelPanel.nativeElement;
    viewerEl.width = (window.innerWidth / 2) - 20;
    viewerEl.height = window.innerHeight - 300;
    this.leftViewer = new CesiumViewer(viewerEl, { modelOptions: model.modelOptions || null, sideBySide: true, id: this.leftModelPanel.nativeElement }, this.zone);
    return this.leftViewer;
  }

  createRightViewer(model) {
    const viewerEl: any = this.rightModelPanel.nativeElement
    viewerEl.width = (window.innerWidth / 2) - 20;
    viewerEl.height = window.innerHeight - 300;
    this.rightViewer = new Viewer(viewerEl, { modelOptions: model.modelOptions || null, sideBySide: true, class: "compare-gui" });
    return this.rightViewer;
  }

  createRightCesiumViewer(model) {
    const viewerEl: any = this.rightModelPanel.nativeElement;
    viewerEl.width = (window.innerWidth / 2) - 20;
    viewerEl.height = window.innerHeight - 300;
    this.rightViewer = new CesiumViewer(viewerEl, { modelOptions: model.modelOptions || null, sideBySide: true, id: this.rightModelPanel.nativeElement }, this.zone);
    return this.rightViewer;
  }
  leftViewer;
  rightViewer;
  leftModelLoading: boolean = false;
  rightModelLoading: boolean = false;
  errorLeftMessage: string = "";
  errorRightMessage: string = "";
  leftModelLoad(model, isAligned) {
    if (model.tileStatus == 'success') {
      const _this = this;

      if (model.tilesUrl) {
        const viewer = this.createLeftCesiumViewer(model);
        this.isLoading = false;
        viewer.loadTiles(model);
        this.getLeftLabels('tiles');
        const handler = this.leftViewer.viewer.screenSpaceEventHandler;
        // Handle mouse single  click
        handler.setInputAction(async (movement) => {
          var pickedObject = this.leftViewer.viewer.scene.pick(movement.position);
          if (pickedObject && pickedObject.id) {
            // Clicking on annotations numbers
            const customData = pickedObject.id.customData;
            if (customData) {
              _this.gotoAnnotation(customData, 'left')
            }
          }
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      } else {
        // three js viewer
        this.leftModelLoading = true;
        const viewer = this.createLeftViewer(model);
        const cleanup = () => {
          this.leftModelLoading = false;
        };
        if (isAligned && this.asset?.baseModel === '3d') {
          this.getLeftLabels();
        }
        const loader = viewer.loadGLTF(model, false);
        loader.catch((e) => {
          cleanup();
          this.errorLeftMessage = `Oops! Sorry There was a problem loading the file into the browser. Maybe because of the size of the file or a corrupted file, Please contact info@oceansai.tech with the ID: <${model.id}> for assistance`
        })
        loader.then((obj) => {
          cleanup();
          this.leftViewer.scene.children.filter(o => o.name == "annotations")
            .map((item) => {
              return item.visible = true
            })

        });
        let mousedownTime;
        _this.leftViewer.renderer.domElement.addEventListener('pointerdown', onDownClick, true)
        function onDownClick(event) {
          mousedownTime = new Date().getTime();
        };

        _this.leftViewer.renderer.domElement.addEventListener('pointerup', onClick, true)
        function onClick(event) {
          const mouseupTime = new Date().getTime(),
            timeDifference = mouseupTime - mousedownTime;
          if (timeDifference > 1000) {
            //  deep press model event fired
            return;
          }
          _this.leftViewer.raycaster.setFromCamera(
            {
              x: ((event.offsetX) / _this.leftViewer.renderer.domElement.clientWidth) * 2 - 1,
              y: -((event.offsetY) / _this.leftViewer.renderer.domElement.clientHeight) * 2 + 1,

            },
            _this.leftViewer.camera
          )
          if (event.which == 1) {
            const intersects = _this.leftViewer.raycaster.intersectObjects(_this.annotationLeftMarkers, true)
            if (intersects[0] && intersects[0].object.userData) {
              _this.gotoAnnotation(intersects[0].object.userData, 'left')

            }

          }
        }
      }
    }
  }

  cleanLeftModel() {
    if (this.leftViewer) {
      this.leftViewer.remove()
    }
    if (this.leftModelPanel) {
      this.leftModelPanel.nativeElement.innerHTML = '';
    }
    const el: HTMLElement = document.querySelector('.gui-wrap');
    if (el) {
      el.hidden = true;
    }

  }

  cleanRightModel() {
    if (this.rightViewer) {
      this.rightViewer.remove()
    }
    if (this.rightModelPanel) {
      this.rightModelPanel.nativeElement.innerHTML = '';
    }
    const el: HTMLElement = document.querySelector('.gui-wrap');
    if (el) {
      el.hidden = true;
    }
  }
  rightModelLoad(model, isAligned) {
    if (model.tileStatus == 'success') {
      const _this = this;
      if (model.tilesUrl) {
        const viewer = this.createRightCesiumViewer(model);
        this.isLoading = false;
        viewer.loadTiles(model);
        if (isAligned && this.asset?.baseModel === '3d') {
          this.getRightLabels('tiles');
        }
        const handler = this.rightViewer.viewer.screenSpaceEventHandler;
        // Handle mouse single  click
        handler.setInputAction(async (movement) => {
          var pickedObject = this.rightViewer.viewer.scene.pick(movement.position);
          if (pickedObject && pickedObject.id) {
            // Clicking on annotations numbers
            const customData = pickedObject.id.customData;
            if (customData) {
              _this.gotoAnnotation(customData, 'right')
            }
          }
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      }
      else {
        this.rightModelLoading = true;
        const viewer = this.createRightViewer(model);
        const cleanup = () => {
          this.rightModelLoading = false;
        };

        if (isAligned && this.asset?.baseModel === '3d') {
          this.getRightLabels();
        }
        const loader = viewer.loadGLTF(model, false);
        loader.catch((e) => {
          cleanup();
          this.errorRightMessage = `Oops! Sorry There was a problem loading the file into the browser. Maybe because of the size of the file or a corrupted file, Please contact info@oceansai.tech with the ID: <${model.id}> for assistance`
        })
        loader.then((obj) => {
          cleanup();
          this.rightViewer.scene.children.filter(o => o.name == "annotations")
            .map((item) => {
              return item.visible = true
            })

        });
        let mousedownTime;
        _this.rightViewer.renderer.domElement.addEventListener('pointerdown', onDownClick, true)
        function onDownClick(event) {
          mousedownTime = new Date().getTime();
        };

        _this.rightViewer.renderer.domElement.addEventListener('pointerup', onClick, true)
        function onClick(event) {
          const mouseupTime = new Date().getTime(),
            timeDifference = mouseupTime - mousedownTime;
          if (timeDifference > 1000) {
            //  deep press model event fired
            return;
          }
          _this.rightViewer.raycaster.setFromCamera(
            {
              x: ((event.offsetX) / _this.rightViewer.renderer.domElement.clientWidth) * 2 - 1,
              y: -((event.offsetY) / _this.rightViewer.renderer.domElement.clientHeight) * 2 + 1,

            },
            _this.rightViewer.camera
          )
          if (event.which == 1) {
            const intersects = _this.rightViewer.raycaster.intersectObjects(_this.annotationRightMarkers, true)
            if (intersects[0] && intersects[0].object.userData) {
              _this.gotoAnnotation(intersects[0].object.userData, 'right')

            }
          }
        }
      }
    }
  }


  leftProjectMedias = [];
  rightProjectMedias = [];
  gotoAnnotation(a, type) {
    const _this = this;
    /*  new TWEEN.Tween(type == "left" ? _this.leftViewer.camera.position : _this.rightViewer.camera.position)
        .to(
          {
            x: a.camPos.x,
            y: a.camPos.y,
            z: a.camPos.z,
          },
          500
        )
        .easing(TWEEN.Easing.Cubic.Out)
        .start()
        */
    type == "left" ?
      this.leftProjectMedias = this.leftLinkImages[a.id] :
      this.rightProjectMedias = this.rightLinkImages[a.id]

    type == "left" ? _this.leftLabels.forEach(node => {
      if (node.id === a.id) {
        node.show = true;
      } else {
        node.show = false;
      }
    }) :
      _this.rightLabels.forEach(node => {
        if (node.id === a.id) {
          node.show = true;
        } else {
          node.show = false;
        }
      });

  }


  getRightProjectModels() {
    if (this.asset?.baselineProjectId == this.selectedRightProject?.id) {
      this.rightModel = this.baselineModel;
      this.rightModelLoad(this.baselineModel, true);
    } else {
      this.rightModel = this.selectedRightProject;
      this.rightModelLoad(this.selectedRightProject, this.selectedRightProject?.isAligned);
    }
  }

  annotationLeftMarkers = [];
  leftLabels = [];
  leftLabelSubscription: any = new Subject();
  isLeftMenuCollpase = true;
  isRightMenuCollpase = true;
  getLeftLabels(viewerType = 'model') {
    const _this = this;
    this.leftLabelSubscription.unsubscribe();
    this.leftLabelSubscription = this.backend.get3DModelLabels$(this.baselineModel?.id).subscribe((result: any) => {
      this.annotationLeftMarkers = [];
      this.leftLabels = result.label || [];

      if (viewerType === 'tiles') {
        this.leftViewer.viewer.entities.removeAll();
        this.leftLabels.forEach(async (el, index) => {
          const canvas = await this.generalService.getCanvasLabel(index);
          // The mouse click hit an object
          const position = Cesium.Cartesian3.fromElements(
            el.position.x,
            el.position.y,
            el.position.z
          );
          this.leftViewer.viewer.entities.add({
            position: position,
            billboard: {
              image: canvas,
              scale: this.leftViewer.state.label,
              verticalOrigin: Cesium.VerticalOrigin.CENTER,
              clampToGround: true,
              translucencyByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e6, 0.0),
              disableDepthTestDistance: Number.POSITIVE_INFINITY
            },
            customData: { number: index, ...el },

          });

        })
      }
      else {
        this.leftLabels.forEach(async (el, index) => {
          const canvas = await this.generalService.getCanvasLabel(index)
          var texture = new THREE.Texture(canvas);
          texture.needsUpdate = true;
          const annotationSpriteMaterial = new THREE.SpriteMaterial({
            map: texture,
            // useScreenCoordinates: false,
            // transparent: true,
            depthTest: false,
            depthWrite: false,
            sizeAttenuation: false,
          })
          const annotationSprite = new THREE.Sprite(annotationSpriteMaterial)
          annotationSprite.scale.set(this.leftViewer.state.label, this.leftViewer.state.label, this.leftViewer.state.label)
          annotationSprite.position.copy(el.lookAt)
          annotationSprite.userData = { number: index, ...el };
          annotationSprite.name = "annotations";
          annotationSprite.visible = !this.leftModelLoading;
          this.leftViewer.scene.add(annotationSprite);
          this.annotationLeftMarkers.push(annotationSprite)
        });
      }

      this.getLinkedImages("left", this.baselineModel.id, this.selectedLeftProject.id, viewerType)
    });
  }

  rightLabelSubscription: any = new Subject();
  annotationRightMarkers = [];
  rightLabels = [];
  getRightLabels(viewerType = 'model') {
    this.rightLabelSubscription.unsubscribe();
    this.rightLabelSubscription = this.backend.get3DModelLabels$(this.baselineModel?.id).subscribe((result: any) => {
      this.annotationRightMarkers = [];
      this.rightLabels = result.label || [];
      if (viewerType === 'tiles') {
        this.rightViewer.viewer.entities.removeAll();
        this.rightLabels.forEach(async (el, index) => {
          const canvas = await this.generalService.getCanvasLabel(index);
          // The mouse click hit an object
          const position = Cesium.Cartesian3.fromElements(
            el.position.x,
            el.position.y,
            el.position.z
          );
          this.rightViewer.viewer.entities.add({
            position: position,
            billboard: {
              image: canvas,
              scale: this.rightViewer.state.label,
              verticalOrigin: Cesium.VerticalOrigin.CENTER,
              clampToGround: true,
              translucencyByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e6, 0.0),
              disableDepthTestDistance: Number.POSITIVE_INFINITY
            },
            customData: { number: index, ...el },

          });

        })
      }
      else {
        this.rightLabels.forEach(async (el, index) => {
          const canvas = await this.generalService.getCanvasLabel(index)
          var texture = new THREE.Texture(canvas);
          texture.needsUpdate = true;
          const annotationSpriteMaterial = new THREE.SpriteMaterial({
            map: texture,
            // useScreenCoordinates: false,
            // transparent: true,
            depthTest: false,
            depthWrite: false,
            sizeAttenuation: false,
          })
          const annotationSprite = new THREE.Sprite(annotationSpriteMaterial)
          annotationSprite.scale.set(this.rightViewer.state.label, this.rightViewer.state.label, this.rightViewer.state.label)
          annotationSprite.position.copy(el.lookAt)
          annotationSprite.userData = { number: index, ...el };
          annotationSprite.name = "annotations";
          annotationSprite.visible = !this.rightModelLoading;
          this.rightViewer.scene.add(annotationSprite);
          this.annotationRightMarkers.push(annotationSprite)
        });
      }
      this.getLinkedImages("right", this.baselineModel.id, this.selectedRightProject.id, viewerType)
    });
  }

  leftLinkImages = [];
  rightLinkImages = [];
  getLinkedImages(type, modelId, projectId, viewerType) {
    this.backend.getLinkedImages(this.assetId, modelId, projectId).subscribe((result) => {
      if (!result) { return }
      let keys = Object.keys(result);
      const _this = this;
      keys.forEach(element => {
        const index = type == "left" ? this.leftLabels.findIndex(o => o.id == element) :
          this.rightLabels.findIndex(o => o.id == element);
        if (index != -1) {
          if (type == "left" && !_this.leftLinkImages[element]) {
            _this.leftLinkImages[element] = [];
          }
          if (type == "right" && !_this.rightLinkImages[element]) {
            _this.rightLinkImages[element] = [];
          }
          result[element]['images']?.forEach(image => {
            if (image &&
              type == "left" ?
              !this.leftLinkImages[element].find(o => o.id == image)
              :
              !this.rightLinkImages[element].find(o => o.id == image)
            ) {
              this.backend.getImageWithAnnotations(image).pipe().toPromise().then((image: any) => {
                if (image.deleted != true) {
                  type == "left" ? _this.leftLinkImages[element].push(image) :
                    _this.rightLinkImages[element].push(image)
                  _this.updateModelColors(type, element, viewerType);
                }
              });
            }
          })
          result[element]['videos']?.forEach(videoId => {
            if (videoId &&
              type == "left" ?
              !this.leftLinkImages[element].find(o => o.id == videoId)
              :
              !this.rightLinkImages[element].find(o => o.id == videoId)
            ) {
              this.backend.getVideo$<any>(videoId).subscribe((video: any) => {
                if (video.deleted != true) {
                  type == "left" ? _this.leftLinkImages[element].push(video) :
                    _this.rightLinkImages[element].push(video)
                }
              });
            }
          })
        }
      });
    });
  }

  leftImageDialogRef;
  rightImageDialogRef;
  openModal(data, type) {
    if (data.type != "videos") {
      if (type == 'left') {
        if (this.leftImageDialogRef)
          this.leftImageDialogRef.close()
        this.leftImageDialogRef = this.dialog.open(ImageModalComponent, {
          data: {
            ...data,
            parentEl: type
          },
          disableClose: true,
          hasBackdrop: false,
          width: `${this.leftModelPanel.nativeElement.offsetWidth}px`,
          height: `${this.leftModelPanel.nativeElement.offsetHeight || (window.innerHeight - 300)}px`
        });
      } else {
        if (this.rightImageDialogRef)
          this.rightImageDialogRef.close()
        this.rightImageDialogRef = this.dialog.open(ImageModalComponent, {
          data: {
            ...data,
            parentEl: type
          },
          disableClose: true,
          hasBackdrop: false,
          width: `${this.rightModelPanel.nativeElement.offsetWidth}px`,
          height: `${this.rightModelPanel.nativeElement.offsetHeight || (window.innerHeight - 300)}px`

        });
      }
    } else {
      this.dialog.open(VideoModalComponent, {
        data: {
          ...data
        },
        width: '100%',
        height: '100%',
        panelClass: 'video-dialog'
      });
    }

  }

  handleResize = () => {
    const modelWidth = (window.innerWidth / 2) - 20;
    const modelHeight = window.innerHeight - 300;
    if (this.leftViewer) {
      this.leftViewer.resize(modelHeight, modelWidth)
    }
    if (this.rightViewer) {
      this.rightViewer.resize(modelHeight, modelWidth)
    }
  }

  clear3DModel() {
    this.rightLabels = [];
    this.leftLabels = [];
    this.leftProjectMedias = [];
    this.rightProjectMedias = [];
    this.leftLinkImages = [];
    this.rightLinkImages = [];
    this.cleanLeftModel();
    this.cleanRightModel();
    this.errorLeftMessage = "";
    this.errorRightMessage = "";
    window.removeEventListener('resize', this.handleResize, false)

  }

  toggleLeftMenu() {
    this.isLeftMenuCollpase = !this.isLeftMenuCollpase
  }
  toggleRightMenu() {
    this.isRightMenuCollpase = !this.isRightMenuCollpase
  }

  async updateTilesAnnotationColor(id, type) {

    const entitiesArray = type == 'left' ? this.leftViewer.viewer.entities.values : this.rightViewer.viewer.entities.values;
    const sprite = entitiesArray.find(o => o.customData.id == id)
    if (sprite && type === 'left' ? this.leftLinkImages[id] : this.rightLinkImages[id]) {
      const images =
        type === 'left' ? this.leftLinkImages[id]?.filter(o => o.type != 'videos') : this.rightLinkImages[id]?.filter(o => o.type != 'videos');

      const maxArr = images.map(x => x.tags?.filter(x => x.sensitive)?.map(x => x.sensitive))
      const max = Math.max(...[].concat(...maxArr))
      const tags = images.map(x => x.tags?.find(e => e.sensitive == max)).filter(x => x !== undefined)
      if (tags.length) {
        const findTag =
          type === 'left' ? this.sensitiveProjectLeftTags.find(x => x.tag == tags[0]?.tag && x.status == 'active') :
            this.sensitiveProjectRightTags.find(x => x.tag == tags[0]?.tag && x.status == 'active')
        if (findTag) {
          const color = findTag.levels?.find(o => o.level == max)?.color;
          this.updateTiles(sprite, color)
        }
      } else {
        this.updateTiles(sprite, '#22222')
      }
    }
  }


  async updateModelAnnotationColor(id, type) {
    const sprite = type === 'left' ? this.leftViewer.scene.children.find(o => o.name == 'annotations' && o.userData.id == id)
      : this.rightViewer.scene.children.find(o => o.name == 'annotations' && o.userData.id == id)
    if (sprite && type === 'left' ? this.leftLinkImages[id]?.filter(o => o.type != 'videos') : this.rightLinkImages[id]?.filter(o => o.type != 'videos')) {
      const images = type === 'left' ? this.leftLinkImages[id] : this.rightLinkImages[id]
      const maxArr = images.map(x => x.tags?.filter(x => x.sensitive)?.map(x => x.sensitive))
      const max = Math.max(...[].concat(...maxArr))
      const tags = images.map(x => x.tags?.find(e => e.sensitive == max)).filter(x => x !== undefined)
      if (tags.length) {
        const findTag =
          type === 'left' ? this.sensitiveProjectLeftTags.find(x => x.tag == tags[0]?.tag && x.status == 'active') :
            this.sensitiveProjectRightTags.find(x => x.tag == tags[0]?.tag && x.status == 'active')
        if (findTag) {
          const color = findTag.levels?.find(o => o.level == max)?.color;
          this.updateSprite(sprite, color)
        }
      } else {
        this.updateSprite(sprite, '#22222')
      }
    }
  }

  async updateTiles(sprite, color) {
    if (sprite.customData['color'] && sprite.customData['color'] === color) {
      return;
    }
    sprite.customData['color'] = color;
    const canvas = await this.generalService.getCanvasLabel(sprite.customData.number, color)
    sprite.billboard.image = canvas;
  }

  async updateModelColors(type, id, viewerType) {
    if (viewerType === 'tiles') {
      this.updateTilesAnnotationColor(id, type)

    } else {
      this.updateModelAnnotationColor(id, type)
    }

  }
  async updateSprite(sprite, color) {
    if (sprite.userData['color'] && sprite.userData['color'] === color) {
      return;
    }
    sprite.userData['color'] = color;
    const canvas = await this.generalService.getCanvasLabel(sprite.userData.number, color)
    var texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    sprite.material = new THREE.SpriteMaterial({
      map: texture,
      depthTest: false,
      depthWrite: false,
      sizeAttenuation: false
    })
  }

  checkTopSeverity(id, type) {
    const labelImages = type == 'left' ? this.leftLinkImages[id]?.filter(o => o.type != 'videos') : this.rightLinkImages[id]?.filter(o => o.type != 'videos');
    if (labelImages) {
      const maxArr = labelImages.map(x => x.tags?.filter(x => x.sensitive)?.map(x => x.sensitive))
      const max = Math.max(...[].concat(...maxArr))
      const tags = labelImages.map(x => x.tags?.find(e => e.sensitive == max)).filter(x => x !== undefined)
      if (tags.length) {
        const findTag =
          type === 'left' ? this.sensitiveProjectLeftTags.find(x => x.tag == tags[0]?.tag && x.status == 'active') :
            this.sensitiveProjectRightTags.find(x => x.tag == tags[0]?.tag && x.status == 'active')
        if (findTag) {
          return findTag.levels?.find(o => o.level == max)?.color;
        }
      }
    }
    return "";
  }

  imageSeverity(image, type) {
    const levels = image.tags?.filter(o => o.sensitive).map(o => o.sensitive);
    if (levels && levels.length) {
      const maxLevel = Math.max(...levels);
      const tag = image.tags?.find(element => element.sensitive === maxLevel);
      const findTag = type === 'left' ? this.sensitiveProjectLeftTags.find(x => x.tag == tag.tag && x.status == 'active') :
        this.sensitiveProjectRightTags.find(x => x.tag == tag.tag && x.status == 'active')
      if (findTag)
        return findTag.levels?.find(o => o.level == maxLevel)?.color;
    }
    return "";
  }

  area;
  hoverType;
  hoverChildContextImages(area: TaggedArea, enabled: boolean, type) {
    if (!area.annotation) { return }
    this.hoverType = type;
    this.area = area
    if (enabled) {
      area.annotation.isHover = true;

    } else {
      area.annotation.isHover = false;
    }
  }

  tooltipVisible: boolean = false;
  tooltipTop: string = '0';
  tooltipLeft: string = '0';
  showTooltip(event: any) {
    setTimeout(() => {
      //   this.tooltipTop = event.clientY  - (this.panelData.projects.length * 52) + 'px';
      this.tooltipLeft =
        (this.highValue == this.value && this.highValue == 0) ?
          '10px'
          :
          event.clientX + (this.panelData.context == 1 && this.highValue + 1 === this.groupProjects.length ? -150 :
            this.panelData.context == 0 && this.value == 0 ? 0 : -80) + 'px';
      this.tooltipVisible = true;
    }, 30);

  }

  hideTooltip() {
    this.tooltipVisible = false;
  }

  showProject(project, type) {
    // reset labels and groups filters
    this.isSearchRequestReady = true;
    this.clearCurrentFilter();
    this.filterValueProject();
    this.modelType = '2d';
    this.oldHighValue = this.highValue;
    this.oldValue = this.value;

    if (type === 'left') {
      if (this.panelData.context == 1) {
        this.value = this.highValue;
      }
      this.uiService.activeProjectLeftChange.next(project);
    } else {
      if (this.panelData.context == 0) {
        this.highValue = this.value;
      }
      this.uiService.activeProjectRightChange.next(project);
    }
    this.panelData = {
      projects: [],
      value: '',
      context: ''

    }
  }

  activateLeftImage(idx: number) {
    this.activate(idx, 'left');
    if (this.searchMode !== 'ai' && this.asset.assetType === 'solar') {
      this.matchGPSRecords('left', idx);
    }
  }

  closestLocation(targetLocation, doc) {
    function vectorDistance(dx, dy) {
      return Math.sqrt(dx * dx + dy * dy);
    }
    function locationDistance(location1, location2) {
      var dx = location1?.GpsLatitude - location2?.GpsLatitude,
        dy = location1?.GpsLongitude - location2?.GpsLongitude;
      return vectorDistance(dx, dy);
    }
    return doc.reduce(function (prev, curr) {
      var prevDistance = locationDistance(targetLocation, prev?.metaData),
        currDistance = locationDistance(targetLocation, curr?.metaData);
      return (prevDistance < currDistance) ? prev : curr;
    });
  }
  matchGPSRecords(type: string, idx: number) {
    if (type === 'right') {
      if (this.filterRightImages[idx] &&
        this.filterLeftImages.length) {
        const GpsLatitude = this.filterRightImages[idx]?.doc?.metaData?.GpsLatitude;
        const GpsLongitude = this.filterRightImages[idx]?.doc?.metaData?.GpsLongitude;
        if (GpsLatitude && GpsLongitude) {
          const matchedGPS = this.closestLocation({
            GpsLatitude,
            GpsLongitude
          }, this.filterLeftImages.map(o => (o.doc)
          ))
          if (matchedGPS) {
            const leftIdx = this.filterLeftImages.filter(o => o.doc.metaData).findIndex(o => o.doc?.id === matchedGPS.id);
            if (leftIdx != -1)
              this.activate(leftIdx, 'left')

          }
        }
      }
    } else {
      if (this.filterLeftImages[idx] &&
        this.filterRightImages.length) {
        const GpsLatitude = this.filterLeftImages[idx]?.doc?.metaData?.GpsLatitude;
        const GpsLongitude = this.filterLeftImages[idx]?.doc?.metaData?.GpsLongitude;
        if (GpsLatitude && GpsLongitude) {
          const matchedGPS = this.closestLocation({
            GpsLatitude,
            GpsLongitude
          }, this.filterRightImages.filter(o => o.doc.metaData).map(o => (o.doc)
          ))
          if (matchedGPS) {
            const rightIdx = this.filterRightImages.findIndex(o => o.doc?.id === matchedGPS.id);
            if (rightIdx != -1)
              this.activate(rightIdx, 'right');
            const containerEl = this.elRef.nativeElement.querySelector('.right-scroller');
            const imagesEl = this.elRef.nativeElement.querySelectorAll('.image-items');
            if (imagesEl[rightIdx]) {
              containerEl.scrollLeft = imagesEl[rightIdx].offsetLeft - 400;
            }
          }
        }
      }
    }
  }

  renderToolTip() {
    setTimeout(() => {
      const tags: any = document.getElementsByClassName('ngx-slider-inner-tooltip');
      if (tags) {
        for (var i = 0; i < tags.length; i++) {
          tags[i].innerText = this.groupProjects[i].value?.length || 0;

        }
      }

    }, 2000);
  }


  getDetectionColor(annotation, isBackground, project) {
    const minTemp = annotation.minTemperature;
    const maxTemp = annotation.maxTemperature;
    const projectMinTemp = project.minTemperature;
    const projectMaxTemp = project.maxTemperature;
    const stdDev = project.std_dev;

    if (projectMinTemp && minTemp && maxTemp &&
      projectMaxTemp && stdDev) {
      if (maxTemp >= (projectMaxTemp + stdDev)) {
        return isBackground ? '#FFF' : 'red'; //hotspots
      }
      if (minTemp <= (projectMinTemp - stdDev)) {
        return isBackground ? '#FFF' : 'blue';  //coldspots
      }
    } else {
      if (annotation.temperature && projectMinTemp &&
        projectMaxTemp && stdDev) {
        if (annotation.temperature >= (projectMaxTemp + stdDev)) {
          return isBackground ? '#FFF' : 'red'; //hotspots
        }
        if (annotation.temperature <= (projectMinTemp - stdDev)) {
          return isBackground ? '#FFF' : 'blue';  //coldspots
        }
      }


    }
    return isBackground ? '#2f1818' : 'fff';  //normal
  }

}
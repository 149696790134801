import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { BackendService } from '../services/backend.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { Project } from '../projects/item/project.type';
import { IReportEditorData, ReportEditorDialogComponent } from '../report-editor-dialog/report-editor-dialog.component';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ITemplate } from '../models/template.interface';
import { IInitialsData, InitialsDataModel } from '../models/initials-data.interface';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UiService } from '../services/ui.service';
import { GeneralService } from '../services/general.service';
export interface IReportData {
  name: string;
  id: string;
  projectId: string;
  templateId: string;
  nodes: any[];
  initialsData?: IInitialsData;
  projectInitials?: any[];
  freeze: boolean;
  userUid: string;
  templates: any[];
  //  reports: any[],

  selectedInital?: any,
  isProjectScope?: boolean,
  columnLayout?: string,
  imageBorder?: boolean
}

@Component({
  selector: 'app-report-add-dialog',
  templateUrl: './report-add-dialog.component.html',
  styleUrls: ['./report-add-dialog.component.scss'],
})
export class ReportAddDialogComponent implements OnInit, OnDestroy {
  public projects$: Observable<Project[]>;
  public nodes: any[];
  public templates$: Observable<ITemplate[]>;
  public templates = [];
  public projectTemplate = [];
  public allTemplate = [];
  public selectedTabIdx = 0;
  public isUpdateInitialsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public projects = [];
  public projectUsers = [];
  @ViewChild('uploadTmplBtn') uploadTemplateBtn: ElementRef;

  public projectInitials: IInitialsData[] = [];
  selectedInitialsObjIdx: string;
  projectInitialSubscribe;
  public form = new FormGroup({
    reportName: new FormControl('', [Validators.required]),
    projectId: new FormControl(null, Validators.required),
    templateId: new FormControl(null, Validators.required),
  });
  private onDestroy$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IReportData | null,
    public backendService: BackendService,
    public dialogRef: MatDialogRef<ReportAddDialogComponent>,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private uiService: UiService,
    private generalService: GeneralService
  ) {
   // this.projects$ = this.backendService.getProjects();

    if (data !== null) {
      this.form.get('reportName').setValue(data.name);
      this.form.get('templateId').setValue(data.templateId);
    }

   
      this.projects = this.uiService.allProjects;
      if (this.projects && this.projects.length) {
        if (!this.form.value.projectId) {
          if (this.uiService.selectedProjectId) {
            this.form.get('projectId').setValue(this.uiService.selectedProjectId);
            const projectPeople = Object.keys(this.projects.find(o => o.id == this.uiService.selectedProjectId).people);
            this.backendService.getProjectPeople(projectPeople).then(users => {
              this.projectUsers = users;
            })

            this.projectInitialSubscribe = this.backendService.valueChangesProjectInitials(this.uiService.selectedProjectId).subscribe(response => {
              this.projectInitials = response;
              if (this.data.selectedInital) {
                let find = this.projectInitials.find(o => o.id == this.data.selectedInital.id);
                if (find) {
                  if (this.data.freeze) {
                    this.revisedProjectInitials(find);
                  }
                  this.selectedInitialsObjIdx = find.id;
                }
              }

            });

          }
        }
      }

    if (this.data.templates && this.data.templates.length) {
      this.templates = this.data.templates;
     /* if (data.templateId) {
        let userTemplate = this.data.templates.find(o => o.id != data.templateId)
        if (!userTemplate && data.templateId != 'default-template') {
          this.backendService.getTemplateById(data.templateId).subscribe((querySnapshot) => {
            let find = this.projectUsers.filter(o => o).find(o => o.uid == querySnapshot.data().createdBy)
            const title = find ? querySnapshot.data().title + '(' + find.createdByUser + ')' : querySnapshot.data().title;
            if (querySnapshot.data().createdBy != this.backendService.currentUser.uid) {
              this.templates.push(Object.assign({}, { id: querySnapshot.id }, querySnapshot.data(), { title: title }))
              this.setTemplateId();
            }
          });

        }
      }*/
      this.setTemplateId();
    }

    // update template in background
    this.backendService.getTemplates(this.uiService.selectedProjectId).subscribe(template => {
      this.templates = template;   
      this.setTemplateId();
    })

   
  }

  revisedProjectInitials(data) {
    const projectId = this.form.get('projectId').value;
    return this.backendService.revisedProjectInitials$(data, projectId)
  }

  setTemplateId() {
    if (this.data.templateId) {
      let find = this.templates.find(o => o.id == this.data.templateId);
      if (find) {
        this.form.controls["templateId"].setValue(find.id)
      }
    } else {
      this.form.controls["templateId"].setValue('default-template')
    }
  }


  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: '0' });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.projectInitialSubscribe.unsubscribe();
  }

  openEditor(): void {
    if (this.form.invalid || !this.selectedInitialsObjIdx) {
      this.snackBar.open('All fields are required', 'Close', {
        duration: 2000,
        panelClass: 'snack-bar'
      });
      return;
    }
    this.dialogRef.close();
    const data: IReportEditorData = {
      name: this.form.get('reportName').value,
      projectId: this.form.value.projectId,
      templateId: this.form.value.templateId,
      nodes: this.data === null ? [] : this.data.nodes,
      id: this.data === null ? null : this.data.id,
      selectedTabIdx: this.selectedTabIdx,
      initialsData: this.projectInitials.find(o => o.id == this.selectedInitialsObjIdx),
      freeze: this.data.freeze,
      userUid: this.data.userUid,
      projects: this.projects,
      isProjectScope: this.data.isProjectScope,
      projectUsers: this.projectUsers,
      columnLayout: this.data.columnLayout,
      imageBorder: this.data.imageBorder
    };


    this.dialog.open(ReportEditorDialogComponent, {
      minWidth: '75vw',
      height: '100vh',
      data
    });
    return;
  }

  public selectedTabChange({ index }: MatTabChangeEvent): void {
    this.selectedTabIdx = index;
  }

  public addNewTemplate(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.uploadTemplateBtn) {
      const e = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      });
      this.uploadTemplateBtn.nativeElement.dispatchEvent(e);
    }
  }

  public onSuccessAddTemplate(): void { }





  addNewInitials() {
    this.selectedTabIdx = 1;
  }


  getUserName(initials) {
    if (initials.createdBy != this.backendService.currentUser.uid) {
      let find = this.projectUsers.filter(o => o).find(o => o.uid == initials.createdBy)
      if (find) {
        return `(${(find.displayName || find.email)})`
      }
    }
  }

  removeInitials(id) {
    if (this.selectedInitialsObjIdx == id) {
      this.selectedInitialsObjIdx = null;
    }
  }
}
// import { Component, OnInit } from '@angular/core';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { BackendService } from '../../services/backend.service';
import { UiService } from '../../services/ui.service';
import { ToastrService } from 'ngx-toastr';

const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};

@Component({
  selector: 'app-add-campaign',
  templateUrl: './add-campaign.component.html',
  styleUrls: ['./add-campaign.component.scss']
})
export class AddCampaignDialogeComponent implements OnInit, OnDestroy {
  public name: string;
  public industry: string;
  public inspectionDate: string;
  public assetProjects = [];
  isMembers: boolean = false;
  isFeatures: boolean = false;
  isFolders: boolean = false;
  members = [];
  companyId: any;
  contacts = [];
  assets = [];
  companyAssets = [];
  selectedAssets :any= [];
  startDate: any;
  endDate: any;
  from: any = "";
  companies = [];
  public createBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Create',
    raised: true,
    spinnerSize: 24,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    mode: 'indeterminate',
    customClass: 'text-uppercase'
  };

  public get projects() {
    return this.uiService.allProjects;
  }

  constructor(
    public backend: BackendService,
    public uiService: UiService,
    public dialogRef: MatDialogRef<AddCampaignDialogeComponent>,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.assets = this.uiService.allAssets;
    this.companies = [...new Map(this.assets.map(item =>
      [item.companyName, item])).values()];
    this.groupWithContacts(this.assets);
    this.assetProjects = this.projects.filter(o => o.assetId == this.data.assetId).sort((a, b) => new Date(a?.createdDate.toDate()).getTime() - new Date(b?.createdDate.toDate()).getTime());

  }

  ngOnInit(): void {

  }

  ngOnDestroy() {

  }

  close(): void {
    this.name = '';
    this.dialogRef.close();
  }

  membersChoosen(event) {
    this.isMembers = event.checked;
  }

  filterContactName(value: string) {
    const filterValue = value.toLowerCase();
    this.groupWithContacts(this.assets.filter(o => o.contactName.toLowerCase().includes(filterValue)));
  }

  groupWithContacts(assets) {
    this.contacts = [];
    const groupContacts = groupBy(assets, 'email');
    let keys = Object.keys(groupContacts)
    keys.forEach(key => {
      this.contacts.push(groupContacts[key][0])
    });
  }

  filterAssets(e: any) {
    this.companyAssets = this.assets.filter(x => x.companyId === e.value)
  }

  handleCreate() {
    if (new Date(this.endDate).getTime() <= new Date(this.startDate).getTime()) {
      this.toastr.warning("End date must not be greater than start date.");
    } else {

      this.backend.createCampaign(this.name,this.companyId, this.selectedAssets,this.startDate,this.endDate).subscribe((res) => {
        this.toastr.success("Campaign created successfully.");
        this.backend.addUsersToCampaign(res.id,this.selectedAssets).subscribe();
        this.dialogRef.close()
      })
    }
  }


}

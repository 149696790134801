<div class="grid-y h-100">
  <headings class="headings py-2">

    <button [class.isActive]="uiService.isAssetPanelExpand" (click)="openAsset()"
      matTooltip="{{uiService.asset?.companyName}} - {{uiService.asset?.assetName}}" class="header-flat-button ml-2"
      mat-button type="button">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.6437 10.5107L15.3125 8.51167V2.78395C15.3105 2.66378 15.2741 2.54672 15.2076 2.44672C15.1411 2.34672 15.0474 2.268 14.9375 2.21995L10.25 0.0579514C10.1677 0.0197743 10.0781 0 9.98749 0C9.89684 0 9.80727 0.0197743 9.72499 0.0579514L5.0375 2.21995C4.92901 2.27029 4.8372 2.35077 4.77295 2.45185C4.70869 2.55292 4.6747 2.67035 4.675 2.79021V8.51794L0.362502 10.5107C0.254014 10.5611 0.162201 10.6416 0.0979501 10.7426C0.0336993 10.8437 -0.00029389 10.9611 1.91421e-06 11.081V17.2098C-0.00029389 17.3296 0.0336993 17.4471 0.0979501 17.5482C0.162201 17.6492 0.254014 17.7297 0.362502 17.7801L5.05 19.942C5.13227 19.9802 5.22184 20 5.3125 20C5.40315 20 5.49272 19.9802 5.575 19.942L9.99999 17.8991L14.425 19.942C14.5073 19.9802 14.5968 20 14.6875 20C14.7781 20 14.8677 19.9802 14.95 19.942L19.6375 17.7801C19.746 17.7297 19.8378 17.6492 19.902 17.5482C19.9663 17.4471 20.0003 17.3296 20 17.2098V11.081C20.0008 10.9618 19.9678 10.8449 19.9047 10.7439C19.8416 10.6428 19.7511 10.562 19.6437 10.5107ZM17.8875 11.081L14.6937 12.5411L11.5 11.081L14.6937 9.60833L17.8875 11.081ZM9.99999 1.31128L13.1937 2.78395L9.99999 4.25661L6.8125 2.78395L9.99999 1.31128ZM5.3125 9.60207L8.50624 11.0747L5.3125 12.5411L2.11875 11.081L5.3125 9.60207ZM9.37499 16.8025L5.3125 18.6824L1.25 16.8025V12.0586L5.05 13.7945C5.13057 13.8308 5.21791 13.8496 5.30625 13.8496C5.39459 13.8496 5.48192 13.8308 5.5625 13.7945L9.36249 12.0398L9.37499 16.8025ZM5.9375 8.51167V3.76781L9.73749 5.52247C9.81977 5.56065 9.90934 5.58043 9.99999 5.58043C10.0906 5.58043 10.1802 5.56065 10.2625 5.52247L14.0625 3.76781V8.51794L9.99999 10.3979L5.9375 8.51167ZM18.7562 16.8087L14.6937 18.6887L10.625 16.8087V12.0523L14.4312 13.7945C14.511 13.8299 14.5972 13.8482 14.6844 13.8482C14.7716 13.8482 14.8578 13.8299 14.9375 13.7945L18.7375 12.0398L18.7562 16.8087Z"
          fill="white" />
      </svg>
      <!-- <mat-icon *ngIf="uiService.isAssetPanelExpand"> close</mat-icon>
   -->
    </button>


    <mat-form-field class="header-project-select mr-1 ml-1" appearance="fill">
      <mat-select [(value)]="uiService.selectedProjectId" (selectionChange)="changeProject($event.value)">
        <mat-option [value]="project.id" *ngFor="let project of uiService.assetProjects">{{ project.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <button (click)="scope()" matTooltip="Project scope" class="header-flat-button mr-1" mat-button type="button"><svg
        width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 0V2.07C7.24011 2.29352 5.60439 3.09553 4.34996 4.34996C3.09553 5.60439 2.29352 7.24011 2.07 9H0V11H2.07C2.29352 12.7599 3.09553 14.3956 4.34996 15.65C5.60439 16.9045 7.24011 17.7065 9 17.93V20H11V17.93C12.7599 17.7065 14.3956 16.9045 15.65 15.65C16.9045 14.3956 17.7065 12.7599 17.93 11H20V9H17.93C17.7065 7.24011 16.9045 5.60439 15.65 4.34996C14.3956 3.09553 12.7599 2.29352 11 2.07V0H9ZM9 4.08V6H11V4.09C13.5 4.5 15.5 6.5 15.92 9H14V11H15.91C15.5 13.5 13.5 15.5 11 15.92V14H9V15.91C6.5 15.5 4.5 13.5 4.08 11H6V9H4.09C4.5 6.5 6.5 4.5 9 4.08ZM10 9C9.73478 9 9.48043 9.10536 9.29289 9.29289C9.10536 9.48043 9 9.73478 9 10C9 10.2652 9.10536 10.5196 9.29289 10.7071C9.48043 10.8946 9.73478 11 10 11C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10C11 9.73478 10.8946 9.48043 10.7071 9.29289C10.5196 9.10536 10.2652 9 10 9Z"
          fill="white" />
      </svg>
    </button>
    <!-- ------------- 3D Generate icon------- -->
    <button mat-button class="header-flat-button mr-2" type="button" (click)="generateModel()"
      [class.isActive]="project?.tileStatus == success" [class.isDisabled]="getReadOnlyForCurrentUser() ||
       uiService.asset?.baselineProjectId ==  project?.id" matTooltip="{{getToolTipText()}}">
      <mat-spinner *ngIf="project?.tileStatus ===  process || project?.tileStatus ===  queued" [diameter]="20">
      </mat-spinner>
      <mat-icon>3d_rotation </mat-icon>
    </button>
    <!-- ------------- View 2D icon------- -->
    <button *ngIf="uiService.asset?.baseModel === '2d'" mat-button class="header-flat-button mr-2" type="button"
      (click)="open2DModel()" matTooltip="View 2D model">
      <span class="material-symbols-outlined">
        2D
      </span>
    </button>
    <button mat-button class="header-flat-button mr-2" type="button" (click)="openTimeline()"
      matTooltip='{{getToolTip()}}' [class.isActive]="!isMinProjects()">
      <!--<mat-icon></mat-icon>--><img src="assets/timeline.png" />

    </button>


    <!-- <div class="d-flex summary mx-auto">
      <span>
        <mat-icon class="loader" *ngIf="uiService.projectInfo.loading">
          <mat-spinner [diameter]="20"></mat-spinner>
        </mat-icon>
        <button type="button" matTooltip="Feature count"><img src="assets/icons/annotations.svg" class="mr-1">
          <b>{{uiService.projectInfo.annotations}}</b></button>

        <button type="button" matTooltip="Images"><img src="assets/icons/images.svg" class="mr-1">
          <b>{{uiService.projectInfo.images}}</b></button>

        <button type="button" matTooltip="Videos"><img src="assets/icons/video.svg" class="mr-1">
          <b>{{uiService.projectInfo.videos}}</b></button>

        <button type="button" matTooltip="Images with Features"><img src="assets/icons/annotated-images.svg"
            class="mr-1"> <b>{{uiService.projectInfo.annotatedImages}}</b></button>

        <button type="button" matTooltip="Images with no Features"><img src="assets/icons/unannotated-images.svg"
            class="mr-1"><b>{{uiService.projectInfo.unAnnotatedImages}}</b></button>

      </span>

    </div> -->

    <mat-form-field style="max-width: 160px !important" class="ml-2 mr-1 header-project-select" appearance="fill"
      *ngIf="selectedImages && selectedImages.length">
      <mat-select placeholder="Select Group" [(value)]="selectedGroupId" (click)="$event.stopImmediatePropagation()"
        (click)="$event.stopImmediatePropagation()" placeholder="Select Group"
        (selectionChange)="setSelectedGroupName($event)">
        <mat-option *ngFor="let group of groups$ | async" [value]="group.id"
          (click)="$event.stopImmediatePropagation();" [disabled]="getReadOnlyForCurrentUser()">
          {{ group.groupName }}
        </mat-option>
      </mat-select>

    </mat-form-field>

    <div class="d-flex side-right ml-auto">
      <button class="header-flat-button w-auto"
        *ngIf='uiService.project?.projectType != "thermal" && (appInferenceEngineMode ==1 || appInferenceEngineMode == 3) && backendService.DOMAIN_CONTROL?.features?.aiEngine'
        mat-button type="button" (click)="openAIEngineModal()"
        [disabled]="getReadOnlyForCurrentUser() || engineStatus() || isLoading">
        <mat-spinner style="display: inline-block;" *ngIf="engineStatus() ||  isLoading" [diameter]="18">
        </mat-spinner>
        <span>Start AI Detection</span>
      </button>
      <button class="header-flat-button w-auto"
        *ngIf='uiService.project?.projectType === "thermal" && (appInferenceEngineMode ==1 || appInferenceEngineMode == 3) && backendService.DOMAIN_CONTROL?.features?.aiEngine'
        mat-button type="button" (click)="startHotSpotDetection()"
        [disabled]="getReadOnlyForCurrentUser() ||
     hotspotLoading || (this.project?.anomalyDetectionStatus  ===  process || this.project?.anomalyDetectionStatus  ===  queued )">
        <mat-spinner style="display: inline-block;"
          *ngIf="hotspotLoading || (this.project?.anomalyDetectionStatus  ===  process || this.project?.anomalyDetectionStatus  ===  queued )"
          [diameter]="18">
        </mat-spinner>
        <div style="font-size: 10px;">
          <span>Hotspot Detection</span>
          <br>
          <span *ngIf="uiService.project?.minTemperature">
            Normal range: {{ uiService.project.minTemperature}} °C -
            {{ uiService.project.maxTemperature}} °C
          </span>
        </div>

      </button>

      <button matTooltip="Delete" *ngIf="selectedImages && selectedImages.length" mat-button
        class="header-flat-button mr-1" type="button" (click)="deleteFolderContent()"
        [disabled]="getReadOnlyForCurrentUser()">
        <mat-icon svgIcon="trash"></mat-icon>
        <!-- <span>Delete</span> -->
      </button>

      <app-upload *ngIf="projectId && idToken && activeFolder && nodes.length !== 0" [projectId]="projectId"
        [folderPath]="activeFolderPath || '' " [assetId]="assetId" [idToken]="idToken"
        (errorEvent)="errorImages = $event;">
        <button mat-button matTooltip="Upload: For better processing, you can upload max 1500 images in each folders"
          class="UppyModalOpenerBtn header-flat-button mr-1" type="button" (click)="openUppy()"
          [disabled]="getReadOnlyForCurrentUser() || isCurrentFileUploading()">
          <mat-icon *ngIf="!isUploadImages" svgIcon="upload"></mat-icon>
          <mat-icon class="upload" *ngIf="isUploadImages">
            <mat-spinner [diameter]="18"></mat-spinner>
          </mat-icon>
          <!-- <span>Upload</span> -->

        </button>
      </app-upload>
      <span class="small-message"
        *ngIf="isUploadImages && uiService.activeFolder && uiService.activeFolder.data.id != uiService.currentUploadingFolder.id">
        Uploading to "{{uiService.currentUploadingFolder.breadcrumb.name}}". Please wait!
      </span>

      <button mat-button matTooltip="Invite members" class="header-flat-button mr-2" type="button"
        (click)="openInviteDialog()" [disabled]="getReadOnlyForCurrentUser()">
        <mat-icon svgIcon="add_member"></mat-icon>
        <!-- <span>Invite members</span> -->
      </button>

    </div>
  </headings>


  <div class="cell auto h-100">
    <div class="grid-container full h-100">
      <div class="no-folder-container" *ngIf="!(backendService?.getFoldersLoading$ | async) && nodes.length === 0">
        <img src="../../assets/empty-folder.svg" alt="ADD FOLDER">
        <button (click)="handleAddFolder(); $event.stopImmediatePropagation()" mat-raised-button color="primary"
          class="mb-0 mt-5" style="text-transform: uppercase;" [disabled]="getReadOnlyForCurrentUser()">Add a folder to
          start the project</button>
      </div>
      <div class="grid-x h-100"
        *ngIf="activeFolder && !(backendService?.getFoldersLoading$ | async) && nodes.length !== 0">
        <div class="cell" style="flex: auto; position: relative;">

          <!-- show images -->
          <ng-container *ngIf="selectedTag !== 'relations'">
            <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
              [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu"
              *ngIf="!getReadOnlyForCurrentUser()">
            </div>
            <div class="w-100  d-flex border-bottom align-items-center">

              <div class="d-flex w-100">
                <div (click)="choose('pictures')" [class.active]="option =='pictures'" class="options my-3">
                  <mat-icon> image</mat-icon>
                  <span>
                    Pictures({{this.uiService.images.length}})
                  </span>
                </div>
                <div (click)="choose('videos')" [class.active]="option =='videos'" class="options my-3">
                  <mat-icon> videocam</mat-icon>
                  <span>
                    Videos({{this.uiService.videos.length}})
                  </span>
                </div>

                <span class="d-flex log-prompt" *ngIf="uiService.isLogProcessing$ | async">
                  <mat-spinner class="spinner" [diameter]="15"></mat-spinner>
                  Logs fetching...
                </span>
                <!-- <button (click)="openFileChoose();" *ngIf="uiService.project.inspectionType ==='forssea-robotics'"
                  [disabled]="(uiService.isLogProcessing$ | async) || isProcessing$.value" class="d-flex" style=" border: none;
                  background: transparent;padding-top: 18px;">
                  <mat-icon matTooltip="Inspection Log File(.xlsx)"> attach_file</mat-icon>
                  <span *ngIf="isProcessing$ | async">
                    <mat-spinner class="spinner" [diameter]="15"></mat-spinner>
                  </span>
                </button> -->
                <button class=" w-auto" mat-button (click)="openLogPrompt()"
                  matTooltip="Attached log and inspection file"
                  *ngIf="uiService.project.inspectionType ==='forssea-robotics'">
                  <mat-icon svgIcon="upload"></mat-icon>
                </button>
                <button class="ml-2  change-palettte" [disabled]="!uiService?.project?.colorPalette"
                *ngIf="uiService.project?.projectType==='thermal'" [matTooltip]="!isColorImage ? 'Show color palette image' :
              'Show original image'" [class.active]="isColorImage" (click)="changeImageType()">
                <mat-icon>picture_in_picture_alt</mat-icon>
              </button>
              <button
                [disabled]="thermalLoading ||
                (this.project?.thermalColorConversionStatus  ===  process || this.project?.thermalColorConversionStatus  ===  queued )"
                matTooltip="{{viewColorPaletteToolTips()}}" id="palette_btn" class="ml-2"
                *ngIf="project.projectType == 'thermal'" class="plate-btn w-auto" mat-button type="button"
                (click)="openColorPalette()">
                <mat-spinner
                  *ngIf="thermalLoading ||
                  (this.project?.thermalColorConversionStatus  ===  process || this.project?.thermalColorConversionStatus  ===  queued )"
                  [diameter]="20">
                </mat-spinner>
                <span>Color palette </span>

              </button>

              </div>

              <!-- <div *ngIf="option =='pictures'" class="verified-radio mt-2">
                <mat-radio-group
                  *ngIf='(appInferenceEngineMode ==1 || appInferenceEngineMode == 3) && backendService.DOMAIN_CONTROL?.features?.aiEngine'
                  (change)="filteredChekboxImages($event);" [(ngModel)]="verifiedTag" class="m-0">
                  <mat-radio-button value="All">All</mat-radio-button>
                  <mat-radio-button matTooltip="AI detection verified images" value="Verified">Verified
                  </mat-radio-button>
                  <mat-radio-button matTooltip="AI detection unverified images" value="Unverified">Unverified
                  </mat-radio-button>
                </mat-radio-group>
              </div> -->

              <span class="d-flex filter-tag" *ngIf="option =='pictures' && uiService.selectedTag != 'All'">
                {{uiService.selectedTag}}
                <span *ngIf="uiService.sensitiveTag">
                  [{{getSeverity(uiService.sensitiveTag)}}]
                </span>
              </span>
              <button [disabled]="uiService.projectInfo.loading" class="filter-icon mr-2 mt-2" mat-button
                matTooltip="Filter and Report Groups" (click)="openFilterPanel()" *ngIf="option =='pictures'">
                <mat-spinner matTooltip="fetching annotations" *ngIf="uiService.projectInfo.loading"
                  style="display: inline-block;" [diameter]="20"></mat-spinner>
                <mat-icon>filter_alt</mat-icon>

              </button>



            </div>

            <div class="video" *ngIf="option =='videos' && videos.length">
              <div (click)="openVideoModal(video);" class="project-item video-content"
                *ngFor="let video of videos; let i = index;"
                [style.border]="isChecked(video.id) ? '2px solid #2196f3' : 'none'">
                <div class="project-item__contents">
                  <a>
                    <img [src]="video.posterImage" style="width: 100%; height: 100%; object-fit: cover;" />
                  </a>
                </div>
                <div class="project-item__name">{{ video.fileName }}</div>
                <!-- <mat-icon class="vid-icon">video_library</mat-icon> -->
              </div>
            </div>


            <dts-select-container *ngIf="option =='pictures'" class="project-items w-100 p-2"
              #container="dts-select-container" (contextmenu)="openContextMenu($event)"
              [(selectedItems)]="selectedImages" (itemDeselected)="itemDeselected()"
              (itemSelected)="itemSelected($event)"
              [disabled]="selectedTag === 'relations' || getReadOnlyForCurrentUser()"
              [style.minHeight]="selectedTag === 'relations' ? '0' : '300px'">
              <div class="infinite-scroll-container w-100" (scroll)="container.update()" infiniteScroll
                [infiniteScrollDistance]="3" [infiniteScrollThrottle]="10" [alwaysCallback]="true"
                [scrollWindow]="false" (scrolled)="onScroll()" style="height: calc(100vh - 200px); overflow-y: auto;">
                <div class="w-100 p-2">
                  <div [dtsSelectItem]="image" class="project-item" *ngFor="let image of filteredImages; let i = index;"
                    [style.border]="isChecked(image.id) ? '2px solid #2196f3' : 'none'">
                    <div class="project-item__contents" [style.opacity]="isMovedImage(image) ? '.5' : '1'">
                      <a (dblclick)="openCanvas(image.id)" style="width: 100%; height: 100%;">
                        <mat-checkbox [checked]="isChecked(image.id) || isMovedImage(image)" [color]="'primary'"
                          style="position: absolute; right: 5px; top: 5px" (click)="
                              $event.preventDefault();
                              $event.stopImmediatePropagation();
                            ">
                        </mat-checkbox>
                        <img [src]="
                        isColorImage && image.colorThumbFileUrl ?
                        image.colorThumbFileUrl:image.thumbFileUrl"
                          style="width: 100%; height: 100%; object-fit: cover;" />
                      </a>
                    </div>
                    <div *ngIf="!image.fileUrl" class="project-item-spinner"
                      style="position: absolute; right: 6px; top: 6px;">
                      <mat-spinner [diameter]="20" [strokeWidth]="3"></mat-spinner>
                    </div>

                    <div [style.paddingLeft.px]="checkTopSeverity(image) ? 50 : 35" style="padding-right: 20px"
                      class="project-item__name">{{ image.fileName }}
                    </div>
                    <span class="icon-3d" matTooltip="Include/Exclude for 3d model (ALT+click)"
                      (click)="toggle3DIcon(image);$event.stopPropagation();">
                      <img [src]="getExcudedImages(image.id) ? 'assets/3d-black.png' : 'assets/3d.png'" />
                    </span>

                    <span [style.background]="checkTopSeverity(image)" class="circle icon-severity"></span>

                    <span (click)="openExifInfo(image.metaData)" class="icon-info" matTooltip="EXIF Info (ALT+click)">
                      <mat-icon [style.color]="!isEmptyObject(image.metaData) ? '#007bff' : 'darkgray'">info</mat-icon>
                    </span>


                  </div>
                </div>
                <div class="w-100 d-flex justify-content-center" *ngIf="uiService.isFolderContentLoading$ | async">
                  <!-- Loading... -->
                  <span>
                    <mat-spinner [diameter]="20"></mat-spinner>
                  </span>
                </div>
              </div>
            </dts-select-container>
          </ng-container>
          <!-- ==== -->

          <!-- show context relations -->
          <ng-container *ngIf="selectedTag === 'relations'">
            <button [disabled]="uiService.projectInfo.loading" class="filter-icon context-menu mr-2" mat-button
              matTooltip="Filter and Report Groups" (click)="openFilterPanel()" *ngIf="option =='pictures'">
              <mat-spinner matTooltip="fetching annotations" *ngIf="uiService.projectInfo.loading"
                style="display: inline-block;" [diameter]="20"></mat-spinner>
              <mat-icon>filter_alt</mat-icon>
            </button>
            <app-image-titles [projectId]="projectId"></app-image-titles>
          </ng-container>
          <!-- ==== -->


          <div *ngIf="uiService.uploadImagesCount !== 0 && 
            filterArrayByField(filteredImages, 'id').length !== uiService.uploadImagesCount &&
            currentUploadFolderPath === activeFolderPath" class="upload-image-progressbar">
            <div class="upload-image-progressbar__text">
              {{uiService.imageProcess.imageSuccess
              }} of {{ uiService.imageProcess.imageUpload}} images uploaded
            </div>
            <div (click)="showFailedUploads()" class="upload-image-progressbar__close">
              Show failed uploads
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>

<ng-template #errorUploadDialog let-data>
  <p style="font-size: 20px; text-align: center;"><b> Upload error!</b></p>
  <p style="font-size: 16px;">These files weren't uploaded.</p>
  <div class="d-flex flex-wrap" style="max-width: 630px; box-sizing: border-box;">
    <div class="project-item mr-2 mb-2" *ngFor="let image of data.images; let i = index"
      style="max-width: 32%; max-height: 80px; position: relative;">
      <img [src]="image.thumbFileUrl" style="object-fit: cover; width: 100%; height: 100%;" />
    </div>
  </div>

  <div class="d-flex align-items-center justify-content-end mt-3">
    <button mat-button color="primary" mat-raised-button (click)="errorUploadDialogRef.close()">Ok, I
      understood</button>
  </div>
</ng-template>

<!-- context menu -->
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button *ngIf="!movedImages.length" (click)="selectMovedImages()" mat-menu-item>Move</button>
    <button *ngIf="movedImages.length" (click)="cancelMoving()" mat-menu-item>Cancel moving</button>
    <button *ngIf="movedImages.length" (click)="pasteImages()" mat-menu-item>Paste</button>
  </ng-template>
</mat-menu>
<!-- ==== -->



<ng-template #engineDialog let-data>
  <div class="popup-header w-100 d-flex">
    <p class="w-100">
      AI feature Process
    </p>
    <mat-icon class="close-btn" (click)="engineDialogRef.close()">close</mat-icon>
  </div>
  <div mat-dialog-content class=" engine-modal mb-5">
    <div class="row">
      <div class="col">
        <div *ngIf="aiTags.length" mat-form-field class="w-100 mb-2">
          <div *ngIf="uiService.skipImages.length != 0">
            <label class="ai-option-label">Select an option</label>
            <mat-radio-group style="font-size: 11px;" [(ngModel)]="data.mode">
              <mat-radio-button value="merge">
                merged verifed/manual annotations
              </mat-radio-button>
              <mat-radio-button value="replace">
                replaced verifed/manual annotations
              </mat-radio-button>
              <mat-radio-button value="skip">
                skipped verifed/manual annotated images
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <small *ngIf="aiTags.length">
          Following tags <small class="small-tag"> {{getSmallNotes(aiTags)}}</small> will be processed
        </small>
        <div *ngIf="!aiTags.length" mat-form-field class="w-100 mb-2">
          <mat-label class="ai-option-label">
            You do not have any active AI Tags.Add the AI tags first.
          </mat-label>
        </div>

      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <div *ngIf="aiTags.length" class="d-flex align-items-center justify-content-center mt-3">
      <mat-spinner *ngIf="data.spinner" [diameter]="18" class="mr-2"></mat-spinner>
      <button [disabled]="data.spinner || !data.mode" mat-button color="primary" mat-raised-button
        (click)="runEngine(data)">RUN</button>
    </div>
  </div>
</ng-template>

<ng-template #versionDiaLog let-data>
  <div class="engine-modal p-10">
    <div class="row">
      <div class="col">
        <div>
          <span>
            Processing {{getTagName(data)}} with latest version
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-data #projectModel>
  <app-base-model [projects]="data.projects" [selectedProjectId]="data.selectedProjectId"
    [isAssetOwner]="data.isAssetOwner" [assetId]="data.assetId" [dialogRef]="projectModelRef" [type]="'project'"
    [asset]="data.asset">
  </app-base-model>
</ng-template>

<!-- 2D Model Component -->
<ng-template let-data #project2DModel>
  <app-base-image-model [projects]="data.projects" [selectedProjectId]="data.selectedProjectId"
    [isAssetOwner]="data.isAssetOwner" [assetId]="data.assetId" [dialogRef]="project2DModelRef" [type]="'project'"
    [asset]="data.asset">
  </app-base-image-model>
</ng-template>


<!-- 3D Model -->
<ng-template let-data #3dModel>
  <div style="padding:30px;" class="d-flex justify-content-center w-100 align-items-center flex-column mt-2 mb-2">
    <div style="border: 1px dashed #e3e3e3;font-size: 14px;width: 100%;padding: 15px;">
      <div class="row mb-2">
        <div class="col">
          <!-- ------------- Aligned icon------- -->
          <button [class.isActive]="project?.isAligned"  mat-raised-button color="primary" matTooltip="Align Your model"
            class="header-flat-button w-100" type="button" (click)="alignModel()" [class.isDisabled]="getReadOnlyForCurrentUser() 
  || project?.tileStatus != success
  || uiService.asset?.baseModel === '2d'
  || uiService.asset?.baselineProjectId === project?.id
  || uiService.model?.tileStatus != success
  || project?.alignStatus === queued
  || project?.alignStatus === process
  
  " matTooltip="{{isModelAlignBlocks() || 'Align / Sync your model'}}">
            <mat-spinner *ngIf="project?.alignStatus ===  process || project?.alignStatus ===  queued" [diameter]="20">
            </mat-spinner>
            <mat-icon>view_array</mat-icon>
            Align the model
          </button>

        </div>
        <div class="col">
          <!-- ------------- View icon------- -->
          <button  mat-raised-button color="primary" class="header-flat-button w-100" type="button" (click)="openModel()"
            [class.isDisabled]="isView3DModelBlock()" matTooltip="{{viewModelToolTips()}}">
            <mat-icon>remove_red_eye</mat-icon>
            View 3D Model
          </button>
        </div>
      </div>

      <button
        [disabled]="isImageUploading$.value || getUploadState()  || 
      (this.project?.tileStatus && 
      (this.project?.tileStatus  ===  process || this.project?.tileStatus  ===  queued || (this.project?.tileStatus  ===  failed &&  this.project?.modelUploadFailed)))"
        mat-raised-button (click)="handleAdd()" style="width:calc(100% - 60px)" class="mb-0" color="primary">
        <mat-spinner *ngIf="isImageUploading$.value" [diameter]="20"></mat-spinner>
        <mat-icon class="mr-2">upload</mat-icon>

        <span class="text-uppercase"> Upload your 3D model (zip) File</span>
      </button>
      <button type="button" class="btn border-0 bg-transparent border-0 mr-1 ml-1" placement="right"
        [ngbPopover]="popContent" popoverTitle="Supported formats">
        <mat-icon>help</mat-icon>
      </button>
      <span class="d-block">
        <strong>Zip file contains a combiation of model and texture file:</strong><br>
        1. 3D Model file:<br><small>Supported formats: .obj, .fbx, .dae, .gltf, .glb</small>
        <!-- .3ds, .ply, .x3d, .stl, -->
        <br>
        2. Textured image file(Optional) <br>
      </span>
      <span style="font-weight: 500;">
        Obj model required the Material Template Library (.mtl file)
      </span>
      <br>
      <span class="modal-message">
        This platform supports Global coordinates Euclidean system EPSG : 4326. For any other global/local coordinates
        system the platform has a manual orientation feature in the control panel of the 3D viewer. The 3D model
        orientation can be changed there.
      </span>
      <ng-template #popContent>
        Alias Wavefront Object (.obj)<br>
        <!-- 3D-Studio File Format (.3ds)<br>
        Stanford Polygon File Format (.ply)<br> -->
        FBX Autodesk Interchange Format (.fbx)<br>
        Collada File Format (.dae)<br>
        <!-- X3D File Format – XML encoding (.x3d)<br>
        STL File Format – STereoLithography (.stl)<br> -->
        Graphics Language Transmission Format(.gltf)<br>
        GL Transmission Format Binary file(.glb)<br>
        <!--
        Eisen Script (.es)<br> 
        STL File Format (.stl)<br>
        Quad Object (.qobj)<br>
        Object File Format (.off)<br>
        PTX File Format (.ptx)<br>
        VCG Dump File Format (.vmi)<br> 
        Breuckman File Format (.bre)<br> 
        OpenCTM compressed format (.ctm)<br>
        E57 (E57 point cloud) (.e57)<br>
        Expe’s point set (binary)(.pts)<br>
        Expe’s point set (ascuii)(.apts)<br>
        XYZ Point cloud (with or without normal)(.xyz)<br>
        GL Transmission Format 2.0 (glb)<br>
        Protein Data Bank (.pdb)<br>
        TRI (photogrammetric reconstructions)(.tri)<br>
        ASC (ascii triplets of points)(.asc)<br>
        TXT(Generic ASCII poinst list)(.txt)<br>
         X3D File Format – VRML encoding (.x3dv)<br>
        VRML 2.0 File Format (.wrl) -->
      </ng-template>


    </div>
    <div class="d-block text-center my-2"><strong>--------- or ---------</strong></div>
    <mat-checkbox *ngIf="!uiService.model || uiService.model?.tileStatus === failed" [(ngModel)]="data.isBaselineModel"
      [disabled]="uiService.asset?.baseModel == '2d'" [color]="'primary'">
      <span>
        Refer as a 3d baseline model
      </span>
    </mat-checkbox>
    <p class="p-0 m-0 text-left w-100"> Camera Lens</p>
    <mat-radio-group
      [disabled]="getUploadState()  || project?.tileStatus === 'requestProcessing' || 
    (project?.tileStatus && 
    (project?.tileStatus  ===  process || project?.tileStatus  ===  queued || (project?.tileStatus  ===  failed &&  project?.modelGenerateFailed)))"
      [(ngModel)]="data.cameraLens">
      <mat-radio-button class="mr-1" value="normal"> Normal </mat-radio-button>
      <mat-radio-button class="mr-1" value="wide-angle"> Wide-angle
      </mat-radio-button>
      <mat-radio-button class="mr-1" value="fish-eye"> Fish eye
      </mat-radio-button>
    </mat-radio-group>
    <button
      [disabled]="getUploadState() || !uiService.projectInfo.images ||  project?.tileStatus ==='requestProcessing' || 
    (project?.tileStatus && 
    (project?.tileStatus  ===  process || project?.tileStatus  ===  queued || (project?.tileStatus  ===  failed &&  project?.modelGenerateFailed)))"
      mat-raised-button color="primary" (click)="projectImageModel(data)" class="mb-0 mt-1 btn-block">
      <span class="text-uppercase">
        <span>
          Generate 3D model
        </span>
      </span>
    </button>

    <div *ngIf="project?.tileStatus  ===  'requestProcessing'">
      <mat-spinner style="display: inline-block;" [diameter]="20"></mat-spinner>
      Please wait... we are processing your request.
    </div>
    <mat-progress-bar class="mt-2" *ngIf="getUploadState() &&
    (project?.tileStatus !=  process && project?.tileStatus !=  queued)" #uploadProgressBar mode="determinate"
      [value]="getProgressPercentage()">
    </mat-progress-bar>
    <small *ngIf="getUploadState() &&
    (project?.tileStatus !=  process && project?.tileStatus !=  queued)">{{uploadMessage}}</small>
    <div class="model-up process" *ngIf="project?.tileStatus === process || project?.tileStatus ===  queued">
      <b>{{processMessage}} </b>
      <p *ngIf="project.executionTime && project.approxModelExecutionTime">
        <span [innerHTML]="getApproxTime()"></span>
      </p>
      <button [disabled]="data.isCancelling" color="warn" mat-button mat-stroked-button class="deleteDialog-button"
        (click)="handleCancel(data)">
        <mat-spinner [diameter]="20" *ngIf="data.isCancelling"></mat-spinner>
        Cancel Model Process
      </button>

    </div>
    <small class="model-up failed" *ngIf="project?.tileStatus === failed">
      {{getFailedMessage()}} Project ID: <b>{{uiService.project?.id}}</b> Project Name:
      <b>{{uiService.project?.name}}</b>
    </small>
    <div *ngIf="getUploadState() && 
    (project?.tileStatus !=  process && project?.tileStatus !=  queued)"
      class=" ml-2 mr-2 align-items-center justify-content-end mt-3">
      <button color="warn" mat-button mat-stroked-button class="deleteDialog-button" (click)="cancelUpload()">
        Cancel Upload
      </button>
    </div>
    <small *ngIf="message" class="model-up failed">
      {{message}}
      If you would still choose to continue with 3D generation please click
      <button color="primary" mat-button mat-stroked-button class="deleteDialog-button"
        (click)="generateModelMinImages(data)">
        Continue
      </button>
    </small>
  </div>
</ng-template>

<input type="file" (click)="$event.target.value = null" accept="application/zip" (change)="fileChoose($event)" hidden
  id="model_3d_image" />

<input type="file" (click)="$event.target.value = null" accept="application/zip" (change)="fileLogChoose($event)" hidden
  id="add_logs" />

<!-- Exif Model -->
<ng-template let-data #exifModel>
  <div class="small-info" *ngIf="!data.isExapnd">
    <p><strong>Make: {{data.data?.Make}}</strong></p>
    <strong>EXIF Info-</strong>
    <ul style="padding-left: 20px;">
      <li>Exif Version: {{data.data?.ExifToolVersion || 'N/A' }}</li>
      <li>Model: {{data.data?.Model || 'N/A' }}</li>
      <li>Lens Model: {{data.data?.LensModel || 'N/A' }}</li>
      <li>Serial Number: {{data.data?.SerialNumber || 'N/A' }}</li>
    </ul>
    <strong>GPS Info-</strong>
    <ul style="padding-left: 20px;">
      <li>Altitude: {{data.data?.GPSAltitudeRef || 'N/A' }}</li>
      <li>LatitudeRef: {{data.data?.GPSLatitudeRef || 'N/A' }}</li>
      <li>LongitudeRef: {{data.data?.GPSLongitudeRef || 'N/A' }}</li>
      <li>Latitude: {{data.data?.GpsLatitude || 'N/A' }}</li>
      <li>Longitud: {{data.data?.GpsLongitude || 'N/A' }}</li>

    </ul>
  </div>
  <div class="more-info" *ngIf="data.isExapnd">
    <div *ngFor="let info of data.more">
      <b>{{info[0]}}</b>: {{info[1]}}
    </div>
  </div>
  <span *ngIf="!data.isExapnd" class="show-more" (click)="openPrompt(data,true)">Show More</span>
  <span *ngIf="data.isExapnd" class="show-more" (click)="openPrompt(data,false)">Show Less</span>
</ng-template>

<!-- Anomly Detection Model -->
<ng-template let-data #hotspotDetectionDialog>
  <div class="popup-header w-100 d-flex">
    <p class="w-100">
      Hotspot Detection
    </p>
    <mat-icon class="close-btn" (click)="hotspotDetectionDialogRef.close()">close</mat-icon>
  </div>
  <div mat-dialog-content class=" engine-modal mb-5">
    <div class="row">
      <div class="col-12">
        <h5 class="">Temperature Range</h5>
        <div class="custom-slider d-flex">
          <span class="d-flex flex-col">
            <!--<span class="material-icons">device_thermostat</span> -->(Min)
          </span>
          <ngx-slider [showTicks]="false" [(value)]="data.minTemp" [(highValue)]="data.maxTemp" [options]="options">
          </ngx-slider>
          <span class="d-flex flex-col">
            <!-- <span class="material-icons">device_thermostat</span> -->
            (Max)
          </span>
        </div>
      </div>
      <div class="w-100 text-center">
        Normal temperature range: <b>{{ data.minTemp}} °C</b> - <b>
          {{ data.maxTemp}} °C</b>
      </div>
    </div>
    <div class="row">
      <div *ngIf="standardDeviation" class="col-12">
        <div mat-form-field class="w-100">
          <div class="counter">
            <h5>Standard Deviation (In °C)</h5>
            <button (click)="subtract(data)" [disabled]="data.standardDeviation <= minDeviation "
              class="deviation_btn">-</button>
            <mat-form-field class="spinner-hide" appearance="fill">
              <input step=".01" type="number" [(ngModel)]="data.standardDeviation" matInput>
            </mat-form-field>
            <button (click)="add(data)" [disabled]="data.standardDeviation >= maxDeviation"
              class="deviation_btn">+</button>
          </div>
        </div>
      </div>
      <small>
        Hot Temperature: {{ data.maxTemp + data.standardDeviation}}°C
        &nbsp;
        Cold Temperature: {{ data.minTemp - data.standardDeviation}}°C
      </small>
      <br>
    </div>
    <div class="row text-center p-4">
      <button (click)="hotspotDetect(data)" mat-button color="primary" mat-raised-button>Proceed</button>
    </div>
    <span class="note">
      This process will take 1 hour to calculate hotposts detections
    </span>
  </div>

</ng-template>

<input type="file" (click)="$event.target.value = null" accept=".xlsx" (change)="inspectionFileChoose($event)" hidden
  id="inspection_file" />

<!-- log model -->
<ng-template let-data #logModelDialog>
  <div class="popup-header w-100 d-flex">
    <p class="w-100">
      Logs
    </p>
  </div>
  <div style="padding:30px;" class="d-flex justify-content-center w-100 align-items-center flex-column mt-2 mb-2">
    <div style="border: 1px dashed #e3e3e3;font-size: 14px;width: 100%;padding: 15px;">
      <p class="p-0 m-0 text-left w-100"> Data Log file</p>
      <button [disabled]="isLogUploading$.value || project?.logFiles && project?.logFiles?.length" mat-raised-button
        (click)="addLogFiles()" class="mb-0 w-100" color="primary">
        <mat-spinner *ngIf="isLogUploading$.value" [diameter]="20"></mat-spinner>
        <span class="text-uppercase"> Upload your data log files (.zip)</span>
      </button>
      <mat-progress-bar class="mt-2" *ngIf="logUploadPercentage" mode="determinate" [value]="logUploadPercentage">
      </mat-progress-bar>
      <br>

      <div *ngIf="!logUploadPercentage  &&  isLogUploading$.value">
        Please wait... we are processing your log files.
      </div>

      <span class="d-flex log-prompt" *ngIf="uiService.isLogProcessing$ | async">
        <mat-spinner class="spinner" [diameter]="15"></mat-spinner>
        Please wait... we are fetching your logs data.
      </span>
    </div>
    <br>
    <br>
    <div style="border: 1px dashed #e3e3e3;font-size: 14px;width: 100%;padding: 15px;">
      <p class="p-0 m-0 text-left w-100"> Inspection Log file</p>
      <button
        [disabled]="project?.inspectionFile ||  uiService.isLogProcessing$.value || isLinking$.value ||  isProcessing$.value || isLogUploading$.value || !project?.logFiles || !project?.logFiles.length"
        mat-raised-button color="primary" (click)="inspectionLog(data)" class="mb-0 mt-1 btn-block">
        <mat-spinner *ngIf="isProcessing$.value" [diameter]="20"></mat-spinner>
        <span class="text-uppercase">
          Inspection log (.excel)
        </span>
      </button>

      <div *ngIf="isLinking$.value">
        Please wait... we are linking your images to labels.
      </div>
    </div>
  </div>


</ng-template>
<div class="w-100 h-100 bg">
  <headings class="headings py-2">
    <span class="mr-3 title">
      <span style="font-size: 2rem; cursor: pointer;" (click)="close()" class="material-icons">arrow_back</span>
    </span>
    <button color="primary" [class.asset_active]="tab === 0" class="asset-button ml-1" mat-raised-button
      (click)="onTabClick(0)">
      <span>Assets</span>
    </button>
    <button color="primary" [class.asset_active]="tab === 1" class="asset-button ml-1" *ngIf="data?.id"
      mat-raised-button (click)="onTabClick(1)">
      <span>Base 2D Model</span>
    </button>
    <button color="primary" [class.asset_active]="tab === 2" class="asset-button ml-1" *ngIf="data?.id"
      mat-raised-button (click)="onTabClick(2)">
      <span>Base 3D Model</span>
    </button>
    <button color="primary" [class.asset_active]="tab === 3" class="asset-button ml-1" *ngIf="data?.id"
      mat-raised-button (click)="onTabClick(3)">
      <span>Inspection Schedule</span>
    </button>
    <div class="d-flex side-right ml-auto">

      <mat-spinner-button *ngIf="labelsChanged && tab === 1" class="mr-1" [options]="createSaveBtnOptions"
        (btnClick)="saveLabels()"></mat-spinner-button>
      <mat-spinner-button *ngIf="labelsChanged && tab === 1" class="mr-1" [options]="createSaveWithCancelBtnOptions"
        (btnClick)="saveLabelsWithCancel()"></mat-spinner-button>

      <button mat-button (click)="close()">Cancel</button>
      <mat-spinner-button *ngIf="tab == 0" class="mr-1 ml-1 header-spinner-button btn-save" [options]="createBtnOptions"
        (btnClick)="validate(form.value)" [disabled]="!form.valid || (!isAssetOwner && data.id)"></mat-spinner-button>
      <mat-spinner-button *ngIf="tab == 3" class="mr-2 ml-1 header-spinner-button btn-save"
        [options]="inspectionSaveBtnOptions" (btnClick)="inspectionSave(inspection)"
        [disabled]="!inspection.startDate || !inspection.endDate || !inspection.frequency || !inspection.frequency_number || !isAssetOwner">
      </mat-spinner-button>


      <mat-spinner-button *ngIf="tab == 0 && data?.id" class="mr-1 ml-1 header-spinner-button btn-save"
        [options]="deleteAssetBtnOptions" (btnClick)="deleteAssetPrompt()"
        [disabled]="assetOwner?.id != backend.currentUser.uid">
      </mat-spinner-button>


      <mat-icon *ngIf="tab == 0 || tab == 3" class="help-tip mr-2 ml-2" [matTooltip]="'Only Project Owners and Asset Creators can update the information.
          '">help</mat-icon>
    </div>
  </headings>
  <div class="row app-asset-form bg">
    <div class="col-12">
      <div class="form-scroll" [hidden]="tab != 0">
        <!-- <div class="row"><div class="col-9"><div class="d-flex align-items-center"><img src="assets/asset.png" class="assets_icon"><h4 class="title">Asset Information</h4></div></div><div class="col-3 d-flex align-items-center"><p class="location"><mat-icon class="location_icon">location_on</mat-icon>
                      Asset Location Change
                  </p></div></div> -->
        <!-- form section -->
        <form [formGroup]="form">



          <div class="row m-0">
            <div class="col-12 col-md-6">
              <mat-card class="card-assets-info mt-3 mb-3">
                <mat-form-field class="w-100 mb-3" appearance="fill">
                  <input id="locationTextField" [disabled]="!isAssetOwner && data?.id" type="text"
                    placeholder="Search address" matInput>
                </mat-form-field>
                <mat-tab-group [selectedIndex]="tabIndex" headerPosition="below" dynamicHeight>
                  <mat-tab label="Asset">
                    <ng-template mat-tab-label>
                      <span class="material-icons"> image </span>
                    </ng-template>
                    <div class="upload_image_section">
                      <div *ngIf="assetsPic" [style.background-image]="'url(' + assetsPic?.link + ')' "></div>
                      <span *ngIf="!assetsPic"> Asset Image </span>
                    </div>
                    <span class="right-btn" *ngIf="isAssetsImageLoading$ | async">
                      <mat-spinner [diameter]="20"></mat-spinner>
                    </span>
                    <button [disabled]="!isAssetOwner && data?.id" *ngIf="!isAssetsImageLoading$.value"
                      (click)="uploadAssetImage()" mat-raised-button type="submit" color="primary" class="right-btn">
                      <span *ngIf="!assetsPic">Upload</span>
                      <span *ngIf="assetsPic">Change Picture</span>
                    </button>
                    <input type="file" accept="image/*" (change)="assetFileChoose($event)" hidden id="asset_image" />
                  </mat-tab>
                  <mat-tab class="map-tab">
                    <ng-template mat-tab-label>
                      <span class="material-icons"> public </span>
                    </ng-template>
                    <div style="height:280px;" #mapEl id="mapEl"></div>
                  </mat-tab>
                </mat-tab-group>
              </mat-card>
              <mat-card class="card-assets-info mb-3">
                <h4 class="card-title">Select the base project model <span class="required-field">*</span></h4>
                <mat-radio-group (change)="modelChange($event)"  formControlName="baseModel">
                  <mat-radio-button value="2d">2D Model </mat-radio-button>
                  <mat-radio-button class="ml-4" value="3d">3D Model</mat-radio-button>
                </mat-radio-group>
                <div *ngIf="isLoading">
                  <mat-spinner [diameter]="10"></mat-spinner>
                  Please wait, we are loading
                </div>
                <!-- <div *ngIf="data?.id" class="row">
                  <div class="col-12">
                    <label class="form-label">Select the baseline project<span class="required-field">*</span></label>
                  </div>
                  <div class="col-12 ">
                    <mat-form-field class="col-md-6" appearance="fill">
                      <mat-select (selectionChange)="changeBaselineProject($event.value)"
                        formControlName="baselineProjectId">
                        <mat-option value="">
                          I Do'not want to attach baseline
                        </mat-option>
                        <mat-option *ngFor="let project of projects" [value]="project.id">
                          {{project.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <p style="font-size: 10px;font-weight: 700;" *ngIf="isConflict">
                      There is a conflict between the 3D model of this project and the baseline 3D model. This projects
                      3D will not be visible.
                    </p>
                  </div>
                  <div class="col-12">
                    <h3 class="m-2">Asset Owner: <b>{{assetOwner?.name}}</b>
                      <div matTooltip="Change asset owner" matTooltipClass="reports-tooltip"
                        (click)="openAssetOwnerTransferPanel()"
                        [class.disabled]="assetOwner?.id != backend.currentUser.uid" class="mr-3 owner-icon">
                        <img src="/assets/icons/switch-user.svg" alt="Make Owner" style="vertical-align: top;">
                      </div>
                    </h3>
                  </div>
                </div> -->
              </mat-card>
              <mat-card class="card-assets-info">
                <div class="row mb-2">
                  <div class="col-12">
                    <label class="form-label">Company Name <span class="required-field">*</span>
                    </label>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="companyName" (input)="filterName($event.target.value)"
                        matInput [matAutocomplete]="company">
                      <mat-autocomplete #company="matAutocomplete">
                        <mat-option style="height:auto" (click)="selectCompany(company)"
                          *ngFor="let company of companies" [value]="option">
                          {{company.companyName}}
                          <p class="address"> {{company.asset?.address}}</p>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <label class="form-label">Address</label>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="address" matInput>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-6 mb-2">
                    <label class="form-label">City</label>
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="city" matInput>
                    </mat-form-field>

                  </div>
                  <div class="col-12 col-md-6 mb-2"><label class="form-label">State</label>
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="state" matInput>
                    </mat-form-field>
                  </div>


                </div>
                <div class="row">
                  <div class="col-12 col-md-6 mb-2">
                    <label class="form-label">Country</label>
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="country" matInput>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 mb-2"> <label class="form-label">Zip</label>
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="zip" matInput>
                    </mat-form-field>
                  </div>


                </div>
                <div class="row">
                  <div class="col-12 col-md-6 mb-2">
                    <label class="form-label">Phone #</label>
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="phone" matInput>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 mb-2"><label class="form-label">Fax#</label>
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="fax" matInput>
                    </mat-form-field>
                  </div>


                </div>
              </mat-card>
            </div>
            <div class="col-12 col-md-6">

              <mat-card class="card-assets-info my-3">
                <div class="row mb-2">
                  <div class="col-12">
                    <label class="form-label">Asset Name <span class="required-field">*</span>
                    </label>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="assetName" matInput>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <label class="form-label">Asset Type <span class="required-field">*</span>
                    </label>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="fill">
                      <mat-select (selectionChange)="changeAssetType($event.value)" formControlName="assetType">
                        <mat-option value="wind-turbine">Wind Turbine</mat-option>
                        <mat-option value="tower">Tower</mat-option>
                        <mat-option value="flare">Flare</mat-option>
                        <mat-option value="solar">Solar</mat-option>
                        <mat-option value="other">Other</mat-option>

                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12 c">
                    <label class="form-label">Dive Asset Tags <span class="required-field">*</span>
                    </label>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="fill">
                      <input type="text" readonly="true" formControlName="assetTags" matInput>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <label class="form-label">Asset ID <span class="required-field">*</span>
                    </label>
                  </div>

                  <div class="col-12">
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="assetId" matInput>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <label class="form-label">Asset Sr Number</label>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="assetNumber" matInput>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <label class="form-label">Industry <span class="required-field">*</span>
                    </label>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="fill">
                      <mat-select formControlName="industry">
                        <mat-option *ngFor="let industry of userIndustries" [value]="industry">
                          {{industry}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6 mb-2"> <label class="form-label">Eq. Class</label>
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="eqClass" matInput>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 mb-2"> <label class="form-label">Eq. Sub Class</label>
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="eqSubClass" matInput>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-6 mb-2"> <label class="form-label">Location</label>
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="location" matInput>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 mb-2"> <label class="form-label">Country</label>
                    <mat-form-field appearance="fill">
                      <mat-select formControlName="country" matInput>
                        <mat-option value="Afganistan">Afghanistan</mat-option>
                        <mat-option value="Albania">Albania</mat-option>
                        <mat-option value="Algeria">Algeria</mat-option>
                        <mat-option value="American Samoa">American Samoa </mat-option>
                        <mat-option value="Andorra">Andorra</mat-option>
                        <mat-option value="Angola">Angola</mat-option>
                        <mat-option value="Anguilla">Anguilla</mat-option>
                        <mat-option value="Antigua & Barbuda">Antigua & Barbuda </mat-option>
                        <mat-option value="Argentina">Argentina</mat-option>
                        <mat-option value="Armenia">Armenia</mat-option>
                        <mat-option value="Aruba">Aruba</mat-option>
                        <mat-option value="Australia">Australia</mat-option>
                        <mat-option value="Austria">Austria</mat-option>
                        <mat-option value="Azerbaijan">Azerbaijan</mat-option>
                        <mat-option value="Bahamas">Bahamas</mat-option>
                        <mat-option value="Bahrain">Bahrain</mat-option>
                        <mat-option value="Bangladesh">Bangladesh</mat-option>
                        <mat-option value="Barbados">Barbados</mat-option>
                        <mat-option value="Belarus">Belarus</mat-option>
                        <mat-option value="Belgium">Belgium</mat-option>
                        <mat-option value="Belize">Belize</mat-option>
                        <mat-option value="Benin">Benin</mat-option>
                        <mat-option value="Bermuda">Bermuda</mat-option>
                        <mat-option value="Bhutan">Bhutan</mat-option>
                        <mat-option value="Bolivia">Bolivia</mat-option>
                        <mat-option value="Bonaire">Bonaire</mat-option>
                        <mat-option value="Bosnia & Herzegovina">Bosnia & Herzegovina </mat-option>
                        <mat-option value="Botswana">Botswana</mat-option>
                        <mat-option value="Brazil">Brazil</mat-option>
                        <mat-option value="British Indian Ocean Ter">British Indian Ocean Ter </mat-option>
                        <mat-option value="Brunei">Brunei</mat-option>
                        <mat-option value="Bulgaria">Bulgaria</mat-option>
                        <mat-option value="Burkina Faso">Burkina Faso</mat-option>
                        <mat-option value="Burundi">Burundi</mat-option>
                        <mat-option value="Cambodia">Cambodia</mat-option>
                        <mat-option value="Cameroon">Cameroon</mat-option>
                        <mat-option value="Canada">Canada</mat-option>
                        <mat-option value="Canary Islands">Canary Islands </mat-option>
                        <mat-option value="Cape Verde">Cape Verde</mat-option>
                        <mat-option value="Cayman Islands">Cayman Islands </mat-option>
                        <mat-option value="Central African Republic">Central African Republic </mat-option>
                        <mat-option value="Chad">Chad</mat-option>
                        <mat-option value="Channel Islands">Channel Islands </mat-option>
                        <mat-option value="Chile">Chile</mat-option>
                        <mat-option value="China">China</mat-option>
                        <mat-option value="Christmas Island">Christmas Island </mat-option>
                        <mat-option value="Cocos Island">Cocos Island</mat-option>
                        <mat-option value="Colombia">Colombia</mat-option>
                        <mat-option value="Comoros">Comoros</mat-option>
                        <mat-option value="Congo">Congo</mat-option>
                        <mat-option value="Cook Islands">Cook Islands</mat-option>
                        <mat-option value="Costa Rica">Costa Rica</mat-option>
                        <mat-option value="Cote DIvoire">Cote DIvoire</mat-option>
                        <mat-option value="Croatia">Croatia</mat-option>
                        <mat-option value="Cuba">Cuba</mat-option>
                        <mat-option value="Curaco">Curacao</mat-option>
                        <mat-option value="Cyprus">Cyprus</mat-option>
                        <mat-option value="Czech Republic">Czech Republic </mat-option>
                        <mat-option value="Denmark">Denmark</mat-option>
                        <mat-option value="Djibouti">Djibouti</mat-option>
                        <mat-option value="Dominica">Dominica</mat-option>
                        <mat-option value="Dominican Republic">Dominican Republic </mat-option>
                        <mat-option value="East Timor">East Timor</mat-option>
                        <mat-option value="Ecuador">Ecuador</mat-option>
                        <mat-option value="Egypt">Egypt</mat-option>
                        <mat-option value="El Salvador">El Salvador</mat-option>
                        <mat-option value="Equatorial Guinea">Equatorial Guinea </mat-option>
                        <mat-option value="Eritrea">Eritrea</mat-option>
                        <mat-option value="Estonia">Estonia</mat-option>
                        <mat-option value="Ethiopia">Ethiopia</mat-option>
                        <mat-option value="Falkland Islands">Falkland Islands </mat-option>
                        <mat-option value="Faroe Islands">Faroe Islands</mat-option>
                        <mat-option value="Fiji">Fiji</mat-option>
                        <mat-option value="Finland">Finland</mat-option>
                        <mat-option value="France">France</mat-option>
                        <mat-option value="French Guiana">French Guiana</mat-option>
                        <mat-option value="French Polynesia">French Polynesia </mat-option>
                        <mat-option value="French Southern Ter">French Southern Ter </mat-option>
                        <mat-option value="Gabon">Gabon</mat-option>
                        <mat-option value="Gambia">Gambia</mat-option>
                        <mat-option value="Georgia">Georgia</mat-option>
                        <mat-option value="Germany">Germany</mat-option>
                        <mat-option value="Ghana">Ghana</mat-option>
                        <mat-option value="Gibraltar">Gibraltar</mat-option>
                        <mat-option value="Great Britain">Great Britain</mat-option>
                        <mat-option value="Greece">Greece</mat-option>
                        <mat-option value="Greenland">Greenland</mat-option>
                        <mat-option value="Grenada">Grenada</mat-option>
                        <mat-option value="Guadeloupe">Guadeloupe</mat-option>
                        <mat-option value="Guam">Guam</mat-option>
                        <mat-option value="Guatemala">Guatemala</mat-option>
                        <mat-option value="Guinea">Guinea</mat-option>
                        <mat-option value="Guyana">Guyana</mat-option>
                        <mat-option value="Haiti">Haiti</mat-option>
                        <mat-option value="Hawaii">Hawaii</mat-option>
                        <mat-option value="Honduras">Honduras</mat-option>
                        <mat-option value="Hong Kong">Hong Kong</mat-option>
                        <mat-option value="Hungary">Hungary</mat-option>
                        <mat-option value="Iceland">Iceland</mat-option>
                        <mat-option value="Indonesia">Indonesia</mat-option>
                        <mat-option value="India">India</mat-option>
                        <mat-option value="Iran">Iran</mat-option>
                        <mat-option value="Iraq">Iraq</mat-option>
                        <mat-option value="Ireland">Ireland</mat-option>
                        <mat-option value="Isle of Man">Isle of Man</mat-option>
                        <mat-option value="Israel">Israel</mat-option>
                        <mat-option value="Italy">Italy</mat-option>
                        <mat-option value="Jamaica">Jamaica</mat-option>
                        <mat-option value="Japan">Japan</mat-option>
                        <mat-option value="Jordan">Jordan</mat-option>
                        <mat-option value="Kazakhstan">Kazakhstan</mat-option>
                        <mat-option value="Kenya">Kenya</mat-option>
                        <mat-option value="Kiribati">Kiribati</mat-option>
                        <mat-option value="Korea North">Korea North</mat-option>
                        <mat-option value="Korea Sout">Korea South</mat-option>
                        <mat-option value="Kuwait">Kuwait</mat-option>
                        <mat-option value="Kyrgyzstan">Kyrgyzstan</mat-option>
                        <mat-option value="Laos">Laos</mat-option>
                        <mat-option value="Latvia">Latvia</mat-option>
                        <mat-option value="Lebanon">Lebanon</mat-option>
                        <mat-option value="Lesotho">Lesotho</mat-option>
                        <mat-option value="Liberia">Liberia</mat-option>
                        <mat-option value="Libya">Libya</mat-option>
                        <mat-option value="Liechtenstein">Liechtenstein</mat-option>
                        <mat-option value="Lithuania">Lithuania</mat-option>
                        <mat-option value="Luxembourg">Luxembourg</mat-option>
                        <mat-option value="Macau">Macau</mat-option>
                        <mat-option value="Macedonia">Macedonia</mat-option>
                        <mat-option value="Madagascar">Madagascar</mat-option>
                        <mat-option value="Malaysia">Malaysia</mat-option>
                        <mat-option value="Malawi">Malawi</mat-option>
                        <mat-option value="Maldives">Maldives</mat-option>
                        <mat-option value="Mali">Mali</mat-option>
                        <mat-option value="Malta">Malta</mat-option>
                        <mat-option value="Marshall Islands">Marshall Islands </mat-option>
                        <mat-option value="Martinique">Martinique</mat-option>
                        <mat-option value="Mauritania">Mauritania</mat-option>
                        <mat-option value="Mauritius">Mauritius</mat-option>
                        <mat-option value="Mayotte">Mayotte</mat-option>
                        <mat-option value="Mexico">Mexico</mat-option>
                        <mat-option value="Midway Islands">Midway Islands </mat-option>
                        <mat-option value="Moldova">Moldova</mat-option>
                        <mat-option value="Monaco">Monaco</mat-option>
                        <mat-option value="Mongolia">Mongolia</mat-option>
                        <mat-option value="Montserrat">Montserrat</mat-option>
                        <mat-option value="Morocco">Morocco</mat-option>
                        <mat-option value="Mozambique">Mozambique</mat-option>
                        <mat-option value="Myanmar">Myanmar</mat-option>
                        <mat-option value="Nambia">Nambia</mat-option>
                        <mat-option value="Nauru">Nauru</mat-option>
                        <mat-option value="Nepal">Nepal</mat-option>
                        <mat-option value="Netherland Antilles">Netherland Antilles </mat-option>
                        <mat-option value="Netherlands">Netherlands (Holland, Europe) </mat-option>
                        <mat-option value="Nevis">Nevis</mat-option>
                        <mat-option value="New Caledonia">New Caledonia</mat-option>
                        <mat-option value="New Zealand">New Zealand</mat-option>
                        <mat-option value="Nicaragua">Nicaragua</mat-option>
                        <mat-option value="Niger">Niger</mat-option>
                        <mat-option value="Nigeria">Nigeria</mat-option>
                        <mat-option value="Niue">Niue</mat-option>
                        <mat-option value="Norfolk Island">Norfolk Island </mat-option>
                        <mat-option value="Norway">Norway</mat-option>
                        <mat-option value="Oman">Oman</mat-option>
                        <mat-option value="Pakistan">Pakistan</mat-option>
                        <mat-option value="Palau Island">Palau Island</mat-option>
                        <mat-option value="Palestine">Palestine</mat-option>
                        <mat-option value="Panama">Panama</mat-option>
                        <mat-option value="Papua New Guinea">Papua New Guinea </mat-option>
                        <mat-option value="Paraguay">Paraguay</mat-option>
                        <mat-option value="Peru">Peru</mat-option>
                        <mat-option value="Phillipines">Philippines</mat-option>
                        <mat-option value="Pitcairn Island">Pitcairn Island </mat-option>
                        <mat-option value="Poland">Poland</mat-option>
                        <mat-option value="Portugal">Portugal</mat-option>
                        <mat-option value="Puerto Rico">Puerto Rico</mat-option>
                        <mat-option value="Qatar">Qatar</mat-option>
                        <mat-option value="Republic of Montenegro">Republic of Montenegro </mat-option>
                        <mat-option value="Republic of Serbia">Republic of Serbia </mat-option>
                        <mat-option value="Reunion">Reunion</mat-option>
                        <mat-option value="Romania">Romania</mat-option>
                        <mat-option value="Russia">Russia</mat-option>
                        <mat-option value="Rwanda">Rwanda</mat-option>
                        <mat-option value="St Barthelemy">St Barthelemy</mat-option>
                        <mat-option value="St Eustatius">St Eustatius</mat-option>
                        <mat-option value="St Helena">St Helena</mat-option>
                        <mat-option value="St Kitts-Nevis">St Kitts-Nevis </mat-option>
                        <mat-option value="St Lucia">St Lucia</mat-option>
                        <mat-option value="St Maarten">St Maarten</mat-option>
                        <mat-option value="St Pierre & Miquelon">St Pierre & Miquelon </mat-option>
                        <mat-option value="St Vincent & Grenadines">St Vincent & Grenadines </mat-option>
                        <mat-option value="Saipan">Saipan</mat-option>
                        <mat-option value="Samoa">Samoa</mat-option>
                        <mat-option value="Samoa American">Samoa American </mat-option>
                        <mat-option value="San Marino">San Marino</mat-option>
                        <mat-option value="Sao Tome & Principe">Sao Tome & Principe </mat-option>
                        <mat-option value="Saudi Arabia">Saudi Arabia</mat-option>
                        <mat-option value="Senegal">Senegal</mat-option>
                        <mat-option value="Seychelles">Seychelles</mat-option>
                        <mat-option value="Sierra Leone">Sierra Leone</mat-option>
                        <mat-option value="Singapore">Singapore</mat-option>
                        <mat-option value="Slovakia">Slovakia</mat-option>
                        <mat-option value="Slovenia">Slovenia</mat-option>
                        <mat-option value="Solomon Islands">Solomon Islands </mat-option>
                        <mat-option value="Somalia">Somalia</mat-option>
                        <mat-option value="South Africa">South Africa</mat-option>
                        <mat-option value="Spain">Spain</mat-option>
                        <mat-option value="Sri Lanka">Sri Lanka</mat-option>
                        <mat-option value="Sudan">Sudan</mat-option>
                        <mat-option value="Suriname">Suriname</mat-option>
                        <mat-option value="Swaziland">Swaziland</mat-option>
                        <mat-option value="Sweden">Sweden</mat-option>
                        <mat-option value="Switzerland">Switzerland</mat-option>
                        <mat-option value="Syria">Syria</mat-option>
                        <mat-option value="Tahiti">Tahiti</mat-option>
                        <mat-option value="Taiwan">Taiwan</mat-option>
                        <mat-option value="Tajikistan">Tajikistan</mat-option>
                        <mat-option value="Tanzania">Tanzania</mat-option>
                        <mat-option value="Thailand">Thailand</mat-option>
                        <mat-option value="Togo">Togo</mat-option>
                        <mat-option value="Tokelau">Tokelau</mat-option>
                        <mat-option value="Tonga">Tonga</mat-option>
                        <mat-option value="Trinidad & Tobago">Trinidad & Tobago </mat-option>
                        <mat-option value="Tunisia">Tunisia</mat-option>
                        <mat-option value="Turkey">Turkey</mat-option>
                        <mat-option value="Turkmenistan">Turkmenistan</mat-option>
                        <mat-option value="Turks & Caicos Is">Turks & Caicos Is </mat-option>
                        <mat-option value="Tuvalu">Tuvalu</mat-option>
                        <mat-option value="Uganda">Uganda</mat-option>
                        <mat-option value="United Kingdom">United Kingdom </mat-option>
                        <mat-option value="Ukraine">Ukraine</mat-option>
                        <mat-option value="United Arab Erimates">United Arab Emirates </mat-option>
                        <mat-option value="United States of America">United States of America </mat-option>
                        <mat-option value="Uraguay">Uruguay</mat-option>
                        <mat-option value="Uzbekistan">Uzbekistan</mat-option>
                        <mat-option value="Vanuatu">Vanuatu</mat-option>
                        <mat-option value="Vatican City State">Vatican City State </mat-option>
                        <mat-option value="Venezuela">Venezuela</mat-option>
                        <mat-option value="Vietnam">Vietnam</mat-option>
                        <mat-option value="Virgin Islands (Brit)">Virgin Islands (Brit) </mat-option>
                        <mat-option value="Virgin Islands (USA)">Virgin Islands (USA) </mat-option>
                        <mat-option value="Wake Island">Wake Island</mat-option>
                        <mat-option value="Wallis & Futana Is">Wallis & Futana Is </mat-option>
                        <mat-option value="Yemen">Yemen</mat-option>
                        <mat-option value="Zaire">Zaire</mat-option>
                        <mat-option value="Zambia">Zambia</mat-option>
                        <mat-option value="Zimbabwe">Zimbabwe</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label class="form-label">Date of Comm.</label>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="fill">
                      <input type="date" matInput formControlName="dateOfComm">
                      <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle><mat-datepicker #picker></mat-datepicker> -->
                    </mat-form-field>
                  </div>
                </div>
              </mat-card>
              <mat-card class="card-assets-info mb-3">
                <div class="row">
                  <div class="col-12">
                    <label class="form-label"> Contact Name <span class="required-field">*</span>
                    </label>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="contactName" (input)="filterContactName($event.target.value)"
                        matInput [matAutocomplete]="contact">
                      <mat-autocomplete #contact="matAutocomplete">
                        <mat-option style="height:auto" (click)="selectContact(contact)"
                          *ngFor="let contact of contacts" [value]="contact.contactName">
                          {{contact.contactName}}
                          <p class="address"> {{contact.email}}</p>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>


                <div class="row">
                  <div class="col-12">
                    <label class="form-label">Location</label>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="contactPersonLocation" matInput>
                    </mat-form-field>
                  </div>
                </div>


                <div class="row ">
                  <div class="col-12">
                    <label class="form-label">Department <span class="required-field">*</span>
                    </label>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="fill">
                      <input type="text" formControlName="department" matInput>
                    </mat-form-field>
                  </div>
                </div>


                <div class="row">
                  <div class="col-12">
                    <label class="form-label">E-mail <span class="required-field">*</span>
                    </label>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="fill">
                      <input type="email" formControlName="email" matInput>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-6 mb-2"><label class="form-label">Work #</label>
                    <mat-form-field class="spinner-hide" appearance="fill">
                      <input type="number" formControlName="workContact" matInput>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 mb-2"> <label class="form-label">Mobile # <span
                        class="required-field">*</span>
                    </label>
                    <mat-form-field class="spinner-hide" appearance="fill">
                      <input type="number" formControlName="mobileContact" matInput>
                    </mat-form-field>
                  </div>


                </div>
              </mat-card>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>

  <div *ngIf="tab == 1">
    <app-base-image-model (cancelSelection)="cancelSelection()" (selectionChange)="updateDXF($event)"
      [projects]="projects" [isAssetOwner]="isAssetOwner" [asset]="assetData" [assetId]="data.id">
    </app-base-image-model>
  </div>
  <div *ngIf="tab == 2">
    <app-base-model [projects]="projects" [dialogRef]="dialogRef" [isAssetOwner]="isAssetOwner" [assetId]="data.id"
      [asset]="assetData"></app-base-model>
  </div>
  <div *ngIf="tab === 3" class="mr-2 ml-2 mt-2 mb-1">
    <mat-card class="card-inspection">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <div class="row d-flex align-items-center">
              <div class="col-12">
                <label class="form-label">Inspection Start Date <span class="required-field">*</span>
                </label>
              </div>
              <div class="col-12">
                <mat-form-field appearance="fill">
                  <input [disabled]="!isAssetOwner && data?.id" [(ngModel)]="inspection.startDate" type="date"
                    matInput />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="row d-flex align-items-center">
              <div class="col-12">
                <label class="form-label">Inspection Till Date <span class="required-field">*</span>
                </label>
              </div>
              <div class="col-12">
                <mat-form-field appearance="fill">
                  <input [disabled]="!isAssetOwner && data?.id" [(ngModel)]="inspection.endDate" type="date" matInput />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="row d-flex align-items-center">
              <div class="col-12">
                <label class="form-label">Frquency <span class="required-field">*</span>
                </label>
              </div>
              <div class="col-12">
                <mat-form-field appearance="fill">
                  <mat-select [disabled]="!isAssetOwner && data?.id" placeholder="--select--"
                    [value]="inspection.frequency" [(ngModel)]="inspection.frequency">
                    <mat-option value="day">Day </mat-option>
                    <mat-option value="week">Week </mat-option>
                    <mat-option value="month">Month </mat-option>
                    <mat-option value="year">Year </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="mb-3" *ngIf="inspection.frequency">
            <div class="row d-flex align-items-center">
              <div class="col-3 col-md-2"> In Every </div>
              <div class="col-6 col-md-8">
                <mat-form-field>
                  <input [disabled]="!isAssetOwner && data?.id" [(ngModel)]="inspection.frequency_number" type="number"
                    matInput />
                </mat-form-field>
              </div>
              <div class="col-3 col-md-2">
                {{inspection.frequency}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>

</div>


<ng-template #assetTransferDialog let-data>
  <div class="popup-header w-100 d-flex">
    <p class="w-100">
      Asset ownership transfer
    </p>
    <mat-icon class="close-btn" (click)="assetTransferDialogRef.close()">close</mat-icon>
  </div>
  <div mat-dialog-content class="mb-2 align-items-center popup-body">
    <div class="form-field">
      <label for="input-role">Select User</label>
      <br>
      <mat-form-field appearance="fill" id="input-role" style="padding: 0; margin-bottom: 16px;">
        <mat-select style="min-width:500px;" [(ngModel)]="data.member">
          <mat-option *ngFor="let user of data.members" [value]="user._id">
            {{user.displayName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions>
    <button [disabled]="!data.member" mat-button (click)="changeOwner(data.member)" class="ml-2" color="primary"
      mat-raised-button>
      Make asset owner
    </button> <button class="ml-2" mat-button (click)="assetTransferDialogRef.close()">Cancel</button>
  </div>

</ng-template>
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BackendService } from '../../services/backend.service';

@Component({
  selector: 'app-severity-level',
  templateUrl: './severity-level.component.html',
  styleUrls: ['./severity-level.component.scss']
})
export class SeverityLevelComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SeverityLevelComponent>,
    private backendService: BackendService) {
  }

  ngOnInit(): void { }

  addLevel(level:number) {
    this.backendService.addSeverityLevel(this.data.id, this.data.imageId, level).subscribe(() => {
      this.dialogRef.close({'update':true});
    });
  }
}
